import axios from 'axios';
import { merge } from 'lodash/fp';
import Cookies from 'universal-cookie';
import { getUuid } from './utils/generators';

const COOKIE_NAME = 'tm-access-token';

const cookies = new Cookies();

class AxiosWrapper {
  client = null;

  constructor(config = {}) {
    this.client = axios.create(
      config || {
        baseURL: config.baseURL,
        timeout: 100000,
      },
    );

    this.client.interceptors.request.use(currentConfig => {
      const newConfig = Object.assign({}, currentConfig);
      if (newConfig.AWSParamTokenName && newConfig.AWSParamTokenValue) {
        newConfig.headers.AWSParamTokenName = newConfig.AWSParamTokenName;
        newConfig.headers.AWSParamTokenValue = newConfig.AWSParamTokenValue;
      }
      if (!newConfig.headers.Authorization) {
        newConfig.headers.Authorization = `Bearer ${newConfig.token || cookies.get(COOKIE_NAME)}`;
      }
      newConfig.headers['ID-Token'] = newConfig.idToken;
      if (newConfig.language) {
        newConfig.headers['Accept-Language'] = newConfig.language;
      }
      newConfig.headers['Access-Control-Allow-Origin'] = '*';
      newConfig.headers['Access-Control-Allow-Credentials'] = 'true';
      newConfig.headers['Access-Control-Allow-Methods'] = 'POST, GET, PUT, OPTIONS, DELETE, PATCH';
      newConfig.headers['Access-Control-Allow-Headers'] =
        'Origin, X-Requested-With, Content-Type, Accept, Authorization';
      return newConfig;
    });

    this.client.interceptors.response.use(
      response => response,
      error => {
        if (
          error.response &&
          error.response.status === 400 &&
          config.handleBadRequest &&
          config.handleBadRequest instanceof Function
        ) {
          config.handleBadRequest();
        }

        if (
          error.response &&
          error.response.status === 401 &&
          config.handleUnauthorized &&
          config.handleUnauthorized instanceof Function
        ) {
          config.handleUnauthorized();
        }

        return Promise.reject(error);
      },
    );
  }

  fetch(url, params = {}, headers = {}) {
    return this.client.get(url, { params, headers: { 'X-Request-ID': getUuid(12), ...headers } });
  }

  create(url, data, config = {}) {
    return this.client.post(url, data, merge(config, { headers: { 'X-Request-ID': getUuid(12) } }));
  }

  post(url, data, config = {}, headers = {}) {
    return this.client.post(
      url,
      data,
      merge(config, { headers: { 'X-Request-ID': getUuid(12), ...headers } }),
    );
  }

  get(url, params = {}) {
    return this.fetch(url, params);
  }

  update(url, data, config = {}, headers) {
    return this.client.put(
      url,
      data,
      merge(config, { headers: { 'X-Request-ID': getUuid(12), ...headers } }),
    );
  }

  put(url, data, config = {}, headers = {}) {
    return this.client.put(
      url,
      data,
      merge(config, { headers: { 'X-Request-ID': getUuid(12), ...headers } }),
    );
  }

  delete(url, data = {}, config = {}, headers = {}) {
    return this.client.delete(
      url,
      merge(config, { data, headers: { 'X-Request-ID': getUuid(12), ...headers } }),
    );
  }

  patch(url, data, config = {}, headers = {}) {
    return this.client.patch(
      url,
      data,
      merge(config, { headers: { 'X-Request-ID': getUuid(12), ...headers } }),
    );
  }

  rootUrl() {
    return this.client.defaults.baseURL;
  }
}

export default AxiosWrapper;
