import { types, flow, getRoot, getParent } from 'mobx-state-tree';
import moment from 'moment';
import { when } from 'mobx';
import Cookies from 'universal-cookie';
import {
  Reload,
  Current,
  Transaction,
  Invoice,
  DepositsPagination,
  ReportsFilters,
} from '../../models/SettingsStore/Balance';
import { getPayoneerUrlForBalanceService, getEnvUrl } from '../../utils/getEnvUrl';

const cookies = new Cookies();

const URL = 'balance-service';

const Balance = types
  .model('Balance', {
    autoReload: types.optional(Reload, {}),
    current: types.optional(Current, {}),
    deposits: types.optional(types.array(Transaction), []),
    depositsPagination: types.optional(DepositsPagination, {}),
    invoices: types.optional(types.frozen(), []),
    reportsTransactions: types.optional(types.array(Transaction), []),
    reportsFilters: types.optional(ReportsFilters, {}),
    paypalSessionToken: types.optional(types.string, ''),
    paypalEligible: types.optional(types.integer, 0),
    paypalPaymentOptions: types.optional(types.array(types.string), []),
    payoneerPaymentOptions: types.optional(types.array(types.string), []),
    paypalConnected: false,
    payoneerConnected: false,
    payoneerSubmitResponse: types.optional(types.frozen(), {}),
    leanBalance: 0,
  })
  .views(self => ({
    get axios() {
      return getRoot(self).axios;
    },
    get axios2() {
      return getRoot(self).axios2;
    },
    get reportsData() {
      const jsonData = self.reportsTransactions.map(
        ({ created, debit, credit, extra, external_id, type, balance_after, app, id }) => ({
          Date: moment(created).format('MMMM D YYYY HH:mm') || '',
          'Transaction Type': type || '',
          Debit: debit,
          Credit: credit,
          'Transaction Id': (extra?.order_id ?? extra?.sub_reason ?? external_id) || '',
          'Balance After': Math.round(balance_after * 100) / 100,
          App: app,
          'Balance Txn': id,
        }),
      );
      let csv = (jsonData[0] ? Object.keys(jsonData[0]) : []).join(',');
      csv += '\n';
      jsonData.forEach(elem => {
        csv += Object.values(elem).join(',');
        csv += '\n';
      });
      return csv;
    },
  }))
  .actions(self => ({
    fetchPaypalSessionToken: flow(function* fetchPaypalSessionToken() {
      try {
        const { data } = yield self.axios2.fetch(`${URL}/payments/paypal/sessionToken`, {}, {});
        self.paypalSessionToken = data.data.token;
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    }),
    // fetchClientConnectionStatus: flow(function* fetchClientConnectionStatus() {
    //   try {
    //     const {
    //       data: { data },
    //     } = yield self.axios2.fetch(`${URL}/payments/paypal/clientToken`, {}, {});
    //     self.setPaypalConnected(data.hasToken);
    //   } catch (err) {
    //     console.error(err);
    //   }
    // }),
    fetchPaypalEligible: flow(function* fetchPaypalEligible() {
      try {
        const {
          data: { data },
        } = yield self.axios2.fetch(`${URL}/payments/paypal/isEligible`, {}, {});
        if(data.is_eligible === true){
          self.paypalEligible = data.balance;
        }
        else{
          self.paypalEligible = 0;
        }
      } catch (err) {
        console.error(err);
      }
    }),
    fetchPaypalPaymentOptions: flow(function* fetchPaypalPaymentOptions() {
      try {
        const {
          data: { data },
        } = yield self.axios2.fetch(`${URL}/payments/paypal/paymentOptions`, {}, {});
        self.setPaypalConnected(data && data.length > 0);
        self.paypalPaymentOptions = data;
      } catch (err) {
        console.error(err);
      }
    }),
    fetchPayoneerPaymentOptions: flow(function* fetchPayoneerPaymentOptions() {
      try {
        const {
          data: { data },
        } = yield self.axios2.fetch(`${URL}/payments/payoneer/paymentOptions`, {}, {});
        self.setPayoneerConnected(data && data.length > 0);
        self.payoneerPaymentOptions = data;
      } catch (err) {
        console.error(err);
      }
    }),
    sendAuthCode: flow(function* sendAuthCode(authCode) {
      return yield self.axios2.post(
        `${URL}/payments/payoneer/auth`,
        {
          authCode,
          redirectUrl: `${getEnvUrl()}/close`,
        },
        {},
      );
    }),
    sendCodeAfterConnection(code) {
      return new Promise((resolve, reject) => {
        when(() =>
          self
            .sendAuthCode(code)
            .then(data => resolve(data))
            .catch(err => reject(err)),
        );
      });
    },
    setPayoneerResponse(v) {
      self.payoneerSubmitResponse = v;
    },
    registerPayoneer: flow(function* registerPayoneer() {
      try {
        const authWindow = window.open(getPayoneerUrlForBalanceService(), 'balance', 'height=750, width=750');
        window.addEventListener(
          'message',
          function handleWindowMessage(e) {
            if (!e.data.type || e.data.type !== 'OAuthAccessCode') {
              return;
            }
            // authWindow.close();
            window.removeEventListener('message', handleWindowMessage, false);
            if (e.data.client === '/close') {
              self.setPayoneerSubmitResponse(e.data.payload);
              authWindow.close();
            } else {
              authWindow.close();
            }
          },
          false,
        );
        yield when(() => Object.keys(self.payoneerSubmitResponse).length !== 0);
        return self.payoneerSubmitResponse.isOk;
      } catch (err) {
        console.error(err);
        return false;
      }
    }),
    setPayoneerSubmitResponse: flow(function* setPayoneerSubmitResponse(value) {
      try {
        const { data } = yield self.axios2.post(`${URL}/payments/payoneer/auth`, {
          authCode: value,
          redirectUrl:
            process.env.REACT_APP_ENV === 'prod'
              ? `https://bifm.triplemars.com/close`
              : `https://qa-bifm.triplemars.com/close`,
        });
        data.status === 'ok'
          ? self.setPayoneerResponse({ isOk: true })
          : self.setPayoneerResponse({ isOk: false });
      } catch (err) {
        console.error(err);
      }
    }),
    fetchBalanceData: flow(function* fetchBalanceData() {
      try {
        const { data } = yield self.axios2.fetch(`${URL}/balance`, {}, {});
        self.autoReload = Reload.create({ USD: data.data.auto_reload });
        self.current = data.data.current;
      } catch (err) {
        console.error(err);
      }
    }),
    downloadTransactionInvoice: flow(function* downloadTransactionInvoice(id, created) {
      try {
        const URL_download = `/bifm-service/invoices/${id}/download_link`;
        const { data } = yield self.axios.fetch(URL_download, {}, {});
        const a = document.createElement('a');
        a.style.display = 'none';
        document.body.appendChild(a);
        a.href = data.data.download_link;
        a.download = created;
        a.click();
      } catch (e) {
        console.error(e);
      }
    }),
    fetchTransactions: flow(function* fetchTransactions(forReports = false, addResults = false) {
      const currency = cookies.get('currency');
      try {
        if (forReports) {
          const from = moment(
            `${self.reportsFilters.fromDate} 00:00 +0000`,
            'YYYY-MM-DD HH:mm Z',
          ).format('X');
          const to = moment(
            `${self.reportsFilters.toDate} 23:59 +0000`,
            'YYYY-MM-DD HH:mm Z',
          ).format('X');
          const page_size = 5000;
          const { data } = yield self.axios2.fetch(
            `${URL}/transactions?from=${from}&to=${to}&page_size=${page_size}&status=approved&app=BIFM&currency=${currency}`,
            {},
            {},
            {},
          );
          self.reportsTransactions = data.data;
        } else {
          const { start, limit } = self.depositsPagination;
          const { data } = yield self.axios2.fetch(
            `${URL}/transactions?type=deposit&start=${start}&length=3&app=BIFM&currency=${currency}`,
            {},
            {},
          );
          // debugger;
          self.deposits = addResults ? [...self.deposits, ...data.data] : data.data;
          self.depositsPagination.setTotal(data.recordsFiltered ?? 0);
        }
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    }),
    fetchFirstTransactionsPage: flow(function* fetchFirstTransactionsPage() {
      self.depositsPagination.setStart(0);
      self.depositsPagination.setLimit(500);
      yield self.fetchTransactions();
      // getParent(self).userProfile.updateIntercom({
      //   last_balance_charge: self.deposits[0].created,
      // });
    }),
    fetchNextTransactionsPage: flow(function* fetchNextTransactionsPage() {
      self.depositsPagination.setStart(self.deposits.length);
      self.depositsPagination.setLimit(500);
      yield self.fetchTransactions(false, true);
    }),
    fetchInvoices: flow(function* fetchInvoices() {
      try {
        const balanceInvoicesUrl = `bifm-service/invoices?type=ORDERS`;
        const { data } = yield self.axios.fetch(balanceInvoicesUrl, {}, {});
        self.invoices = data.data;
      } catch (err) {
        console.log(err);
      }
    }),
    fetchLeanBalance: flow(function* fetchLeanBalance() {
      try {
        const { data } = yield self.axios2.fetch(
          `balance-service/balance/lean?currency=${cookies.get('currency')}`,
        );
        self.leanBalance = data.data.current;
      } catch (err) {
        console.log(err);
      }
    }),
    saveAutoReload: flow(function* saveAutoReload() {
      try {
        yield self.axios2.post(`${URL}/balance/autoReload`, self.autoReload.USD);
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    }),
    deletePaymentOption: flow(function* deletePaymentOption(name) {
      try {
        yield self.axios2.delete(
          `${URL}/payments/paypal/paymentOptions/${name}`,
          {},
          self.autoReload.USD,
        );
        yield self.fetchPaypalPaymentOptions();
        yield self.fetchPaypalEligible();
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    }),
    deletePayoneerPaymentOption: flow(function* deletePayoneerPaymentOption(name) {
      try {
        yield self.axios2.delete(
          `${URL}/payments/payoneer/paymentOptions/${name}`,
          {},
          self.autoReload.USD,
        );
        yield self.fetchPayoneerPaymentOptions();
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    }),
    depositCurrency: flow(function* depositCurrency(amount, currency, nonce, pp_user) {
      try {
        let params = {
          amount,
          currency: cookies.get('currency'),
          app: 'BIFM',
        };
        if (nonce && !self.paypalConnected) {
          // on first deposit
          params = {
            ...params,
            nonce,
          };
          const { data } = yield self.axios2.post(`${URL}/payments/paypal/checkout`, params);
          if (data.status === 'error') {
            return false;
          }
          self.fetchPaypalPaymentOptions();
          self.fetchPaypalEligible();
        } else {
          // on later deposits
          params = {
            ...params,
            useToken: true,
            pp_user,
          };
          yield self.axios2.post(`${URL}/payments/paypal/checkout`, params);
        }
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    }),
    depositCurrencyPayoneer: flow(function* depositCurrencyPayoneer(amount, currency, pp_user) {
      try {
        let params = {
          amount,
          currency: cookies.get('currency'),
          app: 'BIFM',
        };
        if (!self.payoneerConnected) {
          // on first deposit
          const resp = yield self.registerPayoneer();
          if (!resp) {
            return resp;
          }
          yield self.fetchPayoneerPaymentOptions();
          params = {
            ...params,
            useToken: true,
            pp_user: self.payoneerPaymentOptions[self.payoneerPaymentOptions.length - 1],
          };
        } else {
          params = {
            ...params,
            useToken: true,
            pp_user,
          };
        }
        // on later deposits

        const { data } = yield self.axios2.post(`${URL}/payments/payoneer/checkout`, params);
        if (data.status === 'error') {
          const errorMsg = data.message.split('failed with message: ');
          self.payoneerSubmitResponse = { ...self.payoneerSubmitResponse, message: errorMsg[1] };
          return false;
        }
        return data.status !== 'error';
      } catch (err) {
        console.error(err);
        return false;
      }
    }),
    initBalance: flow(function* initStore() {
      yield Promise.allSettled([
        self.fetchInvoices(),
        self.fetchBalanceData(),
        self.fetchFirstTransactionsPage(),
        // self.fetchClientConnectionStatus(),
        self.fetchPaypalEligible(),
        self.fetchPaypalPaymentOptions(),
        self.fetchPayoneerPaymentOptions(),
        self.fetchPaypalSessionToken(),
      ]);
    }),
    setPaypalConnected(value) {
      self.paypalConnected = !!value;
    },
    setPayoneerConnected(value) {
      self.payoneerConnected = !!value;
    },
  }));

export default Balance;
