export const fontSize = {
  small: '12px',
  medium: '14px',
  large: '18px',
  larger: '21px',
  xlarger: '25px',
  huge: '29px',
  giant: '32px',
};

export const mediaQueries = {
  enoughSpace: '@media (min-width: 1366px)',
  notEnoughSpaceOrderTable: '@media (max-width: 1269px)',
};

export const borderRadius = {
  normal: '15px',
  small: '4px',
};

export const fontWeight = {
  veryLight: 100,
  light: 300,
  normal: 500,
};

export const border = {};

export const lineHeight = {
  normal: 1.54,
  big: 2,
  medium: 1.29,
  huge: 2.33,
  filtersBar: '22px',
};

export const colors = {
  greyMid: '#919db4',
  greySecondary: '#586784',
  greyLight: '#cdd3e0',
  greyDark: '#3f435c',
  greyInputBorder: '#a2adc4',
  greyNotes: '#707070',

  blackSecondary: '#202329',
  blueDark: '#233b69',
  blueLight: '#02aff9',
  blueMed: '#0070ba',
  blue: '#1890ff',
  blueBright: '#f0f2f6',

  mainBackground: '#f0f2f5',
  imageBoxShadow: 'rgba(0, 105, 250, 0.30)',
  BlueBoxShadow: '#0069fa26',
  colorlessBoxShadow: 'rgba(0, 0, 0, 0.16)',
  borderLight: '#ebebeb',
  greenBright: '#08ac08',
  greenLight: '#D9FCD9',

  orange: '#ff5700',
  beige: '#ffe6d9',
  red: '#c70000',
  pinkDark: '#ff3f71',
  disabledButton: '#acb6cb',
  disabledText: '#a9b2c8',
  disabledBorder: '#d9d9d9',
  ligthWhite: '#D9E7FF',

  tableHeaderBackground: '#f5f8fc',
  tableHeaderFont: 'rgba(88, 103, 132, 0.8)',
  tableBorderColor: '#becbe4',

  whiteBackground: '#ffffff',
  whiteLightBackground: '#fafbfe',
  whiteMediumBackground: '#edf4fc;',
  whiteDarkBackground: '#e4edf7',
  whiteFont: '#fefefe',
};

export const theme = {
  colors,
  fontSize,
  border,
  borderRadius,
  fontWeight,
  lineHeight,
  mediaQueries,
};

export const buttonNormal = '#233b69';
export const buttonHover = '#0070ba';
export const buttonSelected = '#233b69';
export const buttonDisables = '#ebebeb';
export const themeColors = {
  backgroundLightBlue: '#edf4fc',
  backgroundDarkBlue: '#e4edf7',
  backgroundLightGray: '#f4f6fa',
  backgroundDarkGray: '#fafbfe',
  blue: '#0070ba',
  darkBlue: '#233b69',
  lightBlue: '#d9e7ff',
  eggplantBlue: '#3f435c',
  dirtyBlue: '#586784',
  dirtyLightBlue: '#a2adc4',
  purple: '#474588',
  brightPurple: '#6f6dc3',
  mustard: '#ffdb58',
  beige: '#ebebeb',
  green: '#08ac08',
  pink: '#ff3f71',
  grey: '#c6d1df',
  lightGrey: '#e8ecf2',
  shadowDarkBlue: '#0069fa26',
  warning: '#ffe6d9',
  warningFont: '#ff5700',
  info: '#02aff9',
  infoFont: '#0070ba',
  success: '#d9fcd9',
  successFont: '#08ac08',
  red: '#ff0000',
};
