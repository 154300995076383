import styled from '@emotion/styled';

export const ToastWrapper = styled('div')`
  position: absolute;
  left: 50%;
  margin-left: -20%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 7px 0 #0069fa26;
  border: solid 1px;
  border-radius: 3px;
  border-color: ${({ borderAndTextColor }) => borderAndTextColor};
  color: ${({ borderAndTextColor }) => borderAndTextColor};
  width: 25%;
  height: 73px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  z-index: 1001;
  white-space: pre-wrap;
`;
export const Header = styled('div')`
  position: absolute;
  line-height: 1;
  right: 7px;
  top: 7px;
`;

export const Body = styled('div')`
  width: 100%;
  padding: 0 28px;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  display: flex;
  height: 100%;
  align-items: center;
`;
