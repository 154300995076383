export const getEnvUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'prod':
      return 'https://bifm.triplemars.com';
    case 'dev' || 'qa':
      return 'https://api-dev.triplemars.com';
    case 'local':
      return 'http://triplemars.com:5002';
    default:
      return `http://bifm-new.triplemars.com`;
  }
};

export const getPayoneerUrl = () => {
  if (process.env.REACT_APP_ENV === 'prod') {
    return `https://login.payoneer.com/api/v2/oauth2/authorize?client_id=hv2q4FDgVxNh3OzvlaxxafOL7MU724Lm&redirect_uri=https://bifm.triplemars.com/close&scope=read write openid personal-details&response_type=code`;
  }
  return `https://login.sandbox.payoneer.com/api/v2/oauth2/authorize?client_id=069bb0d8ee64c171c79a439594596715&redirect_uri=https://qa-bifm.triplemars.com/close&scope=read write openid personal-details&response_type=code`;
};

export const getPayoneerUrlForBalanceService = () => {
  if (process.env.REACT_APP_ENV === 'prod') {
    return `https://login.payoneer.com/api/v2/oauth2/authorize?client_id=ca852c7beeed35ea262e975c5ae2d7f8&redirect_uri=https://bifm.triplemars.com/close&scope=read write openid personal-details&response_type=code`;
  }
  return `https://login.sandbox.payoneer.com/api/v2/oauth2/authorize?client_id=069bb0d8ee64c171c79a439594596715&redirect_uri=https://qa-bifm.triplemars.com/close&scope=read write openid personal-details&response_type=code`;
};

