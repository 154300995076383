import React from 'react';
import { types, flow, getParent, getParentOfType } from 'mobx-state-tree';
import { Modal } from 'antd';
import OrdersStore from '../../stores/OrdersStore';
import ENUMS from '../../constants/enums';
import capitalizeFirst from '../../utils/capitalizeFirst';

const { confirm } = Modal;

const OrderTableEntry = types
  .model('OrderTableEntry', {
    selected: false,
    comments: types.optional(types.array(types.frozen()), []),
    created: types.string,
    extras: types.frozen(),
    id: types.identifier,
    is_gift: types.boolean,
    product: types.frozen(),
    product_condition: types.maybeNull(types.string),
    purchase: types.frozen(),
    quantity: types.number,
    return_details: types.maybeNull(types.frozen()),
    shipping_address: types.frozen(),
    short_id: types.number,
    status: types.maybeNull(types.string),
    tracking: types.frozen(),
    updated: types.maybeNull(types.string),
    user: types.string,
  })
  .views(self => ({
    get axios() {
      return getParentOfType(self, OrdersStore).axios;
    },
    get showToast() {
      return getParentOfType(self, OrdersStore).toastsStore.showToast;
    },
    get editable() {
      return self.status === 'NEW' || self.status === 'FAILED';
    },
    get actions() {
      switch (self.status) {
        case 'NEW': {
          return [{ name: 'Cancel', id: 'cancel' }];
        }
        case 'FAILED': {
          return [
            { name: 'Try Again', id: 'tryAgain' },
            { name: 'Force Purchase', id: 'forcePurchase' },
            { name: 'Remove', id: 'remove' },
          ];
        }
        case 'RETURN_IN_PROCESS': {
          return [{ name: 'Label URL', id: 'openLabelURL' }];
        }
        case 'ORDERED': {
          return [{ name: 'Cancel', id: 'cancel' }];
        }
        case 'SHIPPED':
        case 'DELIVERED': {
          return [{ name: 'Return', id: 'returnOrder' }];
        }
        default: {
          return [];
        }
      }
    },
    get editRequestData() {
      const {
        status,
        shipping_address: {
          name,
          address_line_1,
          address_line_2,
          country,
          state,
          city,
          zip,
          phone,
        },
        extras: { max_price },
      } = self;
      return {
        status,
        name,
        address_line_1,
        address_line_2,
        country,
        state,
        city,
        zip,
        phone,
        max_price,
      };
    },
    get validEditRequestData() {
      return typeof self.editRequestData.max_price === 'number';
    },
  }))
  .actions(self => ({
    _cancel: flow(function* _cancel() {
      try {
        const { data } = yield self.axios.post(`bifm-service/orders/${self.id}`, {
          status: 'CANCELED_REQUESTED',
        });
        self.showToast({
          type: ENUMS.TOAST_TYPES.INFO,
          message: data?.message ? capitalizeFirst(data.message) : 'Order successfully canceled',
        });
      } catch (err) {
        console.error(err);
        self.showToast({
          type: ENUMS.TOAST_TYPES.WARNING,
          message: err?.message ? capitalizeFirst(err.message) : 'There was an error',
        });
      }
    }),
    tryAgain: flow(function* tryAgain() {
      try {
        const { data } = yield self.axios.post(`bifm-service/orders/${self.id}`, {
          status: 'IN_PROCESS',
          forced: false,
        });
        self.showToast({
          type: ENUMS.TOAST_TYPES.INFO,
          message: data?.message ? capitalizeFirst(data.message) : 'Request was sent successfully',
        });
      } catch (err) {
        console.error(err);
        self.showToast({
          type: ENUMS.TOAST_TYPES.WARNING,
          message: err?.message ? capitalizeFirst(err.message) : 'There was an error',
        });
      }
    }),
    _forcePurchase: flow(function* _forcePurchase() {
      try {
        const { data } = yield self.axios.post(`bifm-service/orders/${self.id}`, {
          status: 'IN_PROCESS',
          forced: true,
        });
        self.showToast({
          type: ENUMS.TOAST_TYPES.INFO,
          message: data?.message ? capitalizeFirst(data.message) : 'Request was sent successfully',
        });
      } catch (err) {
        console.error(err);
        self.showToast({
          type: ENUMS.TOAST_TYPES.WARNING,
          message: err?.message ? capitalizeFirst(err.message) : 'There was an error',
        });
      }
    }),
    remove: flow(function* remove() {
      try {
        const { data } = yield self.axios.post(`bifm-service/orders/${self.id}`, {
          status: 'REMOVED',
        });
        self.showToast({
          type: ENUMS.TOAST_TYPES.INFO,
          message: data?.message ? capitalizeFirst(data.message) : 'Item removed successfully',
        });
      } catch (err) {
        console.error(err);
        self.showToast({
          type: ENUMS.TOAST_TYPES.WARNING,
          message: err?.message ? capitalizeFirst(err.message) : 'There was an error',
        });
      }
    }),
    edit: flow(function* edit(params) {
      try {
        const { data } = yield self.axios.post(`bifm-service/orders/${self.id}`, params);
        self.showToast({
          type: ENUMS.TOAST_TYPES.INFO,
          message: data?.message ? capitalizeFirst(data.message) : 'Item edited successfully',
        });
      } catch (err) {
        console.error(err);
        self.showToast({
          type: ENUMS.TOAST_TYPES.WARNING,
          message: err?.message ? capitalizeFirst(err.message) : 'There was an error',
        });
      }
    }),
    openLabelURL() {
      window.open(self?.return_details?.tracking_label, '_blank');
    },
    _returnOrder: flow(function* returnOrder(returnReason) {
      try {
        const { data } = yield self.axios.delete(`bifm-service/orders/${self.id}`, {
          reason: returnReason || 'wrong product',
        });
        self.showToast({
          type: ENUMS.TOAST_TYPES.INFO,
          message: data?.message
            ? capitalizeFirst(data.message)
            : 'Return request was sent successfully',
        });
      } catch (err) {
        console.error(err);
        self.showToast({
          type: ENUMS.TOAST_TYPES.WARNING,
          message: err?.message ? capitalizeFirst(err.message) : 'There was an error',
        });
      }
    }),
    setSelected(val) {
      self.selected = val;
    },
    setName(val) {
      self.shipping_address = { ...self.shipping_address, name: val };
    },
    setMaxPrice(val) {
      self.extras = { ...self.extras, max_price: parseFloat(val) || val };
    },
    setAddressLine1(val) {
      self.shipping_address = { ...self.shipping_address, address_line_1: val };
    },
    setAddressLine2(val) {
      self.shipping_address = { ...self.shipping_address, address_line_2: val };
    },
    setCity(val) {
      self.shipping_address = { ...self.shipping_address, city: val };
    },
    setState(val) {
      self.shipping_address = { ...self.shipping_address, state: val };
    },
    setZip(val) {
      self.shipping_address = { ...self.shipping_address, zip: val };
    },
    setCountry(val) {
      self.shipping_address = { ...self.shipping_address, country: val };
    },
    setPhone(val) {
      self.shipping_address = { ...self.shipping_address, phone: val };
    },
  }));

export default OrderTableEntry;
