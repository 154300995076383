import { types } from 'mobx-state-tree';

const ToastsStore = types
  .model('ToastsStore', {
    isShown: false,
    autoClose: true,
    type: 0,
    message: '',
    delay: types.maybe(types.number),
  })
  .views(self => ({
    get getIsShown() {
      return self.isShown;
    },
    get getAutoClose() {
      return self.autoClose;
    },
    get getType() {
      return self.type;
    },
    get getMessage() {
      return self.message;
    },
  }))
  .actions(self => {
    const close = () => {
      self.isShown = false;
    };
    const showToast = data => {
      self.isShown = true;
      if (data.autoClose) self.autoClose = data.autoClose;
      self.type = data.type;
      self.message = data.message;
      self.delay = data.delay;
    };
    return {
      close,
      showToast,
    };
  });

export default ToastsStore;
