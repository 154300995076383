import styled from '@emotion/styled';
import { css } from '@emotion/core';
import DefaultButton from '../Button';
import DefaultInput from '../Input';
import { theme } from '../../../constants';

export const Input = styled(DefaultInput)`
  width: ${({ width }) => width || '289px'};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : '')}
  color: ${theme.colors.greyMid};

  &::placeholder {
    color: ${theme.colors.greyMid};
  }
`;

export const Button = styled(DefaultButton)`
  font-size: ${theme.fontSize.medium};
  line-height: ${theme.lineHeight.big};
  color: ${theme.colors.whiteFont};
  padding: 0;
  width: ${({ width }) => width || '107px'};
  height: ${({ height }) => height || ''};
`;

export const BarContainer = styled.div`
  border: ${({ border }) => border || `1px solid ${theme.colors.greyLight}`};
  border-top: 0;
  margin: 0;
  padding: ${({ padding }) => padding || '15px 10px'};
  ${({ position }) => (position ? `position: ${position};` : '')}
  ${({ width }) => (width ? `width: ${width};` : '')}
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & .ant-input {
    line-height: 13px;
  }
  span {
    margin: 0 6px;
  }
`;

export const formStyle = css`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  align-content: space-between;

  > div {
    margin-right: 21px;
  }
`;

export const submitButtonStyle = css`
  margin-right: 0;
  margin-left: auto;
  padding: 0 31px;
  display: block;
`;
