import { types } from 'mobx-state-tree';
import ENUMS from '../../constants/enums';
import ENUMS_TRANSFORM from '../../constants/transformToEnum';

const EnumsStore = types
  .model('enumsStore', {
    enums: types.frozen(),
    enumsReverse: types.frozen(),
  })
  .views(self => ({
    get getEnumsList() {
      return self.enums;
    },
    get getEnumsReverseList() {
      return self.enumsReverse;
    },
  }))
  .actions(self => {
    const afterCreate = () => {
      self.enums = ENUMS;
      self.enumsReverse = ENUMS_TRANSFORM;
    };
    const getEnums = () => {
      return self.enums;
    };
    const getEnumsReverse = () => {
      return self.enumsReverse;
    };
    return {
      afterCreate,
      getEnums,
      getEnumsReverse,
    };
  });

export default EnumsStore;
