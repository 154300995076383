import { types } from 'mobx-state-tree';
import makeInspectable from 'mobx-devtools-mst';
import { browserHistory } from 'react-router';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import RoutingStore from './RoutingStore';
import AxiosWrapper from '../axios';
import config from '../envUrlConfig';
import LocaleStore from './LocaleStore';
import ToastsStore from './ToastsStore';
import SessionStore from './SessionStore';
import OrdersStore from './OrdersStore';
import GeneralStore from './GeneralStore';
import SettingsStore from './SettingsStore';
import EnumsStore from './EnumsStore';
import DashboardStore from './DashboardStore';
import VMStore from './VMStore';

const routerStore = new RouterStore();
const enhancedBrowserHistory = syncHistoryWithStore(browserHistory, routerStore);

// const SessionStore = types.model('SessionStore', {});

const RootStore = types
  .model('RootStore', {
    /**
     * Instance of Axios with preset base urls and headers
     */
    axios: types.optional(types.frozen(), {}),
    /**
     * Instance of Axios with preset base urls and headers
     */
    axios2: types.optional(types.frozen(), {}),
    /**
     * Routing store with enhanced brower history
     * Wrap react-router's browser history with Mobx to use Mobx observable power
     */
    routingStore: types.optional(RoutingStore, {
      browserHistory: enhancedBrowserHistory,
    }),
    /**
     * Session store to handle user's authentication
     * Will do actions like signup, login, forgot password,...
     * also perform read, write JWT token to cookie
     */
    sessionStore: types.optional(SessionStore, {}),

    /**
     * Enums store to make enums available to all other stores
     */
    enumsStore: types.optional(EnumsStore, {}),
    /**
     * Settings store to handle the (single) user's settings
     */
    settingsStore: types.optional(SettingsStore, {}),
    /**
     * Locale store will handle i18n for our app
     * Parse locale user language and set locale messages data
     */
    localeStore: types.optional(LocaleStore, {}),

    /**
     * toasts store for handle the system toasts
     */
    toastsStore: types.optional(ToastsStore, {}),

    /**
     * Orders store handles orders table and other order-related data
     */
    ordersStore: types.optional(OrdersStore, {}),
    /**
     * Orders store handles orders table and other order-related data
     */
    dashboardStore: types.optional(DashboardStore, {}),
    /**
     * Orders store handles orders table and other order-related data
     */
    vmStore: types.optional(VMStore, {}),
    /**
     * general store handles some new operations
     */
    generalStore: types.optional(GeneralStore, {}),
  })
  .actions(self => ({
    afterCreate() {
      self.initAxios();
    },
    initOnLoad() {
      // const enums = self.enumsStore.getEnums();
      // const enumsReverse = self.enumsStore.getEnumsReverse();
      const Translations = self.localeStore.initLocale();
      const initSession = self.sessionStore.initSession();
      return [
        // enums,
        // enumsReverse,
        Translations,
        initSession,
      ];
    },
    initAxios() {
      self.axios = new AxiosWrapper({
        baseURL: config.API_URL,
        timeout: 1000000,
        token: self.sessionStore.token,
        handleUnauthorized: self.sessionStore.handleUnauthorized,
        handleNetworkError: self.sessionStore.handleNetworkError,
        handleNetworkNotDown: self.sessionStore.handleNetworkNotDown,
      });
      self.axios2 = new AxiosWrapper({
        baseURL: config.API_URL2,
        timeout: 1000000,
        token: self.sessionStore.token,
        handleUnauthorized: self.sessionStore.handleUnauthorized,
        handleNetworkError: self.sessionStore.handleNetworkError,
        handleNetworkNotDown: self.sessionStore.handleNetworkNotDown,
      });
    },
  }));

// eslint-disable-next-line no-multi-assign
const rootStore = (window.store = RootStore.create({}));

if (process.env.NODE_ENV === 'development') {
  makeInspectable(rootStore);
}

export default rootStore;
