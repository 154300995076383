import { getParent, types, flow } from 'mobx-state-tree';
import moment from 'moment';
import orderStatus from '../../constants/orders/orderStatus.json';

const OrdersFilters = types
  .model('OrdersFilters', {
    from: moment()
      .subtract(2, 'weeks')
      .format('YYYY-MM-DD'),
    to: moment().format('YYYY-MM-DD'),
    status: types.optional(types.array(types.string), orderStatus),
    name: '',
    draw: 0,
  })
  .views(self => ({
    get statusesObj() {
      return Object.fromEntries(self.status.map(st => [st, true]));
    },
  }))
  .actions(self => ({
    addDraw() {
      self.draw++;
    },
    setToDate(val) {
      self.to = val;
    },
    setFromDate(val) {
      self.from = val;
    },
    setName(val) {
      self.name = val;
    },
    toggleSellectAllStatuses(listLength) {
      if (self.status.length === listLength) {
        self.status = [];
      } else {
        self.status = orderStatus;
      }
    },
    removeStatus(status) {
      self.status = [...self.status].filter(s => s !== status);
    },
    setStatus(status) {
      self.status = [...self.status, status];
    },
    selectStatus(status) {
      if (self.statusesObj[status] === true) {
        self.removeStatus(status);
      } else {
        self.setStatus(status);
      }
    },
    resetValues() {
      self.from = '';
      self.to = '';
      self.status = [];
      self.name = '';
      self.draw = 0;
    },
  }));

export default OrdersFilters;
