/* eslint-disable no-confusing-arrow */
import styled from '@emotion/styled';
import { buttonDisables, buttonHover, buttonNormal } from '../../../constants/theme';

const iconWithColor = (color, type = '') => `
  background-color: ${color};
  -webkit-mask-image: url('/assets/icons/${type && type.toLowerCase()}.svg');
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  -webkit-mask-size: contain;
`;

const iconWithoutColor = (type = '') => `
  background: url('/assets/icons/${type &&
    type.toLowerCase()}.svg') no-repeat center/contain !important;
`;

const Action = styled('div')`
  position: relative;
  ${props => (props && props.disabled ? '' : 'cursor: pointer')};
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    ${props => (props && props.disabled ? '' : `background-color: ${buttonDisables}`)};
  }
`;

const IconSvg = styled('span')`
  width: ${props => props.width || props.size || 32}px;
  min-width: ${props => props.width || props.size || 32}px;
  height: ${props => props.height || props.size || 32}px;
  display: inline-block;
  vertical-align: middle;
  cursor: ${({ onClick, pointer }) => (onClick || pointer ? 'pointer' : 'inherit')};
  ${props => (props.rotate ? `transform: rotate(${props.rotate});` : '')}
  ${props => (props.themeColor ? `color: ${buttonNormal};` : '')}
  ${props =>
    props.color
      ? iconWithColor(props.color || buttonNormal, props.type)
      : iconWithoutColor(props.type)}

  ${({ marginRight }) => (marginRight ? `margin-right: ${marginRight};` : '')}
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop};` : '')}
  ${({ marginLeft }) => (marginLeft ? `margin-left: ${marginLeft};` : '')}
  ${props =>
    props.hoverColor
      ? `&:hover {
    ${iconWithColor(props.hoverColor, props.type)}
  }`
      : ''}

  ${Action}:hover & {
    ${props => !props.disableHoverEffect && `background-color: ${buttonHover}`};
  }
`;

export default IconSvg;
