import React from 'react';

const Spinner = () => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 999999,
    }}
  >
    <div className="loader" />;
  </div>
);

export default Spinner;
