import { types, getParent, flow } from 'mobx-state-tree';
import { snakeCase } from 'lodash';
import Cookies from 'universal-cookie';
import UserProfile from './UserProfile';
import Subscription from './Subscription';
import Balance from './Balance';
import ConnectionManagement from './ConnectionManagement';
import currencies from '../../constants/currency.json';

import ENUMS from '../../constants/enums';
import ENUMS_TRANSFORM from '../../constants/transformToEnum';

const cookies = new Cookies();

const SettingsStore = types
  .model('SettingsStore', {
    userProfile: types.optional(UserProfile, {}),
    connectionManagement: types.optional(ConnectionManagement, {}),
    subscription: types.optional(Subscription, {}),
    balance: types.optional(Balance, {}),
    initialized: types.optional(types.boolean, false),
    isSettingsOpen: false,
    activeSettingsTab: ENUMS.SETTINGS_TABS.SUBSCRIPTION,
    intercomVisible: true,
    currencyStore: cookies.get('currency') ? currencies[cookies.get('currency')].symbol : '$',
  })
  .views(self => ({
    // get accountCode() {
    //   return getParent(self).sessionStore.accountCode;
    // },
    get axios() {
      return getParent(self).axios;
    },
    // get userProfileForServer() {
    //   return self.userProfile.toJSON();
    // },
    get browserHistory() {
      return getParent(self).routingStore.browserHistory;
    },
    // get email() {
    //   return getParent(self).sessionStore.email;
    // },
  }))
  .actions(self => ({
    handlePayoneerAuth() {
      if (!window.location.search) {
        window.opener.postMessage({ type: 'OAuthAccessCode', payload: null }, '*');
        return;
      }
      self.balance
        .sendCodeAfterConnection(window.location.search.slice(6).split('&')[0])
        .then(r => {
          try {
            window.opener.postMessage(
              { type: 'OAuthAccessCode', payload: { ...r.data, isOk: true } },
              '*',
            );
          } catch (error) {
            console.error(error);
            window.opener.postMessage({ type: 'OAuthAccessCode', payload: { isOk: true } }, '*');
          }
        })
        .catch(err => {
          console.error(err);
          window.opener.postMessage(
            {
              type: 'OAuthAccessCode',
              payload: err.response ? { ...err.response.data, isOk: false } : { isOk: false },
            },
            '*',
          );
        });
    },
    initStore: flow(function* initStore() {
      try {
        self.initialized = false;
        if (window.location.pathname.indexOf('close') !== -1) {
          console.log('window: ', window);
          if (window.name === 'balance') {
            self.handlePayoneerAuth();
          }
        }
        // if (window.location.pathname === '/transactions') {
        //   self.balance.initBalance();
        // }
        // eslint-disable-next-line
        yield self.userProfile.fetchCurrentUser();
        yield Promise.allSettled([
          self.subscription.initSubscription(),
          self.connectionManagement.initConnections(),
          self.balance.initBalance(),
        ]);
      } catch (err) {
        console.error('initing setting store gone wrong: ', err);
      } finally {
        self.initialized = true;
        if (window.location.hash) {
          const parts = window.location.hash.slice(1).split('-');
          const tabName = snakeCase(parts[0]).toUpperCase();
          self.toggleSettings(ENUMS.SETTINGS_TABS[tabName], true);
        }
      }
    }),
    saveDataToServer(activeTab) {
      switch (activeTab) {
        case ENUMS.SETTINGS_TABS.SUBSCRIPTION: {
          break;
        }
        default: {
          break;
        }
      }
    },
    toggleSettings(activeSettingsTab = ENUMS.SETTINGS_TABS.SUBSCRIPTION, force = null) {
      const { isSettingsOpen, intercomVisible } = self;
      if (force === isSettingsOpen) {
        return;
      }
      const intercom = document.getElementsByClassName('intercom-launcher')[0];
      if (intercom) {
        if (!isSettingsOpen && intercomVisible) {
          intercom.style.visibility = 'hidden';
        } else if (isSettingsOpen && !intercomVisible) {
          intercom.style.visibility = 'visible';
        }
        self.intercomVisible = !intercomVisible;
      }
      self.isSettingsOpen = !isSettingsOpen;
      self.activeSettingsTab = activeSettingsTab;
      if (self.isSettingsOpen) {
        // self.updateHash(activeSettingsTab);
      } else {
        self.browserHistory.push(`${window.location.pathname}${window.location.search}`);
      }
    },
    setActiveTab(activeSettingsTab) {
      self.activeSettingsTab = activeSettingsTab;
    },
    changeCurrency(currency) {
      self.currencyStore = currency;
    },
    updateHash(activeSettingsTab) {
      const parts = window.location.hash.slice(1).split('-');
      if (parts[0] !== ENUMS_TRANSFORM.SETTINGS_TABS[activeSettingsTab]) {
        self.browserHistory.push(
          `${window.location.pathname}${window.location.search}#${ENUMS_TRANSFORM.SETTINGS_TABS[activeSettingsTab]}`,
        );
      }
    },
  }));
export default SettingsStore;
