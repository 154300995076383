import React from 'react';
import { injectIntl } from 'react-intl';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { colors, fontSize } from '../../../constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 15px;
`;

const Label = styled.label`
  margin-bottom: 0;
  line-height: 21px;
`;

const Filter = ({
  intlKey,
  children,
  intl,
  profitSign,
  extraCSS = '',
  medium = false,
  large = false,
}) => (
  <Container
    css={css`
      ${extraCSS}
    `}
  >
    {intlKey ? (
      <Label
        htmlFor={intlKey}
        css={css`
          font-size: ${large ? fontSize.large : medium ? fontSize.medium : fontSize.small};
          color: ${colors.greySecondary};
          font-weight: normal;
        `}
      >
        {`${intl.formatMessage({ id: intlKey })} ${profitSign || ''}`}
      </Label>
    ) : (
      <span style={{ height: '27px' }} />
    )}
    {children}
  </Container>
);

Filter.propTypes = {
  intlKey: PropTypes.string.isRequired,
  children: PropTypes.node,
  intl: PropTypes.object,
  profitSign: PropTypes.string,
};

export default observer(injectIntl(Filter));
