import { types, getParent } from 'mobx-state-tree';

const GeneralStore = types
  .model('GeneralStore', {})
  .views(self => ({
    get axios() {
      return getParent(self).axios;
    },
  }))
  .actions(self => ({}));

export default GeneralStore;
