import { types } from 'mobx-state-tree';

const MachineEditParams = types
  .model('MachineEditParams', {
    id: types.optional(types.maybe(types.number), 0),
    name: types.optional(types.string, ''),
    details: types.optional(types.string, ''),
  })
  .views(self => ({
    get editParamsValid() {
      return !!self.name.trim();
    },
  }))
  .actions(self => ({
    setName(val) {
      self.name = val;
    },
    setDetails(val) {
      self.details = val;
    },
    resetData() {
      self.id = null;
      self.name = '';
      self.details = '';
    },
  }));

export default MachineEditParams;
