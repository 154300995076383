import { getParent, types, isAlive } from 'mobx-state-tree';
import Firewall from './Firewall';
import Location from './Location';

function processing(status) {
  return status === 'pending' || status === 'rebooting';
}

const Machine = types
  .model('Machine', {
    id: types.number,
    name: types.string,
    external_account_id: types.string,
    machine_external_id: types.maybe(types.string),
    ip_address: types.maybe(types.string),
    vnc_address: types.maybe(types.string),
    details: types.maybe(types.string),
    status: types.string,
    created_at: types.string,
    updated_at: types.string,
    location: types.maybe(Location),
    firewalls: types.optional(types.array(Firewall), []),
    selected: false,
  })
  .views(self => ({
    get formattedLocation() {
      if (!self.location) {
        return '';
      }
      const { name, country, state } = self.location;
      const maybeState = state ? `${state}, ` : '';
      return `${name} (${maybeState}${country})`;
    },
    get isPending() {
      return self.status === 'pending';
    },
    get isActive() {
      return self.status === 'active';
    },
    get isProcessing() {
      return self.status === 'pending' || self.status === 'rebooting' || self.status === 'deleting';
    },
    get worksOnCurrentIP() {
      return !!self.firewalls.find(({ isCurrentIP }) => isCurrentIP);
    },
    get clientIP() {
      return getParent(self, 2).clientIP;
    },
  }))
  .actions(self => ({
    setSelected(val) {
      if (self.isProcessing && val === true) {
        return;
      }
      self.selected = val;
    },
    afterAttach() {
      if (processing(self.status)) {
        const interval = setInterval(() => {
          if (isAlive(self) && /^\/triple-cloud/.test(window.location.pathname)) {
            getParent(self, 2)
              .fetchMachine(self.id)
              .then(resp => {
                if (resp) {
                  if (!processing(resp.status)) {
                    getParent(self, 2).reloadMachine(self.id, resp);
                    clearInterval(interval);
                  }
                } else {
                  clearInterval(interval);
                }
              });
          } else {
            clearInterval(interval);
          }
        }, 5000);
      }
    },
  }));

export default Machine;
