import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { css } from '@emotion/core';
import { Layout } from 'antd';
import Header from '../Header';
import Sider from '../Sider';
import { colors } from '../../constants/theme';
// import { NewsPopUp } from '../NewsPopUp';

const { Content } = Layout;

const App = ({ children }) => (
  <Layout
    css={css`
      height: 100vh;
    `}
  >
    <Sider
      css={css`
        overflow: hidden;
      `}
    />
    <Layout
      css={css`
        background-color: ${colors.whiteLightBackground};
        margin-left: 118px;
      `}
    >
      {/* <NewsPopUp /> */}

      <Header />
      <Content
        css={css`
          width: 100%;
          padding-left: calc(50vw - 683px);
          padding-right: calc(50vw - 683px);
          margin: 0 auto;
          overflow: auto;
          padding-top: 30px;
          @media (max-width: 1400px) {
            padding: 20px;
          }
        `}
      >
        {children}
      </Content>
    </Layout>
  </Layout>
);

App.propTypes = {
  children: PropTypes.node,
};

export default inject('sessionStore')(observer(App));
