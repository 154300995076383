import React from 'react';
import PropTypes from 'prop-types';
import { Button as DefaultButton } from 'antd';
import styled from '@emotion/styled';

const Button = styled(DefaultButton)`
  width: ${({ width }) => width || null};
  height: ${({ height }) => height || null};
  border-radius: 16px;
  font-weight: 500;
  line-height: ${({ height }) => height || 1.67};

  ${({ type, theme }) =>
    type === 'secondary' &&
    `
    background-color ${theme.colors.whiteMediumBackground};
    box-shadow: 1px 1px 6px 0 rgba(0, 105, 250, 0.12);
    border-color: ${theme.colors.greySecondary};
  `};
`;

const CommonButton = props => {
  const {
    clickHandler,
    cssProps,
    type,
    htmlType,
    size,
    children,
    disabled,
    width,
    height,
    className,
  } = props;
  return (
    <Button
      className={className}
      onClick={clickHandler}
      type={type}
      htmlType={htmlType}
      size={size}
      css={cssProps}
      disabled={disabled}
      width={width}
      height={height}
    >
      {children}
    </Button>
  );
};

CommonButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  size: PropTypes.string,
  htmlType: PropTypes.string,
  type: PropTypes.string,
  cssProps: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  clickHandler: PropTypes.func,
  disabled: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
};

CommonButton.defaultProps = {
  type: 'primary',
  htmlType: 'submit',
  cssProps: {},
  disabled: false,
};

export default CommonButton;
