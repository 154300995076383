import styled from '@emotion/styled';
import { Row, Col, Modal, Card, Collapse } from 'antd';
import { themeColors } from '../../constants';

const { Panel } = Collapse;

export const CustomModal = styled(Modal)`
  top: 5.5em;
  right: 0;
  position: absolute;
  padding: 0;
  & .ant-modal-body {
    padding: 0;
    border-radius: 0;
  }
  & .ant-modal-content {
    border-radius: 0;
  }
`;

export const TopRow = styled(Row)`
  height: 5em;
  & > * {
    height: 100%;
    ${''};
  }
`;

export const BotRow = styled(Row)`
  height: 100%;
`;

export const ModalHeader = styled.span`
  padding-left: 15px;
  padding-bottom: 1em;
`;

export const ModalConentHeader = styled(Col)`
  padding-left: 15px !important;
  padding-bottom: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CustomCard = styled(Card)`
  border: 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${themeColors.lightGrey};
  }
  &:first-of-type {
    border-top: 1px solid ${themeColors.grey};
  }
  height: 6em;
  padding: 10px;
  padding-left: 1em;
  background-color: #f7fafd;
  cursor: pointer;
  & .ant-card-body {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 30%;
    border: ${props => (props.active ? `1px solid ${themeColors.blue}` : 'none')};
    color: ${props => (props.active ? themeColors.blue : 'unset')};
  }
`;

export const CustomPanel = styled(Panel)`
  border: 0 !important;
  border-top: 1px solid ${themeColors.grey} !important;
  &:last-child {
    border-bottom: 1px solid ${themeColors.grey} !important;
  }
  & .ant-collapse-content-box {
    padding: 0;
  }
  & > .ant-collapse-header {
    height: 5em;
    display: flex;
    align-items: center;
    padding-left: 30% !important;
    padding-right: 1em !important;
    color: ${themeColors.dirtyBlue} !important;
    & i.arrow {
      top: unset !important;
      left: 2em !important;
    }
  }
  &.ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0;
  }
`;

export const CustomBreadcrumbs = styled.span`
  font-size: 18px;
  color: ${themeColors.eggplantBlue};
`;

export const ModalCornerButton = styled.span`
  padding: 0 0.5em;
  cursor: pointer;
  position: relative;
  top: -5px;
  &:last-child {
    margin-right: 0.5em;
  }
`;

export const MainCol = styled(Col)`
  padding: 0 15px;
`;
