import styled from '@emotion/styled';
import { themeColors, colors } from '../../../constants';

export const InputTitle = styled.span`
  line-height: 28px;
`;

export const Input = styled.input`
  width: 162px;
  height: 22px;
  padding: 3.5px 10px 0.5px 10px;
  border-radius: 10px;
  border: solid 1px ${themeColors.dirtyLightBlue};
  line-height: 2.33;
  letter-spacing: normal;
  color: ${themeColors.dirtyBlue};
  &.error {
      border-color: ${colors.orange};
    }
  :focus,
  :hover {
    border: solid 1px ${themeColors.blue};
    outline: none;
  }
  &[disabled] {
    background-color: ${colors.blueBright};
    border: none;
    :focus,
    :hover {
      border: none;
    }
  }
`;

export const ErrorText = styled.span`
  line-height: 22px;
  color: ${colors.orange};
`
