export const upperThreeToTwo = {
  ALB: 'AL',
  DZA: 'DZ',
  ASM: 'AS',
  AND: 'AD',
  AGO: 'AO',
  AIA: 'AI',
  ATA: 'AQ',
  ATG: 'AG',
  ARG: 'AR',
  ARM: 'AM',
  ABW: 'AW',
  AUS: 'AU',
  AUT: 'AT',
  AZE: 'AZ',
  BHS: 'BS',
  BHR: 'BH',
  BGD: 'BD',
  BRB: 'BB',
  BLR: 'BY',
  BEL: 'BE',
  BLZ: 'BZ',
  BEN: 'BJ',
  BMU: 'BM',
  BTN: 'BT',
  BOL: 'BO',
  BES: 'BQ',
  BIH: 'BA',
  BWA: 'BW',
  BVT: 'BV',
  BRA: 'BR',
  IOT: 'IO',
  BRN: 'BN',
  BGR: 'BG',
  BFA: 'BF',
  BDI: 'BI',
  KHM: 'KH',
  CMR: 'CM',
  CAN: 'CA',
  CPV: 'CV',
  CYM: 'KY',
  TCD: 'TD',
  CHL: 'CL',
  CHN: 'CN',
  CXR: 'CX',
  CCK: 'CC',
  COL: 'CO',
  COM: 'KM',
  COG: 'CG',
  COD: 'CD',
  COK: 'CK',
  CRI: 'CR',
  HRV: 'HR',
  CUW: 'CW',
  CYP: 'CY',
  CZE: 'CZ',
  CIV: 'CI',
  DNK: 'DK',
  DJI: 'DJ',
  DMA: 'DM',
  DOM: 'DO',
  ECU: 'EC',
  EGY: 'EG',
  SLV: 'SV',
  GNQ: 'GQ',
  ERI: 'ER',
  EST: 'EE',
  ETH: 'ET',
  FLK: 'FK',
  FRO: 'FO',
  FJI: 'FJ',
  FIN: 'FI',
  FRA: 'FR',
  GUF: 'GF',
  PYF: 'PF',
  ATF: 'TF',
  GAB: 'GA',
  GMB: 'GM',
  GEO: 'GE',
  DEU: 'DE',
  GHA: 'GH',
  GIB: 'GI',
  GRC: 'GR',
  GRL: 'GL',
  GRD: 'GD',
  GLP: 'GP',
  GUM: 'GU',
  GTM: 'GT',
  GGY: 'GG',
  GIN: 'GN',
  GNB: 'GW',
  GUY: 'GY',
  HMD: 'HM',
  VAT: 'VA',
  HND: 'HN',
  HKG: 'HK',
  HUN: 'HU',
  ISL: 'IS',
  IND: 'IN',
  IDN: 'ID',
  IRL: 'IE',
  IMN: 'IM',
  ISR: 'IL',
  ITA: 'IT',
  JAM: 'JM',
  JPN: 'JP',
  JEY: 'JE',
  JOR: 'JO',
  KAZ: 'KZ',
  KEN: 'KE',
  KIR: 'KI',
  KOR: 'KR',
  KWT: 'KW',
  KGZ: 'KG',
  UNK: 'XK',
  LAO: 'LA',
  LVA: 'LV',
  LSO: 'LS',
  LIE: 'LI',
  LTU: 'LT',
  LUX: 'LU',
  MAC: 'MO',
  MKD: 'MK',
  MDG: 'MG',
  MWI: 'MW',
  MYS: 'MY',
  MDV: 'MV',
  MLI: 'ML',
  MLT: 'MT',
  MHL: 'MH',
  MTQ: 'MQ',
  MRT: 'MR',
  MUS: 'MU',
  MYT: 'YT',
  MEX: 'MX',
  FSM: 'FM',
  MDA: 'MD',
  MCO: 'MC',
  MNG: 'MN',
  MNE: 'ME',
  MSR: 'MS',
  MAR: 'MA',
  MOZ: 'MZ',
  NAM: 'NA',
  NRU: 'NR',
  NPL: 'NP',
  NLD: 'NL',
  NCL: 'NC',
  NZL: 'NZ',
  NIC: 'NI',
  NER: 'NE',
  NGA: 'NG',
  NIU: 'NU',
  NFK: 'NF',
  MNP: 'MP',
  NOR: 'NO',
  OMN: 'OM',
  PAK: 'PK',
  PLW: 'PW',
  PSE: 'PS',
  PAN: 'PA',
  PNG: 'PG',
  PRY: 'PY',
  PER: 'PE',
  PHL: 'PH',
  PCN: 'PN',
  POL: 'PL',
  PRT: 'PT',
  PRI: 'PR',
  QAT: 'QA',
  ROU: 'RO',
  RUS: 'RU',
  RWA: 'RW',
  REU: 'RE',
  BLM: 'BL',
  SHN: 'SH',
  KNA: 'KN',
  LCA: 'LC',
  MAF: 'MF',
  SPM: 'PM',
  VCT: 'VC',
  WSM: 'WS',
  SMR: 'SM',
  STP: 'ST',
  SAU: 'SA',
  SEN: 'SN',
  SRB: 'RS',
  SYC: 'SC',
  SLE: 'SL',
  SGP: 'SG',
  SXM: 'SX',
  SVK: 'SK',
  SVN: 'SI',
  SLB: 'SB',
  SOM: 'SO',
  ZAF: 'ZA',
  SGS: 'GS',
  SSD: 'SS',
  ESP: 'ES',
  LKA: 'LK',
  SUR: 'SR',
  SJM: 'SJ',
  SWZ: 'SZ',
  SWE: 'SE',
  CHE: 'CH',
  TWN: 'TW',
  TJK: 'TJ',
  TZA: 'TZ',
  THA: 'TH',
  TLS: 'TL',
  TGO: 'TG',
  TKL: 'TK',
  TON: 'TO',
  TTO: 'TT',
  TUN: 'TN',
  TUR: 'TR',
  TKM: 'TM',
  TCA: 'TC',
  TUV: 'TV',
  UGA: 'UG',
  UKR: 'UA',
  ARE: 'AE',
  GBR: 'GB',
  USA: 'US',
  UMI: 'UM',
  URY: 'UY',
  UZB: 'UZ',
  VUT: 'VU',
  VEN: 'VE',
  VNM: 'VN',
  VGB: 'VG',
  VIR: 'VI',
  WLF: 'WF',
  ESH: 'EH',
  YEM: 'YE',
  ZMB: 'ZM',
  ZWE: 'ZW',
};

export const flagsPaths = [
  { name: 'AND' },
  { name: 'ARE' },
  { name: 'ATG' },
  { name: 'ALB' },
  { name: 'ARM' },
  { name: 'AGO' },
  { name: 'ARG' },
  { name: 'AUT' },
  { name: 'AUS' },
  { name: 'AZE' },
  { name: 'BIH' },
  { name: 'BRB' },
  { name: 'BGD' },
  { name: 'BEL' },
  { name: 'BFA' },
  { name: 'BGR' },
  { name: 'BHR' },
  { name: 'BDI' },
  { name: 'BEN' },
  { name: 'BRN' },
  { name: 'BOL' },
  { name: 'BRA' },
  { name: 'BHS' },
  { name: 'BTN' },
  { name: 'BWA' },
  { name: 'BLR' },
  { name: 'BLZ' },
  { name: 'CAN' },
  { name: 'COD' },
  { name: 'COG' },
  { name: 'CHE' },
  { name: 'CIV' },
  { name: 'CHL' },
  { name: 'CMR' },
  { name: 'CHN' },
  { name: 'COL' },
  { name: 'CRI' },
  { name: 'CPV' },
  { name: 'CYP' },
  { name: 'CZE' },
  { name: 'DEU' },
  { name: 'DJI' },
  { name: 'DNK' },
  { name: 'DMA' },
  { name: 'DOM' },
  { name: 'DZA' },
  { name: 'ECU' },
  { name: 'EST' },
  { name: 'EGY' },
  { name: 'ESH' },
  { name: 'ERI' },
  { name: 'ESP' },
  { name: 'ETH' },
  { name: 'FIN' },
  { name: 'FJI' },
  { name: 'FSM' },
  { name: 'FRA' },
  { name: 'GAB' },
  { name: 'GBR' },
  { name: 'GRD' },
  { name: 'GEO' },
  { name: 'GHA' },
  { name: 'GMB' },
  { name: 'GIN' },
  { name: 'GNQ' },
  { name: 'GRC' },
  { name: 'GTM' },
  { name: 'GNB' },
  { name: 'GUY' },
  { name: 'HND' },
  { name: 'HRV' },
  { name: 'HUN' },
  { name: 'IDN' },
  { name: 'IRL' },
  { name: 'ISR' },
  { name: 'IND' },
  { name: 'ISL' },
  { name: 'ITA' },
  { name: 'JAM' },
  { name: 'JOR' },
  { name: 'JPN' },
  { name: 'KEN' },
  { name: 'KGZ' },
  { name: 'KHM' },
  { name: 'KIR' },
  { name: 'COM' },
  { name: 'KNA' },
  { name: 'KOR' },
  { name: 'UNK' },
  { name: 'KWT' },
  { name: 'KAZ' },
  { name: 'LAO' },
  { name: 'LCA' },
  { name: 'LIE' },
  { name: 'LKA' },
  { name: 'LSO' },
  { name: 'LTU' },
  { name: 'LUX' },
  { name: 'LVA' },
  { name: 'MAR' },
  { name: 'MCO' },
  { name: 'MDA' },
  { name: 'MNE' },
  { name: 'MDG' },
  { name: 'MHL' },
  { name: 'MKD' },
  { name: 'MLI' },
  { name: 'MNG' },
  { name: 'MRT' },
  { name: 'MLT' },
  { name: 'MUS' },
  { name: 'MDV' },
  { name: 'MWI' },
  { name: 'MEX' },
  { name: 'MYS' },
  { name: 'MOZ' },
  { name: 'NAM' },
  { name: 'NER' },
  { name: 'NGA' },
  { name: 'NIC' },
  { name: 'NLD' },
  { name: 'NOR' },
  { name: 'NPL' },
  { name: 'NRU' },
  { name: 'NZL' },
  { name: 'OMN' },
  { name: 'PAN' },
  { name: 'PER' },
  { name: 'PNG' },
  { name: 'PHL' },
  { name: 'PAK' },
  { name: 'POL' },
  { name: 'PRT' },
  { name: 'PLW' },
  { name: 'PRY' },
  { name: 'QAT' },
  { name: 'ROU' },
  { name: 'SRB' },
  { name: 'RUS' },
  { name: 'RWA' },
  { name: 'SAU' },
  { name: 'SLB' },
  { name: 'SYC' },
  { name: 'SWE' },
  { name: 'SGP' },
  { name: 'SVN' },
  { name: 'SVK' },
  { name: 'SLE' },
  { name: 'SMR' },
  { name: 'SEN' },
  { name: 'SOM' },
  { name: 'SUR' },
  { name: 'STP' },
  { name: 'SLV' },
  { name: 'SWZ' },
  { name: 'TCD' },
  { name: 'TGO' },
  { name: 'THA' },
  { name: 'TJK' },
  { name: 'TLS' },
  { name: 'TKM' },
  { name: 'TUN' },
  { name: 'TON' },
  { name: 'TUR' },
  { name: 'TTO' },
  { name: 'TUV' },
  { name: 'TWN' },
  { name: 'TZA' },
  { name: 'UKR' },
  { name: 'UGA' },
  { name: 'USA' },
  { name: 'URY' },
  { name: 'UZB' },
  { name: 'VAT' },
  { name: 'VCT' },
  { name: 'VEN' },
  { name: 'VNM' },
  { name: 'VUT' },
  { name: 'WSM' },
  { name: 'YEM' },
  { name: 'ZAF' },
  { name: 'ZMB' },
  { name: 'ZWE' },
];

export const upperTwoToName = {
  AX: 'Åland Islands',
  AL: 'Albania',
  DZ: 'Algeria',
  AS: 'American Samoa',
  AD: 'Andorra',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctica',
  AG: 'Antigua and Barbuda',
  AR: 'Argentina',
  AM: 'Armenia',
  AW: 'Aruba',
  AU: 'Australia',
  AT: 'Austria',
  AZ: 'Azerbaijan',
  BS: 'Bahamas',
  BH: 'Bahrain',
  BD: 'Bangladesh',
  BB: 'Barbados',
  BY: 'Belarus',
  BE: 'Belgium',
  BZ: 'Belize',
  BJ: 'Benin',
  BM: 'Bermuda',
  BT: 'Bhutan',
  BO: 'Bolivia, Plurinational State of bolivia',
  BA: 'Bosnia and Herzegovina',
  BW: 'Botswana',
  BV: 'Bouvet Island',
  BR: 'Brazil',
  IO: 'British Indian Ocean Territory',
  BN: 'Brunei Darussalam',
  BG: 'Bulgaria',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodia',
  CM: 'Cameroon',
  CA: 'Canada',
  CV: 'Cape Verde',
  KY: 'Cayman Islands',
  TD: 'Chad',
  CL: 'Chile',
  CN: 'China',
  CX: 'Christmas Island',
  CC: 'Cocos (Keeling) Islands',
  CO: 'Colombia',
  KM: 'Comoros',
  CG: 'Congo',
  CD: 'Congo, The Democratic Republic of the Congo',
  CK: 'Cook Islands',
  CR: 'Costa Rica',
  CI: "Cote d'Ivoire",
  HR: 'Croatia',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  EC: 'Ecuador',
  EG: 'Egypt',
  SV: 'El Salvador',
  GQ: 'Equatorial Guinea',
  ER: 'Eritrea',
  EE: 'Estonia',
  ET: 'Ethiopia',
  FK: 'Falkland Islands (Malvinas)',
  FO: 'Faroe Islands',
  FJ: 'Fiji',
  FI: 'Finland',
  FR: 'France',
  GF: 'French Guiana',
  PF: 'French Polynesia',
  TF: 'French Southern Territories',
  GA: 'Gabon',
  GM: 'Gambia',
  GE: 'Georgia',
  DE: 'Germany',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Greece',
  GL: 'Greenland',
  GD: 'Grenada',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernsey',
  GN: 'Guinea',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HM: 'Heard Island and Mcdonald Islands',
  VA: 'Holy See (Vatican City State)',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hungary',
  IS: 'Iceland',
  IN: 'India',
  ID: 'Indonesia',
  IE: 'Ireland',
  IM: 'Isle of Man',
  IL: 'Israel',
  IT: 'Italy',
  JM: 'Jamaica',
  JP: 'Japan',
  JE: 'Jersey',
  JO: 'Jordan',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KI: 'Kiribati',
  KR: 'Korea, Republic of South Korea',
  XK: 'Kosovo',
  KW: 'Kuwait',
  KG: 'Kyrgyzstan',
  LA: 'Laos',
  LV: 'Latvia',
  LS: 'Lesotho',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Macedonia',
  MG: 'Madagascar',
  MW: 'Malawi',
  MY: 'Malaysia',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malta',
  MH: 'Marshall Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MU: 'Mauritius',
  YT: 'Mayotte',
  MX: 'Mexico',
  FM: 'Micronesia, Federated States of Micronesia',
  MD: 'Moldova',
  MC: 'Monaco',
  MN: 'Mongolia',
  ME: 'Montenegro',
  MS: 'Montserrat',
  MA: 'Morocco',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NR: 'Nauru',
  NP: 'Nepal',
  NL: 'Netherlands',
  AN: 'Netherlands Antilles',
  NC: 'New Caledonia',
  NZ: 'New Zealand',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigeria',
  NU: 'Niue',
  NF: 'Norfolk Island',
  MP: 'Northern Mariana Islands',
  NO: 'Norway',
  OM: 'Oman',
  PK: 'Pakistan',
  PW: 'Palau',
  PS: 'Palestinian Territory, Occupied',
  PA: 'Panama',
  PG: 'Papua New Guinea',
  PY: 'Paraguay',
  PE: 'Peru',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Poland',
  PT: 'Portugal',
  PR: 'Puerto Rico',
  QA: 'Qatar',
  RO: 'Romania',
  RU: 'Russia',
  RW: 'Rwanda',
  RE: 'Reunion',
  BL: 'Saint Barthelemy',
  SH: 'Saint Helena, Ascension and Tristan Da Cunha',
  KN: 'Saint Kitts and Nevis',
  LC: 'Saint Lucia',
  MF: 'Saint Martin',
  PM: 'Saint Pierre and Miquelon',
  VC: 'Saint Vincent and the Grenadines',
  WS: 'Samoa',
  SM: 'San Marino',
  ST: 'Sao Tome and Principe',
  SA: 'Saudi Arabia',
  SN: 'Senegal',
  RS: 'Serbia',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapore',
  SK: 'Slovakia',
  SI: 'Slovenia',
  SB: 'Solomon Islands',
  SO: 'Somalia',
  ZA: 'South Africa',
  SS: 'South Sudan',
  GS: 'South Georgia and the South Sandwich Islands',
  ES: 'Spain',
  LK: 'Sri Lanka',
  SR: 'Suriname',
  SJ: 'Svalbard and Jan Mayen',
  SZ: 'Swaziland',
  SE: 'Sweden',
  CH: 'Switzerland',
  TW: 'Taiwan',
  TJ: 'Tajikistan',
  TZ: 'Tanzania, United Republic of Tanzania',
  TH: 'Thailand',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinidad and Tobago',
  TN: 'Tunisia',
  TR: 'Turkey',
  TM: 'Turkmenistan',
  TC: 'Turks and Caicos Islands',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UA: 'Ukraine',
  AE: 'United Arab Emirates',
  GB: 'United Kingdom',
  UK: 'United Kingdom',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VU: 'Vanuatu',
  VE: 'Venezuela, Bolivarian Republic of Venezuela',
  VN: 'Vietnam',
  VG: 'Virgin Islands, British',
  VI: 'Virgin Islands, U.S.',
  WF: 'Wallis and Futuna',
  YE: 'Yemen',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};
