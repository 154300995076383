import { types } from 'mobx-state-tree';
import { AddOnPlan } from './AddOnPlan';

export const AddOn = types
  .model('Plan', {
    id: types.string,
    name: types.string,
    description: types.maybe(types.string),
    plans: types.optional(types.array(AddOnPlan), []),
  })
  .views(self => ({
    get isSelected() {
      return self.plans.some(pl => pl.isSelected);
    },
    get selectedPlanId() {
      return self.plans.find(pl => pl.isSelected)?.id ?? null;
    },
  }))
  .actions(self => ({
    hasPlan(id) {
      return self.plans.some(pl => pl.id === id);
    },
  }));
