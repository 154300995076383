import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { css } from '@emotion/core';
import { injectIntl } from 'react-intl';
import { Form, Table, Carousel, Modal, Popover } from 'antd';
import moment from 'moment';
import { SettingsFormHeader } from '../../common/SettingsFormHeader';
import SubscriptionPlanCard from './SubscriptionPlanCard';
import IconSvg from '../../common/IconSvg';
import Button from '../../common/Button';
import { config } from './config';
import { FormItem } from '../commonUI/commonUI';
import { PlansContainer } from './styled';
import { Arrow } from '../../common/styled';
import { colors, themeColors } from '../../../constants';
import Spinner from '../../common/spinner';
import { getUuid } from '../../../utils/generators';

const { confirm } = Modal;
const { slidesToScroll, slidesToShow } = config;

const getFailReason = transaction => {
  const errorMsg = transaction?.extra?.error_msg || 'No failure reason provided.';
  return errorMsg.includes('message:') ? errorMsg.split('message: ')[1] : errorMsg;
};

@injectIntl
@Form.create()
@inject('settingsStore')
@inject('localeStore')
@inject('routingStore')
@observer
class Subscription extends Component {
  constructor() {
    super();
    this.state = {
      isMonthlyPayment: true,
      hoveringCardVisible: false,
      clickedPlanIndex: -1,
      payPalConnectionLoading: false,
      initialSlide: null,
      modalContentRef: document.getElementById('tm-settings-modal-content'),
      disableUpgradePlan: false,
    };
    this.closeCard = this.closeCard.bind(this);
  }

  componentDidMount() {
    const {
      updateBreadcrumbs,
      intl,
      settingsStore: {
        subscription: { currentPlanId, plans },
      },
    } = this.props;
    const { modalContentRef } = this.state;
    updateBreadcrumbs(
      {
        name: intl.formatMessage({ id: 'settingsModal.subscription' }),
      },
      true,
    );
    const actualModalContentRef =
      modalContentRef ?? document.getElementById('tm-settings-modal-content');
    const currPlanIndex = plans.filter(plan => !plan.isFree).findIndex(p => p.id === currentPlanId);
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      initialSlide: currPlanIndex === -1 ? 0 : currPlanIndex,
      modalContentRef: actualModalContentRef,
    });
    const parts = window.location.hash.slice(1).split('-');
    if (parts[1]) {
      const hashPlanIndex = plans.filter(plan => !plan.isFree).findIndex(p => p.id === parts[1]);
      this.handlePlanCardClick(hashPlanIndex, parts[1]);
    }
  }

  onPaymentMethodChange = e => {
    const val = e.target.value;
    this.setState({ isMonthlyPayment: val === 1 });
  };

  paypalInput = null;

  hasErrors = fieldsError => Object.keys(fieldsError).some(field => fieldsError[field]);

  closeAccount = () => {
    const {
      intl,
      settingsStore: {
        userProfile: { trackEventIntercom },
      },
    } = this.props;
    confirm({
      content: intl.formatMessage({ id: 'settingsModal.closeAccount' }),
      onOk() {
        console.log('TBD: ');
      },
      onCancel() {},
    });
    trackEventIntercom('close_account_clicked');
  };

  closeCard = () => {
    this.setState({
      hoveringCardVisible: false,
    });
  };

  closeCardAndScrollTop = () => {
    const { modalContentRef } = this.state;
    this.setState(
      {
        hoveringCardVisible: false,
      },
      () => {
        modalContentRef.scrollTop = 0;
      },
    );
  };

  handlePlanCardClick = (index, id) => {
    const {
      routingStore: { browserHistory },
    } = this.props;
    this.setState({
      clickedPlanIndex: index,
      hoveringCardVisible: true,
    });
  };

  confirmPlanChange = ({ price, currency, id }, payoneerAccount) => {
    const isForPayoneer = payoneerAccount.length > 0;
    const {
      settingsStore: {
        connectionManagement: { connectAndPay, makePayoneerPayment },
        subscription: { billingPaypalAccountId, currentPlanId },
      },
      refreshSubscriptionClone,
    } = this.props;
    isForPayoneer
      ? makePayoneerPayment({ id, currency, userAccount: payoneerAccount }, true).then(() =>
          refreshSubscriptionClone(),
        )
      : connectAndPay(price, currency, id, null, billingPaypalAccountId).then(() => {
          refreshSubscriptionClone();
        });
    this.setState({
      hoveringCardVisible: false,
    });
  };

  loadMore = () => {
    const {
      settingsStore: {
        subscription: { fetchNextInvoicesPage, setLoading },
      },
    } = this.props;
    setLoading(true);
    fetchNextInvoicesPage(true).then(() => {
      setLoading(false);
    });
  };

  loadMoreTransactions = () => {
    const {
      settingsStore: {
        subscription: { fetchNextTransactionsPage, setLoading },
      },
    } = this.props;
    setLoading(true);
    fetchNextTransactionsPage(true).then(() => {
      setLoading(false);
    });
  };

  downloadInvoice(id, created) {
    const {
      settingsStore: {
        subscription: { downloadTransactionInvoice },
      },
    } = this.props;
    downloadTransactionInvoice(id, created);
  }

  columns_invoices = [
    {
      title: 'Date',
      dataIndex: 'created',
      key: 'created',
      render: text => {
        return text && moment(text).format('YYYY-MM-DD');
      },
    },
    {
      title: 'Name',
      dataIndex: 'user_email',
      key: 'user_email',
    },
    {
      title: 'Invoice Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Invoice',
      dataIndex: 'invoice_url',
      key: 'invoice_url',
      render: (text, { id, created }) => (
        <span onClick={() => this.downloadInvoice(id, created)} style={{ cursor: 'pointer' }}>
          <IconSvg type="pdf-file" />
        </span>
      ),
    },
  ];

  columns_transactions = [
    {
      title: 'Date',
      dataIndex: 'created',
      render: text => {
        return text && moment(text).format('YYYY-MM-DD');
      },
    },
    {
      title: 'Name',
      dataIndex: 'user_email',
    },
    {
      title: 'Txn Id',
      dataIndex: 'id',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
    {
      title: 'Provider',
      dataIndex: 'payment_provider',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, record) => {
        return record.status === 'FAILED' ? (
          <Popover
            title=""
            content={
              <span
                css={css`
                  color: ${themeColors.pink};
                `}
              >
                {getFailReason(record)}
              </span>
            }
            trigger="hover"
            placement="left"
          >
            <IconSvg type="x-pink" size={20} style={{ cursor: 'pointer' }} />
          </Popover>
        ) : (
          <IconSvg type="v-green" size={20} />
        );
      },
    },
  ];

  render() {
    const {
      intl,
      localeStore: { isJA },
      settingsStore: {
        subscription: {
          currentPlanActive,
          currentPlan,
          currentPlanId,
          plans,
          loading,
          invoices,
          invoicesPagination,
          cancelSubscription,
          transactions,
          transactionsPagination,
        },
      },
    } = this.props;

    const {
      hoveringCardVisible,
      clickedPlanIndex,
      isMonthlyPayment,
      payPalConnectionLoading,
      initialSlide,
      disableUpgradePlan,
    } = this.state;

    const clickedPlan = plans.filter(plan => !plan.isFree)[clickedPlanIndex] || null;
    const hoveringCardAction = this.confirmPlanChange;
    const hoveringCardButtonMessage = intl.formatMessage({ id: 'subscription.agree' });

    return (
      <Form className="login-form" layout="vertical">
        <SettingsFormHeader
          key="billing-info-header"
          id="subscription.billingInformation"
          defaultMessage="BILLING INFORMATION"
        />
        <FormItem key="last-bill">
          <SettingsFormHeader id="subscription.invoices" defaultMessage="INVOICES" />
          <Table
            key="reloads"
            rowKey="id"
            scroll={{ y: 300 }}
            pagination={false}
            columns={this.columns_invoices}
            dataSource={invoices}
          />
        </FormItem>
        <div key="reloads_show_all" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {invoicesPagination.total > invoices.length && (
            <Button type="normal" clickHandler={this.loadMore}>
              Show more
            </Button>
          )}
        </div>
        <FormItem key="transactions">
          <SettingsFormHeader id="TRANSACTIONS" defaultMessage="TRANSACTIONS" />{' '}
          <Table
            key="transactions"
            rowKey="id"
            scroll={{ y: 300 }}
            pagination={false}
            columns={this.columns_transactions}
            dataSource={transactions}
          />
        </FormItem>
        <div key="transactions_show_all" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {transactionsPagination.total > transactions.length && (
            <Button type="normal" clickHandler={this.loadMoreTransactions}>
              Show more
            </Button>
          )}
        </div>
        <SettingsFormHeader
          key="my-plan-header"
          id="subscription.mySubscriptionPlan"
          defaultMessage="MY SUBSCRIPTION PLAN"
        />
        <PlansContainer>
          {initialSlide !== null && (
            <Carousel
              infinite={false}
              initialSlide={0}
              dots
              arrows
              slidesToShow={slidesToShow}
              slidesToScroll={slidesToScroll}
              css={css`
                ul.slick-dots {
                  bottom: -11px;
                  & > li > button {
                    border: 1px solid ${colors.greyInputBorder};
                    border-radius: 3px;
                    background: ${colors.whiteBackground};
                    height: 6px;
                    opacity: 1;
                  }
                  & > li.slick-active > button {
                    background: ${colors.blueMed};
                    border: 1px solid ${colors.blueMed};
                  }
                }
              `}
              nextArrow={<Arrow type="carousel-arrow-r" />}
              prevArrow={<Arrow flip type="carousel-arrow-r" />}
            >
              {plans
                .filter(plan => !plan.isFree)
                .map((plan, index) => {
                  return (
                    <SubscriptionPlanCard
                      key={`plan-card-${plan.id}`}
                      selectedPlanCost={currentPlanActive ? currentPlan.price || 0 : 0}
                      plan={plan}
                      currentPlan={currentPlan ?? null}
                      selected={plan.id === currentPlanId}
                      upgradePlan={() => this.handlePlanCardClick(index, plan.id)}
                      monthlyPayment={isMonthlyPayment}
                      disableUpgrade={disableUpgradePlan}
                      isJA={isJA}
                    />
                  );
                })}
            </Carousel>
          )}
        </PlansContainer>
        {moment().isBefore(currentPlan?.plan_expiry) && (
          <div
            key="cancel_subscription_btn"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <Button
              type="danger"
              size="small"
              clickHandler={() => {
                console.log(currentPlan);
                cancelSubscription();
              }}
            >
              Cancel Subscription
            </Button>
          </div>
        )}
        {hoveringCardVisible && clickedPlan !== null && (
          <div
            css={css`
              position: fixed;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              z-index: 1;
            `}
          >
            <div
              css={css`
                position: absolute;
                overflow: auto;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.65);
                z-index: 1;
              `}
              id="subplancard_fill"
              onClick={this.closeCard}
            >
              <SubscriptionPlanCard
                key={`plan-card-${clickedPlan.id}`}
                selectedPlanCost={currentPlanActive ? currentPlan.price || 0 : 0}
                plan={clickedPlan}
                currentPlan={currentPlan ?? null}
                selected={clickedPlan.id === currentPlanId}
                upgradePlan={hoveringCardAction}
                hoveringCardButtonMessage={hoveringCardButtonMessage}
                monthlyPayment={isMonthlyPayment}
                poppedOver
                closeCard={this.closeCard}
              />
            </div>
          </div>
        )}
        {payPalConnectionLoading || (loading && <Spinner />)}
      </Form>
    );
  }
}

Subscription.propTypes = {
  intl: PropTypes.object,
  settingsStore: PropTypes.object,
  updateBreadcrumbs: PropTypes.func,
  localeStore: PropTypes.object,
  refreshSubscriptionClone: PropTypes.func,
};

export default Subscription;
