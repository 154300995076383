import styled from '@emotion/styled';
import { Modal } from 'antd';
import { css } from '@emotion/core';
import React from 'react';
import BraintreeDropin from 'braintree-dropin-react';
import CommonButton from '../common/Button';
import { colors, fontSize } from '../../constants';
import IconSvg from '../common/IconSvg';
// import { Row, Col, Modal, Card, Collapse } from 'antd';

export const CustomModal = styled(Modal)`
  top: 5.5em;
  left: 175px;
  transition: width 0.5s;
  padding: 0;
  & .ant-modal-body {
    padding: 0;
    border-radius: 0;
    height: 100%;
  }
`;

export const CloseButtonContainer = styled.div`
  width: 100%;
  position: relative;
  & > span {
    position: absolute;
    margin: 15px;
    right: 0;
    top: 0;
  }
`;

export const ContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  ${({ noMargin }) => (noMargin ? '' : 'margin: 16px 0;')}
  ${({ height }) => height && `height: ${height};`}
  ${({ width }) => width && `width: ${width};`}
`;

export const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  ${({ height }) => height && `height: ${height};`}
  z-index: 1;
`;
export const ButtonsContainer = styled.div`
  padding: 1em;
  & > div > button {
    margin: 0 8px;
  }
`;

export const PopUpBody = styled.div`
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
`;

export const SplitRow = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AddToSumButton = ({ setLoadSum, sumValue, currencyValue = '$' }) => {
  return (
    <CommonButton
      clickHandler={() => setLoadSum(sumValue)}
      type="ghost"
      height="32px"
      css={css`
        padding: 0 10px 0 5px;
        line-height: 20px;
        :hover,
        :focus {
          border-color: ${colors.greenBright};
          * {
            color: ${colors.greenBright};
          }
        }
        * {
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
      `}
    >
      <ContentRow noMargin>
        <IconSvg type="positive" size={20} />
        <span
          css={css`
            color: ${colors.greySecondary};
            font-size: ${fontSize.medium};
          `}
        >
          {currencyValue}
          {sumValue}
        </span>
      </ContentRow>
    </CommonButton>
  );
};

export const Background = props => (
  <div
    css={css`
      width: 520px;
      height: 200px;
      border-radius: 4px;
      background-color: #edf4fc;
      position: absolute;
      top: 50px;
      z-index: 0;
    `}
  >
    <div
      css={css`
        width: 67.6px;
        height: 200px;
        overflow: hidden;
      `}
    >
      <div
        css={css`
          border-radius: 50%;
          width: 200px;
          height: 200px;
          margin-left: ${props.big ? '-200.9px' : '-152.3px'};
          background-color: #e4edf7;
        `}
      />
    </div>
  </div>
);

export const MyBraintreeDropin = styled(BraintreeDropin)`
  .braintree-placeholder {
    display: none;
  }
  .braintree-dropin-react-submit-btn-wrapper {
    margin-top: 8px;
    text-align: center;
  }
`;
