import { types } from 'mobx-state-tree';

const Transaction = types.model('Transaction', {
  accountId: types.string,
  month: types.string,
  invoice_url: types.string,
  created: types.string,
  id: types.string,
  short_id: types.number,
  type: types.string,
  user_email: types.string,
});
// .views(self => ({
// }));

export default Transaction;
