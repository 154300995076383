import styled from '@emotion/styled';
import BraintreeDropin from 'braintree-dropin-react';

export const MyBraintreeDropin = styled(BraintreeDropin)`
  .braintree-dropin-react-submit-btn-wrapper {
    margin-top: 13px;
    text-align: center;
  }
`;

export const Container = styled('div')`
  ${({ noPadding }) => (noPadding ? '' : 'padding: 0 50px;')}
  max-width: 1248px;
`;
