import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { FormattedMessage } from 'react-intl';
import { Button, Select, Modal } from 'antd';
import CommonButton from '../common/Button';
import IconSvg from '../common/IconSvg';
import {
  CloseButtonContainer,
  CustomModal,
  ContentRow,
  PopUpBody,
  ButtonsContainer,
  Background,
  ContentColumn,
} from './styled';
import { themeColors, fontSize } from '../../constants';

const { Option } = Select;
const { confirm } = Modal;

@inject('settingsStore')
@observer
class LoadBalanceModalPayoneer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinnerShown: false,
      pnrAccount: props.settingsStore.balance.payoneerPaymentOptions[0] || '',
      newAccountLoaded: false,
      newAccount: props.settingsStore.balance.payoneerPaymentOptions.length
        ? ''
        : props.settingsStore.email,
    };
  }

  componentWillUnmount() {
    const {
      settingsStore: {
        balance: { fetchPayoneerPaymentOptions, payoneerConnected },
      },
    } = this.props;
    if (!payoneerConnected) {
      fetchPayoneerPaymentOptions();
    }
  }

  setSpinnerShown = val => {
    this.setState({ spinnerShown: val });
  };

  setNewAccount = e => {
    this.setState({ newAccount: e.target.value });
  };

  updatePnrAccount = value => {
    const {
      settingsStore: {
        balance: { setPayoneerConnected },
      },
    } = this.props;
    if (value !== '__other__') {
      this.setState({
        pnrAccount: value,
      });
    } else {
      setPayoneerConnected(false);
      this.handleNewAccount();
    }
  };

  showConfirmToDelete = value => {
    confirm({
      content: `Are you sure you want to delete "${value}"?`,
      onOk: () => {
        this.deletePnrAccount(value);
      },
      onCancel() {},
    });
  };

  deletePnrAccount = value => {
    const {
      settingsStore: {
        balance: { payoneerPaymentOptions, setPaypalConnected, deletePayoneerPaymentOption },
      },
    } = this.props;
    const { pnrAccount } = this.state;
    if (value === pnrAccount) {
      if (payoneerPaymentOptions.length === 1) {
        deletePayoneerPaymentOption(value).then(resp => {
          resp && setPaypalConnected(false);
        });
      } else {
        deletePayoneerPaymentOption(value).then(resp => {
          resp && this.updatePPAccount(payoneerPaymentOptions[0]);
        });
      }
    } else {
      deletePayoneerPaymentOption(value);
    }
  };

  handleNewAccount = () => {
    const { loadNow, closeModal } = this.props;
    const { newAccount } = this.state;
    this.setSpinnerShown(true);
    loadNow(newAccount).then(() => {
      this.setSpinnerShown(false);
      closeModal();
    });
  };

  render() {
    const buttonCss = { minWidth: '150px' };

    const {
      visible,
      closeModal,
      openFinanceSettings,
      openBalanceStatus,
      loadSum,
      commission,
      loadNow,
      settingsStore: {
        balance: { payoneerConnected, payoneerPaymentOptions },
        currencyStore,
      },
    } = this.props;
    const {
      spinnerShown,
      newAccountLoaded,
      pnrAccount,
      // newAccount
    } = this.state;
    return (
      <CustomModal
        visible={visible}
        onCancel={closeModal}
        closable={false}
        width="520px"
        footer={null}
      >
        {spinnerShown ? <div className="loader" /> : null}
        <CloseButtonContainer>
          <IconSvg type="x" onClick={closeModal} size={10} />
        </CloseButtonContainer>
        <PopUpBody>
          <ContentRow noMargin height="50px">
            <h1
              css={css`
                text-align: center;
                font-family: Heebo-Medium;
                color: ${themeColors.blue};
                margin: 0 0 0 17px;
                display: inline-block;
              `}
            >
              <FormattedMessage id="balance.paymentConfirmation" />
            </h1>
          </ContentRow>
          <ContentColumn>
            {payoneerConnected && !newAccountLoaded ? (
              <React.Fragment>
                <ContentRow noMargin>
                  <Select
                    onChange={this.updatePnrAccount}
                    value={pnrAccount}
                    size="large"
                    css={css`
                      width: 300px;
                      & .ant-select-selection-selected-value {
                        > span[type='delete'] {
                          visibility: hidden;
                        }
                      }
                    `}
                  >
                    {payoneerPaymentOptions.map(name => (
                      <Option
                        key={name}
                        value={name}
                        style={{
                          cursor: 'default',
                          paddingRight: '32px',
                        }}
                      >
                        <span
                          css={css`
                            cursor: pointer;
                          `}
                        >
                          {name}
                        </span>
                        <IconSvg
                          onClick={e => {
                            e.stopPropagation();
                            this.showConfirmToDelete(name);
                          }}
                          type="delete"
                          size={16}
                          css={css`
                            position: absolute;
                            right: 15px;
                          `}
                        />
                      </Option>
                    ))}
                    <Option key="other" value="__other__">
                      <span style={{ fontFamily: 'Heebo-Thin' }}>
                        <FormattedMessage id="listing.connectAnother" />
                      </span>
                    </Option>
                  </Select>
                </ContentRow>
                <ContentRow>
                  <span style={{ fontSize: fontSize.giant }}>
                    <FormattedMessage
                      id="balance.loadCurrency"
                      values={{ currency: currencyStore, sum: loadSum + commission }}
                    />
                  </span>
                </ContentRow>
                <ContentRow>
                  <CommonButton
                    cssProps={{ borderRadius: '20px', minWidth: '128px' }}
                    type="primary"
                    size="large"
                    disabled={spinnerShown}
                    clickHandler={() => {
                      this.setSpinnerShown(true);
                      loadNow(pnrAccount).then(() => {
                        this.setSpinnerShown(false);
                        closeModal();
                      });
                    }}
                  >
                    <FormattedMessage id="balance.loadNow" />
                  </CommonButton>
                </ContentRow>
              </React.Fragment>
            ) : (
              <>
                {!payoneerPaymentOptions.length && (
                  <ContentRow noMargin>
                    <span style={{ fontSize: fontSize.giant }}>
                      <FormattedMessage id="balance.noAccountConnected" />
                    </span>
                  </ContentRow>
                )}
                {/* <ContentRow noMargin> */}
                {/*   <CustomInput */}
                {/*     height="32px" */}
                {/*     type="text" */}
                {/*     value={newAccount} */}
                {/*     onChange={this.setNewAccount} */}
                {/*     placeholder="Payoneer Account Email" */}
                {/*   /> */}
                {/* </ContentRow> */}
                <ContentRow>
                  <Button
                    onClick={this.handleNewAccount}
                    type="primary"
                    disabled={this.state.spinnerShown}
                    size="large"
                    style={{ borderRadius: '20px' }}
                  >
                    <FormattedMessage id="settingsModal.connectAndPay" />
                  </Button>
                </ContentRow>
              </>
            )}
          </ContentColumn>
          <ButtonsContainer>
            <ContentRow noMargin>
              <CommonButton
                cssProps={buttonCss}
                clickHandler={openBalanceStatus}
                height="25px"
                type="normal"
              >
                <FormattedMessage id="balance.balanceStatus" />
              </CommonButton>
              <CommonButton
                cssProps={buttonCss}
                clickHandler={() => {
                  closeModal();
                  openFinanceSettings();
                }}
                height="25px"
                type="primary"
                // disabled
              >
                <FormattedMessage id="balance.autoReload" />
              </CommonButton>
            </ContentRow>
          </ButtonsContainer>
        </PopUpBody>
        <Background />
      </CustomModal>
    );
  }
}

LoadBalanceModalPayoneer.propTypes = {
  settingsStore: PropTypes.object,
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  openFinanceSettings: PropTypes.func,
  openBalanceStatus: PropTypes.func,
  loadSum: PropTypes.number,
  loadNow: PropTypes.func,
};

export default LoadBalanceModalPayoneer;
