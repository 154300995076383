import React, { Component } from 'react';
import { css } from '@emotion/core';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import CommonButton from '../common/Button';
import IconSvg from '../common/IconSvg';
import currencies from '../../constants/currency';

import {
  CloseButtonContainer,
  CustomModal,
  ContentRow,
  PopUpBody,
  ButtonsContainer,
  SplitRow,
  ContentColumn,
  Background,
} from './styled';
import { themeColors } from '../../constants';

@inject('settingsStore')
@inject('routingStore')
@observer
class BalanceStatusModal extends Component {
  componentDidMount() {
    const {
      settingsStore: {
        balance: { fetchBalanceData },
      },
    } = this.props;
    fetchBalanceData();
  }

  updateRouteState = path => {
    const { routingStore } = this.props;
    routingStore.browserHistory.push(`/${path}`);
  };

  render() {
    const buttonCss = { minWidth: '150px' };
    const {
      visible,
      closeModal,
      openBalance,
      settingsStore: {
        balance: { current, fetchBalanceData },
      },
    } = this.props;
    fetchBalanceData();

    return (
      <CustomModal
        visible={visible}
        onCancel={closeModal}
        closable={false}
        width="520px"
        footer={null}
      >
        <CloseButtonContainer>
          <IconSvg type="x" onClick={closeModal} size={10} />
        </CloseButtonContainer>
        <PopUpBody>
          <ContentRow noMargin height="50px">
            <h1
              css={css`
                text-align: center;
                font-family: Heebo-Medium;
                color: ${themeColors.blue};
                margin: 0 0 0 17px;
                display: inline-block;
              `}
            >
              Available Balance
            </h1>
          </ContentRow>
          <ContentColumn>
            {Object.keys(current).map(key => (
              <SplitRow key={key}>
                <h3>{key}</h3>
                <span>
                  {currencies[key].symbol}
                  {current[key]}
                </span>
              </SplitRow>
            ))}
          </ContentColumn>
          <ButtonsContainer>
            <ContentRow noMargin>
              <CommonButton
                cssProps={buttonCss}
                height="25px"
                type="primary"
                clickHandler={openBalance}
              >
                <FormattedMessage id="balance.loadBalance" />
              </CommonButton>
              <CommonButton
                cssProps={buttonCss}
                clickHandler={() => {
                  closeModal();
                  this.updateRouteState('balance');
                }}
                height="25px"
                type="primary"
              >
                <FormattedMessage id="balance.autoReload" />
              </CommonButton>
            </ContentRow>
          </ButtonsContainer>
        </PopUpBody>
        <Background />
      </CustomModal>
    );
  }
}

export default BalanceStatusModal;
