import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Cookies from 'universal-cookie';
import { Select, Modal } from 'antd';
import Dropdown from '../common/Dropdown';
import IconPng from '../common/IconPng';
import { fontSize } from '../../constants';

const { Option } = Select;

const cookies = new Cookies();

const options = [
  {
    text: '$',
    value: 'USD',
    icon: 'flags/round/us/us',
  },
  {
    text: '£',
    value: 'GBP',
    icon: 'flags/round/uk/uk',
  },
];

const CurrencyDropdown = ({
  defaultValue,
  forceUpdateHandler,
  balance,
  singleOrder,
  removeSingleOrder,
  bulkOrder,
  setBulkOrder,
  setBulkOrderFilename,
  setBulkOrderTableView,
}) => {
  const [currency, setCurrency] = useState(cookies.get('currency') || defaultValue);
  const [isModalVisible, setModalVisible] = useState(false);
  const [auxiliaryCurrency, setAuxiliaryCurrency] = useState();

  useEffect(() => {
    if (!cookies.get('currency')) {
      cookies.set('currency', 'USD');
    }
  }, []);

  const changeCurrency = value => {
    cookies.set('currency', value);
    balance.fetchLeanBalance();
    setCurrency(cookies.get('currency'));
    forceUpdateHandler();
    window.location.reload();
  };

  const handleBulkOrderRefresher = () => {
    setBulkOrder([]);
    setBulkOrderFilename('');
    setBulkOrderTableView(false);
  };

  const changeCurrencyInModal = value => {
    setAuxiliaryCurrency(value);
    setModalVisible(true);
  };

  return (
    <>
      <Dropdown
        onChange={value =>
          singleOrder || bulkOrder.length ? changeCurrencyInModal(value) : changeCurrency(value)
        }
        defaultValue={currency}
        value={currency}
        height="32px"
        borderRadius="16px !important"
        css={css`
          & div.ant-select-selection-selected-value {
            overflow: visible;
          }
        `}
      >
        {options.map(option => (
          <Option value={option.value} key={option.value}>
            <IconPng
              type={option.icon}
              size={38}
              marginTop="-3px"
              marginLeft="-15px"
              marginRight="-4px"
            />
            <span
              css={css`
                font-size: ${fontSize.medium};
              `}
            >
              {option.text}
            </span>
          </Option>
        ))}
      </Dropdown>
      <Modal
        title="Region Change"
        visible={isModalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={() => {
          changeCurrency(auxiliaryCurrency);
          singleOrder ? removeSingleOrder() : handleBulkOrderRefresher();
          setModalVisible(false);
        }}
        okText="Change Region"
      >
        <p>You are about to change your region while placing an order.</p>
        <p>
          This action will undo any changes you&apos;ve made and will start a new order on the
          selected region.
        </p>
      </Modal>
    </>
  );
};

CurrencyDropdown.propTypes = {
  defaultValue: PropTypes.string,
  balance: PropTypes.object,
  forceUpdateHandler: PropTypes.func,
  singleOrder: PropTypes.object,
  removeSingleOrder: PropTypes.func,
  bulkOrder: PropTypes.object,
  setBulkOrder: PropTypes.func,
  setBulkOrderFilename: PropTypes.func,
  setBulkOrderTableView: PropTypes.func,
};

export default CurrencyDropdown;
