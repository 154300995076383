import { getParentOfType, types } from 'mobx-state-tree';
import Subscription from '../../../stores/SettingsStore/Subscription';

export const AddOnPlan = types
  .model('Plan', {
    id: types.string,
    typeId: types.maybe(types.string),
    name: types.maybe(types.string),
    description: types.maybe(types.string),
    cost: types.number,
  })
  .views(self => ({
    get isSelected() {
      return getParentOfType(self, Subscription).activeAddOnsMap[self.id] || false;
    },
  }));
