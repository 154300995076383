// const getURL = env => `${env}old-bifm.triplemars.com`;
const getURL = env => `api-${env}.triplemars.com`;

let BASE_URL = '';
let BASE_URL2 = '';
let PROTOCOL = 'https';
const env = process.env.REACT_APP_ENV;

switch (true) {
  case env === 'prod':
    BASE_URL = getURL('prod');
    BASE_URL2 = getURL('prod');
    break;

  case env === 'test':
    BASE_URL = getURL('dev');
    BASE_URL2 = getURL('dev');
    PROTOCOL = 'https';
    break;

  default:
    BASE_URL = getURL('dev');
    BASE_URL2 = getURL('dev');
    PROTOCOL = 'https';
}

const config = {
  API_URL: `${PROTOCOL}://${BASE_URL}/api`,
  // API_URL: `https://${BASE_URL2}/api`,
  API_URL2: `https://${BASE_URL2}/api`,
};

export default config;
