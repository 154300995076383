import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Dropdown, Layout, Menu, Popover, Spin } from 'antd';
import Cookies from 'universal-cookie';
import moment from 'moment';
import Toast from '../Toast';
import colorNames from '../../constants/subscription/color-names.json';
import statusMessages from '../../constants/subscription/status-names.json';
import iconNames from '../../constants/subscription/icon-names.json';
import {
  Logout,
  MenuTitleDescContainer,
  MenuTitleDescRow,
  Notifications,
  PlanUsagePopover,
  SettingsButtonsContainer,
  StyledMenuItem,
  StyledMenuTitle,
  StyledMenuTitleValue,
} from './styled';
import { ButtonWithIcon } from '../common/ButtonWithIcon';
import ENUMS from '../../constants/enums';
import { commaFormat } from '../../utils/numberCommaFormatter';
import {
  BalanceModal,
  BalanceStatusModal,
  LoadBalanceModalPayoneer,
  LoadBalanceModalPaypal,
} from '../BalanceModals';
import { colors } from '../../constants';
import IconSvg from '../common/IconSvg';
import CurrencyDropdown from '../CurrencyDropdown';
import SettingsModal from '../SettingsModal';
import currencies from '../../constants/currency.json';

const { Header: AntHeader } = Layout;
const cookies = new Cookies();

const BALANCE_MODALS = {
  BALANCE: 1,
  BALANCE_STATUS: 2,
  LOAD_BALANCE_PAYPAL: 3,
  LOAD_BALANCE_PAYONEER: 4,
};

const newColors = { ...colorNames, '1': 'greySecondary' };

@inject('toastsStore')
@inject('sessionStore')
@inject('ordersStore')
@inject('settingsStore')
@inject('dashboardStore')
@observer
class Header extends Component {
  constructor(props) {
    super(props);
    this.toggleUserSettings = this.toggleUserSettings.bind(this);
    this.toggleBalance = this.toggleBalance.bind(this);
    this.toggleFinanceSettings = this.toggleFinanceSettings.bind(this);
    this.openBalanceStatus = this.openBalanceStatus.bind(this);
    this.openLoadBalancePaypal = this.openLoadBalancePaypal.bind(this);
    this.openLoadBalancePayoneer = this.openLoadBalancePayoneer.bind(this);
    this.openBalance = this.openBalance.bind(this);
    this.handleLoadBalanceInput = this.handleLoadBalanceInput.bind(this);
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    this.setLoadSum = this.setLoadSum.bind(this);
    this.loadNow = this.loadNow.bind(this);
    this.hoverOn = this.hoverOn.bind(this);
    this.hoverOff = this.hoverOff.bind(this);
    this.state = {
      activeBalanceModal: BALANCE_MODALS.BALANCE,
      isBalanceOpen: false,
      loadBalanceSum: 0,
      loadBalanceCommission: 0,
      loadBalanceCommissionPayoneer: 0,
      hoveringUserProfile: false,
      planUsageVisible: null,
    };
    if (!cookies.get('currency')) {
      cookies.set('currency', 'USD');
    }
  }

  componentDidMount() {
    const {
      dashboardStore,
      settingsStore: { balance },
    } = this.props;
    dashboardStore.initStore();
    balance.fetchLeanBalance();
  }

  setLoadSum(howMuch) {
    this.setState({
      loadBalanceSum: howMuch,
      loadBalanceCommission: this.calculateCommission(howMuch),
      loadBalanceCommissionPayoneer: this.calculateCommissionPayoneer(howMuch),
    });
  }

  calculateCommission(value) {
    // if (value < 100) {
    //   return 0.04 * value;
    // }
    // if (value < 500) {
    //   return 0.03 * value;
    // }
    // return 0.025 * value;
    return 0.08 * value;
  }

  calculateCommissionPayoneer(value) {
    return 0.03 * value;
  }

  toggleBalance(activeBModal = BALANCE_MODALS.BALANCE) {
    this.setState({
      isBalanceOpen: !this.state.isBalanceOpen,
      activeBalanceModal: activeBModal,
    });
  }

  toggleUserSettings = () => {
    const {
      settingsStore: { toggleSettings },
    } = this.props;
    toggleSettings(ENUMS.SETTINGS_TABS.SUBSCRIPTION);
  };

  forceUpdateHandler = () => {
    const {
      settingsStore: { changeCurrency },
    } = this.props;
    changeCurrency(currencies[cookies.get('currency')].symbol);
    this.forceUpdate();
  };

  toggleFinanceSettings = () => {
    const {
      settingsStore: { toggleSettings },
    } = this.props;
    toggleSettings(ENUMS.SETTINGS_TABS.BALANCE);
  };

  openBalanceStatus() {
    this.setState({ isBalanceOpen: false }, () => {
      this.toggleBalance(BALANCE_MODALS.BALANCE_STATUS);
    });
  }

  openLoadBalancePaypal() {
    const {
      settingsStore: {
        balance: { fetchPaypalPaymentOptions },
      },
    } = this.props;
    fetchPaypalPaymentOptions().then(() =>
      this.setState({ isBalanceOpen: false }, () => {
        this.toggleBalance(BALANCE_MODALS.LOAD_BALANCE_PAYPAL);
      }),
    );
  }

  openLoadBalancePayoneer() {
    const {
      settingsStore: {
        balance: { fetchPayoneerPaymentOptions },
      },
    } = this.props;
    fetchPayoneerPaymentOptions().then(() =>
      this.setState({ isBalanceOpen: false }, () => {
        this.toggleBalance(BALANCE_MODALS.LOAD_BALANCE_PAYONEER);
      }),
    );
  }

  openBalance() {
    this.setState({ isBalanceOpen: false }, () => {
      this.toggleBalance(BALANCE_MODALS.BALANCE);
    });
  }

  handleLoadBalanceInput(e) {
    const givenValue = e.target ? e.target.value : 0;
    if (givenValue.indexOf('-') !== -1) {
      return;
    }
    const howMuch = parseFloat(givenValue) || 0;
    this.setState({
      loadBalanceSum: howMuch,
      loadBalanceCommission: this.calculateCommission(howMuch),
      loadBalanceCommissionPayoneer: this.calculateCommissionPayoneer(howMuch),
    });
  }

  loadNow = (nonce, pp_user) => {
    const {
      settingsStore: {
        balance: { depositCurrency, fetchLeanBalance },
      },
      toastsStore: { showToast },
    } = this.props;
    const { loadBalanceSum, loadBalanceCommission } = this.state;
    return depositCurrency(loadBalanceSum + loadBalanceCommission, 'USD', nonce, pp_user).then(
      resp => {
        if (resp) {
          showToast({
            type: ENUMS.TOAST_TYPES.INFO,
            message: 'balance.balanceUpdated',
          });
          fetchLeanBalance();
        } else {
          showToast({
            type: ENUMS.TOAST_TYPES.WARNING,
            message: 'balance.balanceError',
          });
        }
        return resp;
      },
    );
  };

  loadNowPayoneer = p_user => {
    const {
      settingsStore: {
        balance: { depositCurrencyPayoneer, fetchLeanBalance },
      },
      toastsStore: { showToast },
    } = this.props;
    const { loadBalanceSum, loadBalanceCommissionPayoneer } = this.state;
    return depositCurrencyPayoneer(
      loadBalanceSum + loadBalanceCommissionPayoneer,
      'USD',
      p_user,
    ).then(resp => {
      const {
        settingsStore: {
          balance: { payoneerSubmitResponse },
        },
      } = this.props;
      if (resp === true) {
        showToast({
          type: ENUMS.TOAST_TYPES.INFO,
          message: 'balance.balanceUpdated',
        });
        fetchLeanBalance();
      } else {
        showToast({
          type: ENUMS.TOAST_TYPES.WARNING,
          message:
            typeof payoneerSubmitResponse.message === 'string'
              ? payoneerSubmitResponse.message
              : 'balance.balanceError',
        });
      }
      return resp;
    });
  };

  hoverOn(e, target = '') {
    e.preventDefault();
    e.stopPropagation();
    switch (target) {
      case 'userProfile': {
        this.setState({
          hoveringUserProfile: true,
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  hoverOff(e, target) {
    e.preventDefault();
    e.stopPropagation();
    switch (target) {
      case 'userProfile': {
        this.setState({
          hoveringUserProfile: false,
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  keepPlanUsageClosed = () => {
    localStorage.setItem('planUsageClosed', 'true');
    this.handlePlanUsageVisibleChange(false);
  };

  handlePlanUsageVisibleChange = planUsageVisible => {
    this.setState({ planUsageVisible });
  };

  render() {
    const {
      settingsStore: {
        initialized,
        toggleSettings,
        isSettingsOpen,
        activeSettingsTab,
        balance: { leanBalance },
        userProfile: { currentPlanActive, currentPlan, overLimitInfo },
        currencyStore,
        browserHistory,
      },
      sessionStore: { logout, email },
      dashboardStore: { miniDashboard },
      ordersStore: {
        singleOrder,
        removeSingleOrder,
        bulkOrder,
        setBulkOrder,
        setBulkOrderFilename,
        setBulkOrderTableView,
      },
    } = this.props;

    const {
      isBalanceOpen,
      activeBalanceModal,
      loadBalanceSum,
      loadBalanceCommission,
      loadBalanceCommissionPayoneer,
      hoveringUserProfile,
      planUsageVisible,
    } = this.state;
    const notifications = [];
    let loadedModal;
    if (isBalanceOpen) {
      switch (activeBalanceModal) {
        case BALANCE_MODALS.BALANCE:
          loadedModal = (
            <BalanceModal
              visible={isBalanceOpen}
              closeModal={this.toggleBalance}
              openLoadBalancePaypal={this.openLoadBalancePaypal}
              openLoadBalancePayoneer={this.openLoadBalancePayoneer}
              openBalanceStatus={this.openBalanceStatus}
              openFinanceSettings={this.toggleFinanceSettings}
              loadSum={loadBalanceSum}
              commission={loadBalanceCommission}
              commissionPayoneer={loadBalanceCommissionPayoneer}
              handleInput={this.handleLoadBalanceInput}
              setLoadSum={this.setLoadSum}
              // isJA={isJA}
              isJA={false}
            />
          );
          break;
        case BALANCE_MODALS.BALANCE_STATUS:
          loadedModal = (
            <BalanceStatusModal
              visible={isBalanceOpen}
              closeModal={this.toggleBalance}
              openBalance={this.openBalance}
              openFinanceSettings={this.toggleFinanceSettings}
            />
          );
          break;
        case BALANCE_MODALS.LOAD_BALANCE_PAYPAL:
          loadedModal = (
            <LoadBalanceModalPaypal
              visible={isBalanceOpen}
              closeModal={this.toggleBalance}
              openBalanceStatus={this.openBalanceStatus}
              loadSum={loadBalanceSum}
              commission={loadBalanceCommission}
              openFinanceSettings={this.toggleFinanceSettings}
              loadNow={this.loadNow}
            />
          );
          break;
        case BALANCE_MODALS.LOAD_BALANCE_PAYONEER:
          loadedModal = (
            <LoadBalanceModalPayoneer
              visible={isBalanceOpen}
              closeModal={this.toggleBalance}
              openBalanceStatus={this.openBalanceStatus}
              loadSum={loadBalanceSum}
              commission={loadBalanceCommissionPayoneer}
              openFinanceSettings={this.toggleFinanceSettings}
              loadNow={this.loadNowPayoneer}
            />
          );
          break;
        default:
          loadedModal = null;
      }
    }

    let warningLevel = 0;
    if (!currentPlanActive) {
      warningLevel = 1;
    }
    if (overLimitInfo.isOverLimit) {
      warningLevel = 3;
    }

    return (
      <Fragment>
        <Toast />
        <AntHeader
          css={{
            padding: 0,
            background: '#fff',
            height: '68px',
            borderBottom: 'solid #e9eaef 1px',
          }}
        >
          <Menu
            mode="horizontal"
            css={{
              background: ' #ffffff',
              padding: 0,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'stretch',
              height: '100%',
              maxWidth: '1248px',
              margin: '0 auto',
              borderBottom: 'none',
            }}
          >
            <StyledMenuItem key="header-item-0" style={{ flexGrow: 0.1 }}>
              <CurrencyDropdown
                forceUpdateHandler={this.forceUpdateHandler}
                defaultValue={cookies.get('currency') || 'USD'}
                balance={this.props.settingsStore.balance}
                browserHistory={browserHistory}
                singleOrder={singleOrder}
                removeSingleOrder={removeSingleOrder}
                bulkOrder={bulkOrder}
                setBulkOrder={setBulkOrder}
                setBulkOrderFilename={setBulkOrderFilename}
                setBulkOrderTableView={setBulkOrderTableView}
              />
            </StyledMenuItem>
            <StyledMenuItem key="header-item-1" style={{ flexGrow: 0.86 }}>
              {currentPlan ? ( // current plan info loaded
                <Popover
                  content={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: '5px',
                      }}
                    >
                      <PlanUsagePopover
                        statusMessage={statusMessages[warningLevel]}
                        openSubscriptionSettings={() =>
                          toggleSettings(ENUMS.SETTINGS_TABS.SUBSCRIPTION)
                        }
                        closeAction={this.keepPlanUsageClosed}
                        showWarning={!!warningLevel}
                      />
                    </div>
                  }
                  defaultVisible={!!warningLevel}
                  visible={planUsageVisible === null ? !!warningLevel : planUsageVisible}
                  onVisibleChange={this.handlePlanUsageVisibleChange}
                  title=""
                  placement="bottom"
                  trigger="hover"
                >
                  <StyledMenuTitle
                    style={{
                      width: '16%',
                      cursor: 'pointer',
                      justifySelf: 'start',
                      marginRight: 'auto',
                    }}
                    onClick={() => toggleSettings(ENUMS.SETTINGS_TABS.SUBSCRIPTION)}
                    color={colors[newColors[warningLevel]]}
                    hoverColor={`${colors[newColors[warningLevel]]}BF`}
                  >
                    <StyledMenuTitleValue>
                      <IconSvg size={20} type={iconNames[warningLevel]} />
                    </StyledMenuTitleValue>
                    <MenuTitleDescContainer className="title-link">
                      <MenuTitleDescRow>
                        {moment().isAfter(currentPlan.plan_expiry)
                          ? 'No Active Plan'
                          : currentPlan.title}
                      </MenuTitleDescRow>
                    </MenuTitleDescContainer>
                  </StyledMenuTitle>
                </Popover>
              ) : (
                <StyledMenuTitle
                  style={{ width: '14%', cursor: 'pointer' }}
                  onClick={() => toggleSettings(ENUMS.SETTINGS_TABS.SUBSCRIPTION)}
                  color={colors[newColors[warningLevel]]}
                  hoverColor={`${colors[newColors[warningLevel]]}BF`}
                />
              )}
              <Popover
                content={<FormattedMessage id="header.allTime" />}
                title=""
                placement="bottom"
                trigger="hover"
              >
                <StyledMenuTitle style={{ width: '16%' }}>
                  <StyledMenuTitleValue>
                    {miniDashboard?.ORDERED ? commaFormat(miniDashboard?.ORDERED) : 0}
                  </StyledMenuTitleValue>
                  <MenuTitleDescContainer>
                    <MenuTitleDescRow>
                      <FormattedMessage id="header.orders" default="Orders" />
                    </MenuTitleDescRow>
                  </MenuTitleDescContainer>
                </StyledMenuTitle>
              </Popover>
              <Popover
                content={<FormattedMessage id="header.allTime" />}
                title=""
                placement="bottom"
                trigger="hover"
              >
                <StyledMenuTitle style={{ width: '16%' }}>
                  <StyledMenuTitleValue>
                    {miniDashboard?.FAILED ? commaFormat(miniDashboard?.FAILED) : 0}
                  </StyledMenuTitleValue>
                  <MenuTitleDescContainer>
                    <MenuTitleDescRow>
                      <FormattedMessage id="header.failedOrders" default="Failed Orders" />
                    </MenuTitleDescRow>
                  </MenuTitleDescContainer>
                </StyledMenuTitle>
              </Popover>
              <Popover
                content={<FormattedMessage id="header.currentBalance" />}
                title=""
                placement="bottom"
                trigger="hover"
              >
                <StyledMenuTitle
                  style={{
                    width: '20%',
                    cursor: 'pointer',
                    justifySelf: 'end',
                    marginLeft: 'auto',
                  }}
                  onClick={() => this.toggleBalance()}
                >
                  <StyledMenuTitleValue>
                    {currencyStore}
                    {leanBalance && commaFormat(leanBalance)}
                  </StyledMenuTitleValue>
                  <MenuTitleDescContainer className="title-link">
                    <MenuTitleDescRow>
                      <FormattedMessage id="header.balance" default="Balance" />
                    </MenuTitleDescRow>
                  </MenuTitleDescContainer>
                </StyledMenuTitle>
              </Popover>
            </StyledMenuItem>
            <StyledMenuItem>v2.1.2</StyledMenuItem>
            <StyledMenuItem
              key="header-item-2"
              className="bordered-right"
              style={{
                flexGrow: 0.14,
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              <Dropdown
                disabled
                key="header-item-notifications"
                trigger={['click']}
                overlay={
                  <Menu>
                    {notifications.map(notification => (
                      <Menu.Item key={`header-item-${notification.index}`}>
                        {notification.content}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <span>
                  <Notifications count={notifications.length} />
                </span>
              </Dropdown>
              <SettingsButtonsContainer>
                {!initialized ? (
                  <Spin style={{ margin: 'auto', height: '22.5px' }} />
                ) : (
                  <React.Fragment>
                    <ButtonWithIcon
                      disabled={!initialized}
                      onClick={() => toggleSettings()}
                      iconType="settings"
                      iconSize="20"
                    />
                    <Popover
                      key="header-item-settings"
                      content={
                        !email ? (
                          <FormattedMessage id="common.hi" defaultMessage="Hi!" />
                        ) : (
                          <FormattedMessage
                            id="common.hiUser"
                            values={{ email }}
                            defaultMessage="Hi, {email}"
                          />
                        )
                      }
                      title=""
                      placement="bottom"
                      visible={hoveringUserProfile && !isSettingsOpen}
                    >
                      <ButtonWithIcon
                        onMouseEnter={e => this.hoverOn(e, 'userProfile')}
                        onMouseLeave={e => this.hoverOff(e, 'userProfile')}
                        disabled={!initialized}
                        onClick={this.toggleUserSettings}
                        iconType="user"
                        iconSize="24"
                      />
                    </Popover>
                  </React.Fragment>
                )}
              </SettingsButtonsContainer>
              <Logout onClick={logout} />
              {isSettingsOpen && (
                <SettingsModal
                  visible={isSettingsOpen}
                  activeTab={activeSettingsTab}
                  closeModal={() => toggleSettings()}
                />
              )}
              {loadedModal}
            </StyledMenuItem>
          </Menu>
          {/*<NoSubscriptionBlocker*/}
          {/*  openSubscriptionSettings={() => toggleSettings(ENUMS.SETTINGS_TABS.SUBSCRIPTION)}*/}
          {/*/>*/}
        </AntHeader>
      </Fragment>
    );
  }
}

Header.propTypes = {
  toastsStore: PropTypes.object,
  sessionStore: PropTypes.object,
  settingsStore: PropTypes.object,
  ordersStore: PropTypes.object,
  dashboardStore: PropTypes.object,
};

export default Header;
