import { getParent, types } from 'mobx-state-tree';

const Firewall = types
  .model('Firewall', {
    id: types.number,
    ip_address: types.string,
    details: types.maybe(types.string),
    created_at: types.string,
    updated_at: types.string,
  })
  .views(self => ({
    get clientIP() {
      return getParent(self, 2).clientIP;
    },
    get isCurrentIP() {
      return self.clientIP === self.ip_address;
    },
    get formattedFirewall() {
      return `${self.ip_address}${self.detaild ? ` - ${self.detaild}` : ''}`;
    },
  }));

export default Firewall;
