import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { observer, inject } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { Layout, Menu, Icon } from 'antd';
import Logo from '../Logo';
import IconSvg from '../common/IconSvg';
import { buttonNormal, buttonHover } from '../../constants';

const { Sider: AntSider } = Layout;
const MAIN_PAGE_KEY = 'single-order';

const MenuItemContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const StyledMenuItem = styled(Menu.Item)`
  height: 75px !important;
  width: 100% !important;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-items: center;
  justify-self: center;
  align-content: center;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
`;

const StyledMenu = styled(Menu)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100vh - 68px);
  padding-top: 15px;
`;

@inject('routingStore')
@observer
class Sider extends Component {
  static propTypes = {
    routingStore: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      menuKey: 'single-order',
    };
  }

  componentDidMount = () => {
    const { routingStore } = this.props;
    const currentPath = routingStore.browserHistory.getCurrentLocation().pathname.split('/')[1];
    this.setState({ menuKey: currentPath || MAIN_PAGE_KEY });
  };

  updateRouteState = path => {
    const { routingStore } = this.props;
    routingStore.browserHistory.push(`/${path}`);
    this.setState({ menuKey: path });
  };

  render() {
    const { menuKey } = this.state;
    return (
      <AntSider
        css={{ backgroundColor: '#ffffff', overflow: 'hidden', position: 'fixed' }}
        width="118px"
      >
        <Logo css={{ cursor: 'pointer' }} onClick={() => this.updateRouteState('single-order')} />
        <StyledMenu mode="inline" openKeys={[menuKey]} selectedKeys={[menuKey]}>
          <StyledMenuItem key="single-order" onClick={() => this.updateRouteState('single-order')}>
            <MenuItemContainer>
              <IconSvg
                type="single-order"
                color={buttonNormal}
                hoverColor={buttonHover}
                size={20}
              />
              <FormattedMessage id="sider.singleOrder" />
            </MenuItemContainer>
          </StyledMenuItem>
          <StyledMenuItem key="bulk-order" onClick={() => this.updateRouteState('bulk-order')}>
            <MenuItemContainer>
              <IconSvg type="bulk-order" color={buttonNormal} hoverColor={buttonHover} size={20} />
              <FormattedMessage id="sider.bulkOrder" />
            </MenuItemContainer>
          </StyledMenuItem>
          <StyledMenuItem key="orders" onClick={() => this.updateRouteState('orders')}>
            <MenuItemContainer>
              <IconSvg type="orders" color={buttonNormal} hoverColor={buttonHover} size={20} />
              <FormattedMessage id="sider.myOrders" />
            </MenuItemContainer>
          </StyledMenuItem>
          <StyledMenuItem key="returns" onClick={() => this.updateRouteState('returns')}>
            <MenuItemContainer>
              <IconSvg type="returns" color={buttonNormal} hoverColor={buttonHover} size={20} />
              <FormattedMessage id="sider.myReturns" />
            </MenuItemContainer>
          </StyledMenuItem>
          <StyledMenuItem key="balance" onClick={() => this.updateRouteState('balance')}>
            <MenuItemContainer>
              <IconSvg type="balance" color={buttonNormal} hoverColor={buttonHover} size={20} />
              <FormattedMessage id="sider.myBalance" />
            </MenuItemContainer>
          </StyledMenuItem>
          <StyledMenuItem key="triple-cloud" onClick={() => this.updateRouteState('triple-cloud')}>
            <MenuItemContainer>
              <IconSvg
                type="cloud-server"
                color={buttonNormal}
                hoverColor={buttonHover}
                size={20}
              />
              <FormattedMessage id="sider.triplecloud" />
            </MenuItemContainer>
          </StyledMenuItem>
        </StyledMenu>
      </AntSider>
    );
  }
}
export default Sider;
