export const getAuthLocation = (withReturnUrl = true) => {
  // const retLoc = `${window.location.pathname}${window.location.hash}`;
  const authSuff = process.env.REACT_APP_ENV === 'prod' ? '' : `-dev`;
  const protocolSuffix = authSuff === 'prod' ? 's' : '';
  // const suff = process.env.REACT_APP_ENV === 'prod' ? '' : `qa-`;
  return withReturnUrl
    ? `https://auth${authSuff}.triplemars.com/?redirectUrl=http${protocolSuffix}://${
        process.env.REACT_APP_ENV === 'prod' ? '' : `qa-`
      }bifm.triplemars.com`
    : `https://auth${authSuff}.triplemars.com/`;
};
