import React, { useState } from 'react';
import { Router, Route, browserHistory, IndexRoute, Redirect } from 'react-router';
import { hot } from 'react-hot-loader';
import { IntlProvider } from 'react-intl';
import { inject } from 'mobx-react';
import Loadable from 'react-loadable';
import App from './components/App';
import { ClosePopup } from './pages/ClosePopup';
import Page404 from './pages/404';

const Loading = () => <div>Loading...</div>;

const AsyncSingleOrder = Loadable({
  loader: () => import('./pages/SingleOrder'),
  loading: Loading,
  delay: 300,
});

const AsyncBulkOrder = Loadable({
  loader: () => import('./pages/BulkOrder'),
  loading: Loading,
  delay: 300,
});

const AsyncMyOrders = Loadable({
  loader: () => import('./pages/MyOrders'),
  loading: Loading,
  delay: 300,
});

const AsyncMyReturns = Loadable({
  loader: () => import('./pages/MyReturns'),
  loading: Loading,
  delay: 300,
});

const AsyncOrder = Loadable({
  loader: () => import('./pages/Order'),
  loading: Loading,
  delay: 300,
});

const AsyncBalance = Loadable({
  loader: () => import('./components/SettingTabs/Balance'),
  loading: Loading,
  delay: 300,
});

const AsyncTripleCloud = Loadable({
  loader: () => import('./pages/TripleCloud'),
  loading: Loading,
  delay: 300,
});

const Routes = ({ localeStore }) => {
  return (
    <IntlProvider
      key={localeStore.language}
      locale={localeStore.language}
      messages={localeStore.messages}
      textComponent={React.Fragment}
    >
      <Router history={browserHistory}>
        <Redirect from="/" to="single-order" />
        <Route path="/" component={App}>
          <Route path="single-order" component={AsyncSingleOrder} />
          <Route path="bulk-order" component={AsyncBulkOrder} />
          <Route path="orders" component={AsyncMyOrders} />
          <Route path="returns" component={AsyncMyReturns} />
          <Route path="balance" component={AsyncBalance} />
          <Route path="order/:code" component={AsyncOrder} />
          <Route path="close" component={ClosePopup} />
          <Route path="triple-cloud" component={AsyncTripleCloud} />
          <Route path="*" exact component={Page404} />
        </Route>
      </Router>
    </IntlProvider>
  );
};

export default hot(module)(inject('localeStore')(Routes));
