import { types, flow, getParent, resolveIdentifier } from 'mobx-state-tree';
import qs from 'qs';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { OrderTableEntry, OrdersFilters, SingleOrder, BulkOrderEntry } from '../../models/Orders';
import ENUMS from '../../constants/enums';
import capitalizeFirst from '../../utils/capitalizeFirst';
import ReturnsFilters from '../../models/Orders/ReturnsFilters';

const cookies = new Cookies();

const conditions = {
  New: 'NEW',
  UsedLikeNew: 'USED_LIKE_NEW',
  UsedGood: 'USED_GOOD',
  UsedVeryGood: 'USED_VERY_GOOD',
  UsedAcceptable: 'USED_ACCEPTABLE',
};

const OrdersStore = types
  .model('OrdersStore', {
    orders: types.optional(types.array(OrderTableEntry), []),
    ordersFilters: types.optional(OrdersFilters, {}),
    page: 1,
    length: 50,
    totalResults: 0,
    allPagesSelected: false,

    returns: types.optional(types.array(OrderTableEntry), []),
    returnsFilters: types.optional(ReturnsFilters, {}),
    retPage: 1,
    retLength: 50,
    retTotalResults: 0,
    retAllPagesSelected: false,

    singleOrder: types.optional(types.maybeNull(SingleOrder), null),
    bulkOrder: types.optional(types.array(BulkOrderEntry), []),
    productDetails: types.maybe(OrderTableEntry),
    bulkOrderFilename: '',
    bulkOrderTableView: false,
  })
  .views(self => ({
    get axios() {
      return getParent(self).axios;
    },
    get toastsStore() {
      return getParent(self).toastsStore;
    },
    get start() {
      return (self.page - 1) * self.length;
    },
    get currentRegion() {
      const currency = cookies.get('currency');
      const region = currency === 'USD' ? 'US' : 'UK';
      return region;
    },
    get stringFilters() {
      const region = self.currentRegion;
      return qs.stringify(
        {
          from: moment(`${self.ordersFilters.from} 00:00 +0000`, 'YYYY-MM-DD HH:mm Z').format('X'),
          to: moment(`${self.ordersFilters.to} 23:59 +0000`, 'YYYY-MM-DD HH:mm Z').format('X'),
          status: self.ordersFilters.status,
          start: self.start,
          length: self.length,
          name: self.ordersFilters.name,
          draw: self.ordersFilters.draw,
          region,
        },
        { arrayFormat: 'repeat', encode: false },
      );
    },
    get returnsStringFilters() {
      const region = self.currentRegion;
      return qs.stringify(
        {
          from: moment(`${self.returnsFilters.from} 00:00 +0000`, 'YYYY-MM-DD HH:mm Z').format('X'),
          to: moment(`${self.returnsFilters.to} 23:59 +0000`, 'YYYY-MM-DD HH:mm Z').format('X'),
          status: self.returnsFilters.status,
          start: self.retStart,
          length: self.retLength,
          name: self.returnsFilters.name,
          draw: self.returnsFilters.draw,
          region,
        },
        { arrayFormat: 'repeat', encode: false },
      );
    },
    get selectedItems() {
      return self.orders.filter(item => item.selected);
    },
    get selectedReturnItems() {
      return self.returns.filter(item => item.selected);
    },
    get selectedItemsCount() {
      return self.selectedItems.length;
    },
    get selectedReturnItemsCount() {
      return self.selectedReturnItems.length;
    },
    get currentPageSelected() {
      return self.orders.reduce((acc, item) => {
        return item.selected && acc;
      }, true);
    },
    get currentReturnPageSelected() {
      return self.returns.reduce((acc, item) => {
        return item.selected && acc;
      }, true);
    },
    get selectedBulkOrder() {
      return self.bulkOrder.filter(({ selected }) => selected);
    },
    get selectedBulkOrderCount() {
      return self.selectedBulkOrder.length;
    },
    get allBulkOrderSelected() {
      return self.bulkOrder.reduce((acc, item) => {
        return item.selected && acc;
      }, true);
    },
  }))
  .actions(self => ({
    itemReference(identifier) {
      if (typeof identifier !== 'number' && typeof identifier !== 'string') {
        return {};
      }
      if (identifier === self?.productDetails?.id) {
        self.productDetails = undefined;
      }
      return (
        resolveIdentifier(OrderTableEntry, self.orders, identifier) ??
        resolveIdentifier(OrderTableEntry, self.returns, identifier)
      );
    },
    bulkItemReference(identifier) {
      if (typeof identifier !== 'number' && typeof identifier !== 'string') {
        return {};
      }
      return self.bulkOrder[identifier];
    },
    setSelectedItem(val, identifier) {
      self.itemReference(identifier).setSelected(val);
    },
    setBulkOrderFilename(val) {
      self.bulkOrderFilename = val;
    },
    setBulkOrderTableView(val) {
      self.bulkOrderTableView = val;
    },
    setLength(val) {
      self.length = val;
    },
    setReturnsLength(val) {
      self.retLength = val;
    },
    selectAll(val) {
      self.orders.forEach(item => {
        item.setSelected(val);
      });
    },
    selectAllReturns(val) {
      self.returns.forEach(item => {
        item.setSelected(val);
      });
    },
    setAllBulkPagesSelected(val) {
      self.bulkOrder.forEach(item => {
        item.setSelected(val);
      });
    },
    setAllPagesSelected(val) {
      self.allPagesSelected = !!val;
    },
    setAllReturnsPagesSelected(val) {
      self.retAllPagesSelected = !!val;
    },
    setPage(nextPage) {
      self.page = nextPage;
    },
    setReturnsPage(nextPage) {
      self.retPage = nextPage;
    },
    fetchNextPage: flow(function* fetchNextPage(nextPage) {
      self.setPage(nextPage);
      yield self.getOrders();
    }),
    fetchNextReturnsPage: flow(function* fetchNextPage(nextPage) {
      self.setReturnsPage(nextPage);
      yield self.getReturns();
    }),
    getOrders: flow(function* getOrders() {
      try {
        self.productDetails = undefined;
        const { data } = yield self.axios.fetch(`bifm-service/orders?${self.stringFilters}`);
        self.totalResults = data.recordsFiltered;
        self.orders = data.data;
        self.ordersFilters.addDraw();
      } catch (err) {
        console.error(err);
      }
    }),
    getReturns: flow(function* getOrders() {
      try {
        self.productDetails = undefined;
        const { data, recordsFiltered } = yield self.axios.fetch(
          `bifm-service/orders?${self.returnsStringFilters}`,
        );
        self.retTotalResults = recordsFiltered;
        self.returns = data.data;
        self.returnsFilters.addDraw();
      } catch (err) {
        console.error(err);
      }
    }),
    removeSingleOrder() {
      self.singleOrder = null;
    },
    setBulkOrder(arr) {
      self.bulkOrder = arr.map(el => ({
        ...el,
        condition: conditions[el.condition],
        phone: el.phone ? `${el.phone}` : '',
        address_line_2: el.address_line_2 || '',
        gift: false,
        non_prime: false,
        zip: el.zip ? el.zip.toString() : '',
        force_purchase: false,
      }));
    },
    getDetails: flow(function* getDetails(market, id) {
      self.removeSingleOrder();
      try {
        const { data } = yield self.axios.fetch(`bifm-service/products/${market}/${id}`);
        if (data.status === 'ok') {
          self.singleOrder = data.data;
          return { ok: true };
        }
        return { ok: false };
      } catch (err) {
        self.removeSingleOrder();
        console.error(err);
        return { ok: false, message: err.message };
      }
    }),
    submitOrder: flow(function* submitOrder(params) {
      let response = false;
      try {
        const { data } = yield self.axios.post(`bifm-service/orders`, params);
        if (data.status === 'ok') {
          self.toastsStore.showToast({
            type: ENUMS.TOAST_TYPES.INFO,
            message: capitalizeFirst(data.data),
          });
          response = true;
        } else {
          self.toastsStore.showToast({
            type: ENUMS.TOAST_TYPES.WARNING,
            message: capitalizeFirst(data.message),
          });
        }
      } catch (err) {
        console.error(err);
        self.toastsStore.showToast({
          type: ENUMS.TOAST_TYPES.WARNING,
          message: err.message
            ? capitalizeFirst(err.message)
            : 'There was an error submitting your order',
        });
      }
      return response;
    }),
    submitBulkOrder: flow(function* submitBulkOrder() {
      const region = self.currentRegion;
      try {
        const { data } = yield self.axios.post(`bifm-service/orders/bulk`, {
          orders: self.selectedBulkOrder.map(({ selected, ...el }) => el),
          region,
        });
        self.toastsStore.showToast({
          type: ENUMS.TOAST_TYPES.INFO,
          message: data.message ? capitalizeFirst(data.message) : 'Order successfully submitted',
        });
        return true;
      } catch (err) {
        console.error(err);
        self.toastsStore.showToast({
          type: ENUMS.TOAST_TYPES.WARNING,
          message: err.message
            ? capitalizeFirst(err.message)
            : 'There was an error submitting your order',
        });
        return false;
      }
    }),
    getProductDetails: flow(function* getProductDetails(orderId) {
      try {
        const { data } = yield self.axios.fetch(`bifm-service/orders/${orderId}`);
        self.productDetails = data.data;
      } catch (err) {
        console.error(err);
      }
    }),
  }));

export default OrdersStore;
