import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { Select } from 'antd';
import { observer } from 'mobx-react';
import { colors, fontSize } from '../../../constants';

const Dropdown = styled(Select)`
  margin-right: ${({ marginRight }) => marginRight || ''};
  margin-bottom: ${({ marginBottom }) => marginBottom || ''};

  & .ant-selection,
  .ant-select-selection {
    height: ${({ height }) => height || ''};
    line-height: ${({ height }) => height || null};
    border-radius: ${({ borderRadius, theme }) =>
      borderRadius || `${theme.borderRadius.normal} !important`};
    border-color: ${({ theme, borderColor }) => borderColor || theme.colors.greyMid};
    ${({ width }) => width && `width: ${width};`};
  }

  & .ant-selection,
  .ant-select-selection:hover {
    border-color: ${({ theme, borderColorHover }) => borderColorHover || theme.colors.blueMed};
    border-right-width: 1px !important;
  }

  & .ant-select-selection-selected-value {
    line-height: ${({ height }) => height || ''};
    font-size: ${({ fontSize: fs }) => fs || fontSize.small};
    text-align: left;
    color: ${({ theme }) => theme.colors.greySecondary};
  }

  & .ant-select-selection__rendered {
    margin: ${({ arrow }) => (arrow ? 0 : null)};
    line-height: ${({ height }) => height || null};
  }

  & .ant-select-arrow {
    right: ${({ arrow }) => (arrow ? '4px' : '7px')};

    & .ant-select-arrow-icon {
      margin: 0;
      min-width: 12px;
    }
  }

  .ant-select-selection--multiple > ul > li,
  .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    height: ${({ height }) => (height ? `${parseInt(height, 10) - 9}px` : null)};
    line-height: ${({ height }) => (height ? `${parseInt(height, 10) - 9}px` : null)};
  }

  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto !important')};
`;

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
};

Dropdown.defaultProps = {
  dropdownClassName: css`

  overflow-x: auto;
  border: 1px solid ${colors.greyMid};
  font-size: ${fontSize.small};
  color: ${colors.greySecondary};

    > div:first-of-type {
        border-radius: 10px;
      }
    }

    .ant-select-dropdown-menu-item {
      background: transparent;
      border-radius: 0;
    }

    .ant-select-dropdown-menu-item:hover {
      background: ${colors.whiteDarkBackground};
    }
  `,
};

Dropdown.Option = Select;

export default observer(Dropdown);
