import { when } from 'mobx';
import axios from 'axios';
import { types, getParent, flow, isAlive } from 'mobx-state-tree';
import { getPayoneerUrl, getEnvUrl } from '../../../utils/getEnvUrl';

const paypalAccount = types.optional(types.string, '');
const payoneerAccount = types.optional(types.string, '');

const ConnectionManagement = types
  .model('ConnectionManagement', {
    paypalBillingToken: types.optional(types.string, ''),
    paypalAccounts: types.optional(types.array(paypalAccount), []),
    payoneerAccounts: types.optional(types.array(payoneerAccount), []),
  })
  .views(self => ({
    get axios() {
      return getParent(self).axios;
    },
    get showToast() {
      return getParent(self, 2).toastsStore.showToast;
    },
    get enums() {
      return getParent(self, 2).enumsStore.getEnumsList;
    },
    get billableAccounts() {
      return self.paypalAccounts; // .filter(({ isBillable }) => isBillable);
    },
    get billablePayoneerAccounts() {
      return self.payoneerAccounts; // .filter(({ isBillable }) => isBillable);
    },
    get subscription() {
      return getParent(self).subscription;
    },
  }))
  .actions(self => ({
    fetchPaypalAccounts: flow(function* fetchPaypalAccounts() {
      try {
        const url = '/bifm-service/payments/paypal/paymentOptions';
        const { data } = yield self.axios.fetch(url);
        if (isAlive(self)) {
          self.paypalAccounts = data.data;
        }
      } catch (err) {
        console.log(err);
      }
    }),
    fetchPayoneerAccounts: flow(function* fetchPayoneerAccounts() {
      try {
        const url = '/bifm-service/payments/payoneer/paymentOptions';
        const { data } = yield self.axios.fetch(url);
        if (isAlive(self)) {
          self.payoneerAccounts = data.data;
        }
      } catch (err) {
        console.log(err);
      }
    }),
    connectAndPay: flow(function* connectAndPay(
      amount,
      currency,
      subscription_plan_id,
      nonce,
      pp_user,
    ) {
      try {
        let params = {
          amount,
          currency,
          subscription_plan_id,
        };
        if (nonce) {
          params = {
            ...params,
            nonce,
          };
          yield self.axios.post('/bifm-service/payments/paypal/checkout', params);
          self.showToast({
            type: self.enums.TOAST_TYPES.INFO,
            message: 'Your subscription was successfully updated.',
          });
          yield self.fetchPaypalAccounts();
        } else {
          params = {
            ...params,
            useToken: true,
            pp_user,
          };
          getParent(self).subscription.setLoading(true);
          yield self.axios.post('/bifm-service/payments/paypal/checkout', params);
          getParent(self).subscription.setLoading(false);
          self.showToast({
            type: self.enums.TOAST_TYPES.INFO,
            message: 'Your subscription was successfully updated.',
          });
        }
        yield getParent(self).userProfile.fetchCurrentUser();
      } catch (err) {
        console.error(err);
        self.showToast({
          type: self.enums.TOAST_TYPES.WARNING,
          message: 'There was an error updating the subscription',
        });
      } finally {
        yield getParent(self).subscription.setLoading(false);
      }
      console.log('ok');
    }),
    connectPayoneerAccount: flow(function* connectPayoneerAccount(plan) {
      try {
        self.payoneerSubmitResponse = {};
        const authWindow = window.open(getPayoneerUrl(), 'subscription', 'height=750, width=750');
        window.addEventListener(
          'message',
          function handleWindowMessage(e) {
            if (!e.data.type || e.data.type !== 'OAuthAccessCode') {
              return;
            }
            window.removeEventListener('message', handleWindowMessage, false);
            if (e.data.client === '/close') {
              self.setPayoneerSubmitResponse(e.data, plan);
              authWindow.close();
            } else {
              self.showToast({
                type: self.enums.TOAST_TYPES.WARNING,
                message: 'An error has occurred while adding your account. Please try again.',
              });
              authWindow.close();
            }
          },
          false,
        );
        yield when(() => Object.keys(self.payoneerSubmitResponse).length !== 0);
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    }),
    setPayoneerSubmitResponse: flow(function* setPayoneerSubmitResponse(value, plan) {
      try {
        const { data } = yield self.axios.post('/bifm-service/payments/payoneer/auth', {
          authCode: value.payload,
          redirectUrl:
            process.env.REACT_APP_ENV === 'prod'
              ? `https://bifm.triplemars.com/close`
              : `https://qa-bifm.triplemars.com/close`,
        });
        if (data.status === 'ok') {
          self.makePayoneerPayment(plan);
        }
      } catch (err) {
        console.error(err);
        self.showToast({
          type: self.enums.TOAST_TYPES.WARNING,
          message: 'An error has occurred while adding your Payoneer account. Please try again.',
        });
      } finally {
        // yield self.fetchPayoneerAccounts();
      }
    }),
    makePayoneerPayment: flow(function* makePayoneerPayment(plan, alreadyConnectedAcc = false) {
      try {
        yield self.fetchPayoneerAccounts();
        const pp_user = alreadyConnectedAcc ? plan.userAccount : self.payoneerAccounts[0];
        const { data } = yield self.axios.post('/bifm-service/payments/payoneer/checkout', {
          subscription_plan_id: plan.id,
          currency: plan.currency,
          pp_user,
        });
        if (data.status === 'ok') {
          self.showToast({
            type: self.enums.TOAST_TYPES.INFO,
            message: 'You have successfully connected your Payoneer account..',
          });
        } else {
          const errorMsg = data.message.split('failed with message: ');
          self.showToast({
            type: self.enums.TOAST_TYPES.WARNING,
            message: errorMsg[1],
          });
        }
      } catch (err) {
        console.error(err);
        self.showToast({
          type: self.enums.TOAST_TYPES.WARNING,
          message: 'An error has occurred while adding your Payoneer account. Please try again.',
        });
      } finally {
        // yield self.fetchPayoneerAccounts();
      }
    }),
    fetchBillingToken: flow(function* fetchBillingToken() {
      try {
        const { data } = yield self.axios.fetch(
          '/bifm-service/payments/paypal/sessionToken',
          {},
          {},
        );
        self.paypalBillingToken = data.data.token;
      } catch (err) {
        console.error(err);
      }
    }),
    initConnections: flow(function* initConnections() {
      yield Promise.allSettled([
        self.fetchBillingToken(),
        self.fetchPaypalAccounts(),
        self.fetchPayoneerAccounts(),
      ]);
    }),
  }));

export default ConnectionManagement;
