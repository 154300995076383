import { types } from 'mobx-state-tree';

const CurrencyReload = types
  .model('CurrencyReload', {
    amount: types.maybeNull(types.union(types.number, types.string)),
    is_active: types.optional(types.boolean, false),
    limit: types.maybeNull(types.union(types.number, types.string)),
  })
  .actions(self => ({
    setActive(val) {
      self.is_active = val.target ? val.target.value : val;
    },
    setAmount(val) {
      self.amount = val.target ? val.target.value : val || null;
    },
    setLimit(val) {
      self.limit = val.target ? val.target.value : val || null;
    },
  }));

export default CurrencyReload;
