import { types } from 'mobx-state-tree';

export const SubscriptionData = types.model('SubscriptionData', {
  subscriptionPlanId: types.maybe(types.string),
  addOns: types.optional(types.array(types.string), []), // typeId
  accountAutopurchaseRunsCount: types.maybe(types.number),
  accountDiscoveredItemsCount: types.maybe(types.number),
  accountListedItemsCount: types.maybe(types.number),
  accountId: types.maybe(types.string),
  dateStarted: types.maybe(types.string),
  expirationDate: types.maybe(types.string),
  id: types.maybe(types.string),
  subscriptionPlanName: types.maybe(types.string),
  upcomingPlanId: types.maybe(types.string),
});
