import { types } from 'mobx-state-tree';

const Transaction = types
  .model('Transaction', {
    amount: types.number,
    app: types.string,
    created: types.string,
    currency: types.string,
    user_email: types.string,
    user_id: types.string,
    external_id: types.maybeNull(types.string),
    extra: types.optional(types.frozen(), {}),
    id: types.string,
    payment_provider: types.maybeNull(types.string),
    type: types.string,
    status: types.maybeNull(types.string),
    fee: types.number,
    balance_after: types.maybeNull(types.number),
  })
  .views(self => ({
    get generalType() {
      return self.type.split('_')[0];
    },
    get signedAmount() {
      switch (self.generalType) {
        case 'DEPOSIT':
        case 'REFUND': {
          return self.amount;
        }
        case 'WITHDRAW':
        default: {
          return -(self.amount + self.fee);
        }
      }
    },
    get debit() {
      return self.signedAmount < 0 ? Math.round(-self.signedAmount * 100) / 100 : '';
    },
    get credit() {
      return self.signedAmount > 0 ? Math.round(self.signedAmount * 100) / 100 : '';
    },
  }));

export default Transaction;
