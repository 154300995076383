import { types, flow, getParent } from 'mobx-state-tree';

const BulkOrderEntry = types
  .model('BulkOrderEntry', {
    selected: true,
    address_line_1: types.maybeNull(types.string),
    address_line_2: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    condition: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    external_id: types.maybeNull(types.string),
    max_price: types.maybe(types.number),
    name: types.maybeNull(types.string),
    notes: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    quantity: types.maybe(types.number),
    source: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    zip: types.maybeNull(types.string),
    gift: false,
    non_prime: false,
    force_purchase: false,
  })
  .views(self => ({
    get shippingInfo() {
      let info = '';
      if (self.address_line_1) {
        info += `${self.address_line_1},`;
      }
      if (self.address_line_2) {
        info += `${self.address_line_2},`;
      }
      if (self.state) {
        info += `${self.state},`;
      }
      if (self.city) {
        info += `${self.city},`;
      }
      if (self.country) {
        info += `${self.country}, `;
      }
      if (self.zip) {
        info += `${self.zip}`;
      }
      return info;
    },
  }))
  .actions(self => ({
    setSelected(val) {
      self.selected = val;
    },
  }));

export default BulkOrderEntry;
