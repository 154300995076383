import styled from '@emotion/styled';
import React from 'react';
import { css } from '@emotion/core';
import { Modal } from 'antd';
import { buttonNormal, colors, fontSize } from '../../constants';
import IconSvg from './IconSvg';
import { Input } from './Filters';

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

export const BoldCardTitle = styled.div`
  color: ${buttonNormal} !important;
  font-family: Heebo-Medium;
  font-size: ${fontSize.large};
  margin-bottom: ${({ marginBottom }) => marginBottom || '10px'};
`;

export const GeneralCardDiv = ({ children, title, extraCSS = '' }) => {
  return (
    <div
      css={css`
        ${extraCSS}
      `}
    >
      <div>{title}</div>
      <div
        className="tm-general-card-body"
        css={css`
          box-shadow: 0px 1px 7px 0 rgba(0, 105, 250, 0.07);
          background: ${colors.whiteBackground};
        `}
      >
        {children}
      </div>
    </div>
  );
};

// for carousels
export const Arrow = styled(IconSvg)`
  &:before {
    content: none !important;
  }
  transform: ${props => (props.flip ? 'rotate(180deg)' : '')};
`;

export const VideoModal = styled(Modal)`
  position: static;
  div.ant-modal-content {
    position: static;
    > button.ant-modal-close {
      top: 3em;
      right: 3em;
      > span.ant-modal-close-x {
        font-size: 3em;
        color: rgba(255, 255, 255, 0.45);
      }
      > span.ant-modal-close-x:hover {
        color: rgba(255, 255, 255, 0.9);
      }
    }
    > div.ant-modal-body {
      padding: 0;
      > * {
        transform: translateX(-50%);
      }
    }
  }
`;

export const SearchInput = styled(Input)`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.colors.greyDark};
  line-height: ${({ theme }) => theme.lineHeight.huge};
  ${({ showIcon }) =>
    showIcon === undefined || showIcon === true
      ? `
    background: url('../../../assets/icons/search.svg') no-repeat 10px;
    background-size: 16px 16px;
    padding-left: 30px;
  `
      : ''}
`;
