import { types, flow, getParent } from 'mobx-state-tree';
import Cookies from 'universal-cookie';
import { getAuthLocation } from '../../utils/getAuthLocation';

const COOKIE_NAME = 'tm-access-token';

const SessionStore = types
  .model('SessionStore', {
    token: types.optional(types.string, process.env.REACT_AUTH_COOKIE || ''),
    endpointsAvailability: types.optional(types.map(types.boolean), {}),
    email: '',
  })
  .volatile(() => ({
    urlRoot: '/auth',
    cookies: new Cookies(),
  }))
  .views(self => ({
    get isLogged() {
      return self.token !== '' && self.token.length > 5;
    },

    get axios() {
      return getParent(self).axios;
    },
    get axios2() {
      return getParent(self).axios2;
    },
    get settingsStore() {
      return getParent(self).settingsStore;
    },
    get isAccountUp() {
      return self.endpointsAvailability.get('account');
    },
  }))
  .actions(self => {
    const afterCreate = function() {
      if (process.env.REACT_APP_AUTH_COOKIE && !self.cookies.get(COOKIE_NAME)) {
        document.cookie = `${COOKIE_NAME}=${process.env.REACT_APP_AUTH_COOKIE}`;
      }
    };
    const isUp = endpoint => {
      const availability = self.endpointsAvailability.get(endpoint);
      return !(availability === false);
    };
    const connectToUserFromToken = flow(function* connectToUserFromToken() {
      self.loadAuthCookie();
      if (!self.token) {
        window.location = getAuthLocation();
        return null;
      }
      // create initial transports with no user data, just api urls
      getParent(self).initAxios();
      try {
        const { data } = yield self.axios.fetch('bifm-service/isAuth', {}, {});
        self.email = data?.data?.email ?? '';
        return data;
      } catch (err) {
        if (err.response.status !== 503) {
          window.location = getAuthLocation();
        }
        return null;
      }
    });

    const handleUnauthorized = flow(function* handleUnauthorized() {
      if (!self.token) {
        window.location = getAuthLocation();
        return null;
      }
      try {
        yield self.axios.fetch('bifm-service/isAuth', {}, {});
        return null;
      } catch (err) {
        self.logout();
        return null;
      }
    });

    const handleNetworkError = url => {
      const endpoint = url.split('/')[4];
      const value = self.endpointsAvailability.get(endpoint);
      if (typeof value === 'undefined' || value === true) {
        self.endpointsAvailability.set(endpoint, false);
      }
    };

    const handleNetworkNotDown = url => {
      const endpoint = url.split('/')[4];
      const value = self.endpointsAvailability.get(endpoint);
      if (typeof value === 'undefined' || value === false) {
        self.endpointsAvailability.set(endpoint, true);
      }
    };
    /**
     * Do logout, clear token from store and cookie
     */
    const logout = () => {
      self.token = '';
      document.cookie = `${COOKIE_NAME}=;expires=Thu, 01 Jan 1970 00:00:01 GMT ;domain=.triplemars.com;path=/`;
      localStorage.removeItem('loginCounted');
      window.location = '/';
    };

    /**
     * Load authorization token from cookie
     */
    const loadAuthCookie = () => {
      self.token = self.cookies.get(COOKIE_NAME);
    };

    const initSession = flow(function* initSession() {
      try {
        yield self.connectToUserFromToken().then(() => {
          getParent(self).initAxios();
          self?.settingsStore?.initStore();
        });
        return true;
      } catch (error) {
        console.error(error);
        return null;
      }
    });

    return {
      loadAuthCookie,
      logout,
      initSession,
      handleUnauthorized,
      handleNetworkError,
      handleNetworkNotDown,
      isUp,
      connectToUserFromToken,
      afterCreate,
    };
  });

export default SessionStore;
