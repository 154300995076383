import { types, flow, getParent } from 'mobx-state-tree';

const SingleOrder = types
  .model('SingleOrder', {
    availability: types.optional(types.string, ''),
    category: types.optional(types.string, ''),
    created: types.optional(types.string, ''),
    currency: types.optional(types.string, ''),
    description: types.optional(types.string, ''),
    external_id: types.optional(types.string, ''),
    image_url: types.optional(types.string, ''),
    list_price: types.maybe(types.number),
    price: types.maybe(types.number),
    shipping_fee: types.maybe(types.number),
    source: types.optional(types.string, ''),
    title: types.optional(types.string, ''),
    updated: types.optional(types.string, ''),
    variation_prices: types.optional(types.frozen(), {}),
    variations: types.optional(types.frozen(), {}),
    _id: types.optional(types.string, ''),
  })
  .views(self => ({
    get variationsArray() {
      return Object.entries(self.variations).map(([variationId, variationObj]) => ({
        id: variationId,
        name: variationObj.name,
        options: Object.entries(variationObj.options).map(([optionId, value]) => ({
          optionId,
          value,
        })),
      }));
    },
    get emptyVariationsRequestObject() {
      return Object.fromEntries(self.variationsArray.map(({ id }) => [id, '']));
    },
  }));

export default SingleOrder;
