/** the values are usually intl keys */

const ENUMS_TRANSFORM = {
  MARKETS: {
    1: 'amazon',
    2: 'ebay',
    3: 'walmart',
    4: 'aliExpress',
  },
  MARKETSold: {
    AZ: 'amazon',
    EBAY: 'ebay',
    WM: 'walmart',
    paypal: 'paypal',
    4: 'aliExpress',
  },
  STRATEGY: {
    0: 'none',
    1: 'fixedProfit',
    2: 'competition',
    3: 'penetration',
  },
  COMMON: {
    1: 'id',
    2: 'url',
  },
  CONDITION: {
    0: 'none',
    1: 'new',
    2: 'newOther',
    3: 'used',
    4: 'manufacturerRefurbished',
    5: 'sellerRefurbished',
  },
  CURRENCY: {
    0: 'none',
    1: 'dollar',
    2: 'euro',
  },
  VALUE_TYPE: {
    1: 'percent',
    2: 'currencyUnits',
  },
  TYPE_OF_DISCOVERY_PROFIT_TYPE: {
    1: 'Percent',
    2: 'CurrencyUnits',
  },
  PROFIT_TYPES: {
    1: '%',
    2: '$',
  },
  SHIPPING_METHODS: {
    1: 'economy',
    2: 'standard',
    3: 'express',
    4: 'oneDay',
    5: 'international',
    6: 'domestic',
  },
  CODES: {
    1: 'upc',
    2: 'asin',
    3: 'url',
    4: 'marketId',
  },
  DISCOVERY_METHODS: {
    1: 'url',
    2: 'ids',
    3: 'csv',
  },
  DESTINATION_MARKETPLACE: {
    0: 'none',
    4: 'ebayUs',
    6: 'ebayUk',
  },
  DISCOVERY_SORT_BY: {
    1: 'id',
    2: 'title',
    3: 'sourcePrice',
    4: 'profit',
    5: 'profitCurrencyUnits',
    6: 'profitPercent',
    7: 'destinationPrice',
    8: 'triplemarsRank',
  },
  DISCOVERY_STATUS: {
    0: 'none',
    1: 'inProgress',
    2: 'completed',
    3: 'failed',
    4: 'listed',
    5: 'canceled',
    6: 'partiallyCompleted',
    7: 'timedOut',
  },
  SOURCE_MARKETPLACE: {
    0: 'none',
    1: 'amazonUs',
    2: 'walmart',
    3: 'aliExpressCn',
    4: 'aliExpressUs',
    5: 'amazonUk',
  },
  PRICING_STRATEGIES: {
    1: 'fixedPrice',
    2: 'competition',
    3: 'penetration',
  },
  HTML_TEMPLATE_SYSTEM: {
    1: 'template1',
    2: 'template2',
  },
  ITEM_CODE: {
    1: 'upc',
    2: 'asin',
    3: 'url',
    4: 'marketId',
  },
  ITEM_STATUS: {
    0: 'none',
    1: 'added',
    2: 'recievedScrapeService',
    3: 'succeededToDiscover',
    4: 'failedToDicscover',
    5: 'waitingForStrategyCalculation',
    6: 'validationCategoriesNotFound',
    7: 'failedStrategyCalculation',
    8: 'waitingForScrape',
    9: 'succededToScrape',
    10: 'failedToScrape',
    11: 'itemTimedOut',
  },
  LANGUAGE: {
    0: 'unspecified',
    1: 'en',
    2: 'es',
  },
  LISTING_ITEM_STATUS: {
    1: 'active',
    2: 'completed',
    3: 'custom',
    4: 'customCode',
    5: 'ended',
    6: 'outOfStock',
  },
  LISTING_STATUS: {
    1: 'inProgress',
    2: 'partiallyCompleted',
    3: 'completed',
    4: 'refused',
    5: 'stopped',
  },
  MARKETPLACE_API_TASK: {
    0: 'uncpecified',
    1: 'createAccessToken',
    2: 'refreshAccessToken',
    3: 'checkAccessToken',
    4: 'createBusinessPolicy',
    5: 'updateBusinessPolicy',
    6: 'deleteBusinessPolicy',
  },
  ORDER_STATUS: {
    0: 'unknown',
    1: 'ordered',
    2: 'failed',
    3: 'shipped',
    4: 'inProcess',
    5: 'canceled',
    6: 'completed',
    7: 'standby',
    8: 'returned',
    9: 'refunded',
    10: 'stopped',
  },
  SPYDER_TYPE: {
    1: 'walProduct',
    2: 'amzProduct',
  },
  VALIDATION: {
    0: 'none',
    1: 'blackList',
    2: 'vero',
    3: 'blackListCategory',
  },
};

export default ENUMS_TRANSFORM;
