import { Input } from 'antd';
import styled from '@emotion/styled';

const CustomInput = styled(Input)`
  width: ${props => props.width || '13.5em'};
  border-radius: 15px;
  border-color: ${({ theme }) => theme.colors.greyMid};
  color: ${({ theme }) => theme.colors.greySecondary};
  font-size: ${({ theme }) => theme.fontSize.medium};
  line-height: ${({ theme }) => theme.lineHeight.big};
  height: ${({ height }) => height || '22px'};
  border-color: ${({ theme }) => theme.colors.greyMid};

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button,
  & {
    -webkit-appearance: none;
    -moz-appearance: textfield !important;
  }

  :hover {
    border-color: ${({ theme }) => theme.colors.blueMed};
    border-right-width: 1px !important;
  }
`;

CustomInput.TextArea = Input.TextArea;

export default CustomInput;
