import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import styled from '@emotion/styled';
import { themeColors } from '../../../constants/theme';
import IconSvg from '../IconSvg';
import FormattedMessageFixed from '../../../HOC/FormattedMessageFixed';

const Heading = styled.span`
  font-weight: 500;
  display: flex;
  font-size: 12px;
  color: #3f435c;
  align-items: center;
`;

const Container = styled.div`
  margin-bottom: ${({ marginBottom }) => marginBottom || null};
  margin-top: ${({ marginTop }) => marginTop || null};
`;

export const SettingsFormHeader = ({
  id,
  defaultMessage,
  closeButton,
  marginTop,
  marginBottom,
  ...props
}) => (
  <Container
    marginBottom={marginBottom}
    marginTop={marginTop}
    className={props.className}
    style={props.style}
  >
    <Heading
      key={`${id}-header`}
      style={{
        justifyContent: closeButton ? 'space-between' : 'flex-start',
      }}
    >
      <FormattedMessageFixed id={id} defaultMessage={defaultMessage} {...props} />
      {closeButton && (
        <div style={{ padding: '0.5em' }} onClick={closeButton.clickHandler}>
          <IconSvg pointer hoverColor={themeColors.blue} size={10} type={closeButton.type} />
        </div>
      )}
    </Heading>
    <Divider key={`${id}-divider`} style={{ margin: '3px 0' }} />
  </Container>
);

SettingsFormHeader.defaultProps = {
  marginBottom: '14px',
  marginTop: '1em',
  className: '',
  style: {},
};

SettingsFormHeader.propTypes = {
  id: PropTypes.string,
  defaultMessage: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  closeButton: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
};
