import React from 'react';
import { Form } from 'antd';
import { SettingsFormHeader } from '../../common/SettingsFormHeader';
import { FormItem } from '../commonUI/commonUI';

const UserProfileComponent = ({ renderFormInputs: { loginInputs, businessInputs } }) => {
  return (
    <Form className="login-form" layout="vertical">
      <FormItem>
        <SettingsFormHeader
          key="login-information-header"
          id="subscription.loginInformation"
          defaultMessage="LOGIN INFORMATION"
        />
        {loginInputs}
      </FormItem>
      <FormItem>
        <SettingsFormHeader
          key="business-information-header"
          id="subscription.businessInformation"
          defaultMessage="BUSINESS INFORMATION"
        />
        {businessInputs}
      </FormItem>
    </Form>
  );
};

export default UserProfileComponent;
