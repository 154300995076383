import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import Cookies from 'universal-cookie';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import CommonButton from '../common/Button';
import CustomInput from '../common/Input';
import IconSvg from '../common/IconSvg';
import {
  CloseButtonContainer,
  CustomModal,
  ContentRow,
  PopUpBody,
  ButtonsContainer,
  AddToSumButton,
  Background,
  ContentColumn,
} from './styled';
import { colors, fontSize, lineHeight, fontWeight, themeColors } from '../../constants';

@inject('routingStore')
@inject('settingsStore')
@observer
class BalanceModal extends Component {
  componentDidMount() {
    const { setLoadSum } = this.props;
    setLoadSum(0);
  }

  updateRouteState = path => {
    const { routingStore } = this.props;
    routingStore.browserHistory.push(`/${path}`);
  };

  render() {
    const mainButtonCss = { minWidth: '150px' };
    const minLimit = 25;
    const {
      settingsStore: { currencyStore },
    } = this.props;

    const {
      settingsStore: {
        balance: { paypalEligible },
      },
    } = this.props;
    // console.log("paypalEligible",paypalEligible);

    const {
      setLoadSum,
      visible,
      closeModal,
      openLoadBalancePaypal,
      openLoadBalancePayoneer,
      commissionPayoneer,
      openBalanceStatus,
      commission,
      handleInput,
      loadSum,
      isJA,
    } = this.props;
    return (
      <CustomModal
        visible={visible}
        onCancel={closeModal}
        closable={false}
        width="520px"
        footer={null}
      >
        <CloseButtonContainer>
          <IconSvg type="x" onClick={closeModal} size={10} />
        </CloseButtonContainer>
        <PopUpBody>
          <ContentRow noMargin height="50px">
            <IconSvg type="balance-graph" size={33} />
            <h1
              css={css`
                ${isJA && 'font-size: 1.75em;'}
                text-align: center;
                font-family: Heebo-Medium;
                color: ${themeColors.blue};
                margin: 0 0 0 17px;
                display: inline-block;
              `}
            >
              <FormattedMessage id="balance.loadBalanceTitle" />
            </h1>
          </ContentRow>
          <ContentColumn justifyContent="space-around" height="185px">
            <ContentRow noMargin>
              <span
                css={css`
                  font-size: ${fontSize.larger};
                  color: ${colors.greyDark};
                  white-space: pre;
                `}
              >
                <FormattedMessage id="balance.addCurrency" values={{ currency: currencyStore }} />
              </span>
              <CustomInput value={loadSum || ''} width="8em" type="text" onChange={handleInput} />
            </ContentRow>
            <ContentRow noMargin height="18px">
              <span
                css={css`
                  font-size: ${fontSize.medium};
                  line-height: ${lineHeight.medium};
                  font-weight: ${fontWeight.normal};
                  color: ${colors.pinkDark};
                `}
              >
                {loadSum > 0 && loadSum < minLimit && `Minimum balance load is $${minLimit}`}
                {loadSum > paypalEligible && `Maximum Paypal load is $${paypalEligible}`}
              </span>
            </ContentRow>
            <ContentRow justifyContent="space-evenly" width="50%">
              <AddToSumButton
                currencyValue={currencyStore}
                setLoadSum={setLoadSum}
                sumValue={100}
              />
              <AddToSumButton
                currencyValue={currencyStore}
                setLoadSum={setLoadSum}
                sumValue={500}
              />
              <AddToSumButton
                currencyValue={currencyStore}
                setLoadSum={setLoadSum}
                sumValue={1000}
              />
            </ContentRow>
            <ContentRow noMargin justifyContent="space-between">
              {paypalEligible > 0 ? (
                <ContentColumn>
                  <CommonButton
                    cssProps={{
                      padding: 'padding: 0 20px',
                      borderRadius: '20px',
                      marginBottom: '5px',
                      marginRight: '5px',
                    }}
                    size="large"
                    type="primary"
                    clickHandler={openLoadBalancePaypal}
                    disabled={!loadSum || loadSum < minLimit || loadSum > paypalEligible}
                  >
                    <FormattedMessage id="balance.loadViaPaypal" />
                    <IconSvg type="paypal" size="20" marginLeft="5px" />
                  </CommonButton>
                  {loadSum ? (
                    <FormattedMessage
                      id="balance.total"
                      values={{
                        sum: commission + loadSum,
                        currency: currencyStore,
                      }}
                    />
                  ) : (
                    <br />
                  )}
                </ContentColumn>
              ) : null}

              <ContentColumn>
                <CommonButton
                  cssProps={{
                    padding: 'padding: 0 20px',
                    borderRadius: '20px',
                    marginBottom: '5px',
                    marginLeft: '5px',
                  }}
                  size="large"
                  type="primary"
                  clickHandler={openLoadBalancePayoneer}
                  disabled={!loadSum || loadSum < minLimit}
                >
                  <FormattedMessage id="balance.loadViaPayoneer" />
                  <IconSvg type="payoneer" size="20" marginLeft="5px" />
                </CommonButton>
                {loadSum ? (
                  <span>
                    <FormattedMessage
                      id="balance.total"
                      values={{
                        sum: commissionPayoneer + loadSum,
                        currency: currencyStore,
                      }}
                    />
                  </span>
                ) : (
                  <br />
                )}
              </ContentColumn>
            </ContentRow>
          </ContentColumn>
          <ButtonsContainer>
            <ContentRow noMargin>
              <CommonButton
                cssProps={mainButtonCss}
                clickHandler={openBalanceStatus}
                type="normal"
                height="25px"
              >
                <FormattedMessage id="balance.balanceStatus" />
              </CommonButton>
              <CommonButton
                // disabled
                cssProps={mainButtonCss}
                clickHandler={() => {
                  closeModal();
                  this.updateRouteState('balance');
                }}
                height="25px"
                type="primary"
              >
                <FormattedMessage id="balance.autoReload" />
              </CommonButton>
            </ContentRow>
          </ButtonsContainer>
        </PopUpBody>
        <Background />
      </CustomModal>
    );
  }
}

BalanceModal.propTypes = {
  settingsStore: PropTypes.object,
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  openLoadBalancePaypal: PropTypes.func,
  openLoadBalancePayoneer: PropTypes.func,
  openBalanceStatus: PropTypes.func,
  handleInput: PropTypes.func,
  loadSum: PropTypes.number,
  commission: PropTypes.number,
  commissionPayoneer: PropTypes.number,
  setLoadSum: PropTypes.func,
  isJA: PropTypes.bool,
};
export default BalanceModal;
