import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Spinner from '../../components/common/spinner';

@inject('settingsStore')
@observer
class ClosePopup extends Component {
  componentDidMount() {
    const { location } = this.props;
    console.log('SSSSS: ', location);
    window.opener.postMessage(
      { type: 'OAuthAccessCode', payload: location.query.code, client: location.pathname },
      '*',
    );
  }

  render() {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
}

export default ClosePopup;
