import React from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';
import { Provider } from 'mobx-react';
import { ThemeProvider } from 'emotion-theming';

import { theme } from './constants/theme';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import stores from './stores';
import '../scss/index.scss';

const GlobalStyles = createGlobalStyle`
  ${styledNormalize}
  body {
    font-family: 'Heebo', sans-serif;
  }
`;

Promise.all(stores.initOnLoad()).then(() => {
  ReactDOM.render(
    <Provider {...stores}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Routes />
      </ThemeProvider>
    </Provider>,
    document.getElementById('root'),
  );
});
serviceWorker.register();
