import React, { Component } from 'react';
import { Card, Button, Divider, Modal, Select } from 'antd';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { injectIntl, FormattedMessage } from 'react-intl';
import { theme, themeColors } from '../../../constants';
import IconSvg from '../../common/IconSvg';
import Checkout from '../../Checkout';
import { FormItem } from '../commonUI/commonUI';

const formItemLayout = {
  colon: false,
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const CustomCard = styled(Card)`
  min-height: 35em;
  margin-right: 1em;
  border-radius: 5px;
  border: ${props => (props.selected ? `1px solid ${themeColors.darkBlue}` : '')};
  box-shadow: 0 1px 6px 0 rgba(71, 69, 136, 0.11);
  ${({ zindex }) => zindex && 'z-index: 1;'} :hover {
    box-shadow: 0 1px 6px 0 rgba(71, 69, 136, 0.11);
  }
  & .ant-card-body {
    display: flex;
    padding: 15px;
    flex-direction: column;
    overflow: auto;
  }
  ${({ popped }) =>
    popped &&
    `
    position: absolute;
    top: 10em;
    left: calc(50% - 140px);
    width: 280px;
    transform: scale(1.1);
  `}
`;
const CloseButtonContainer = styled.div`
  width: 100%;
  position: relative;
  & > span {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const Meta = styled(Card.Meta)`
  & .ant-card-meta-detail > div:not(:last-child) {
    margin: 0;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: center;
`;
const AdditionalInfoWrapper = styled.div`
  padding: 5px 0;
  font-size: ${theme.fontSize.medium};
`;

const PaymentToggle = styled.div`
  display: flex;
  margin: 0 0 10px 0;
  font-size: ${theme.fontSize.small};
  justify-content: space-between;

  & button {
    width: 45%;
  }
`;

const FlexibleInfoWrapper = ({ children }) => (
  <div
    css={css`
      height: 317px;
      display: flex;
      flex-direction: column;
      min-height: 0;
    `}
  >
    <div
      css={css`
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        min-height: 0;
      `}
    >
      {children}
    </div>
  </div>
);

const payments = [
  // { id: 1, value: 'Paypal' },
  { id: 2, value: 'Payoneer' },
];
const { confirm } = Modal;
const { Option } = Select;

@inject('settingsStore')
@injectIntl
@observer
class SubscriptionPlanCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poppedCardAddOns: props.settingsStore.subscription.planById(props.plan.id).features,
      showCheckout: false,
      payPalConnectionLoading: false,
      paymentMethod: '',
      payoneerAccount: '',
    };
    this.toggleAddOn = this.toggleAddOn.bind(this);
  }

  showDowngradeInfo(plan) {
    const { upgradePlan, intl } = this.props;
    const { payoneerAccount } = this.state;
    confirm({
      content: intl.formatMessage({ id: 'subscription.downgradeInfo' }),
      onOk() {
        upgradePlan(plan, payoneerAccount);
      },
      onCancel() {},
    });
  }

  toggleAddOn(newValue, addOnId) {
    const { poppedCardAddOns } = this.state;
    const {
      settingsStore: {
        subscription: { addOnById },
      },
    } = this.props;
    const thisAddOnCategory = addOnById(addOnId);
    const newState =
      newValue === true
        ? {
            poppedCardAddOns: poppedCardAddOns.map(addOn => {
              return {
                ...addOn,
                // only one addOnPlan from a given type can be active
                isSelected: addOn.id === addOnId ? true : !thisAddOnCategory.hasPlan(addOn.id),
              };
            }),
          }
        : {
            poppedCardAddOns: poppedCardAddOns.map(addOn => {
              return {
                ...addOn,
                isSelected: addOn.id === addOnId ? false : addOn.isSelected,
              };
            }),
          };
    this.setState(newState);
  }

  updateAccount = value => {
    const {
      settingsStore: {
        subscription: { updateBillingPaypalAccount },
      },
    } = this.props;
    if (value >= 0) {
      updateBillingPaypalAccount(value);
    } else {
      this.setState({ showCheckout: true });
    }
  };

  updatePayoneerAccount = value => {
    const {
      settingsStore: {
        subscription: { updateBillingPayoneerAccount },
        connectionManagement: { connectPayoneerAccount },
      },
      plan,
    } = this.props;
    if (value) {
      this.setState({ payoneerAccount: value });
    } else {
      connectPayoneerAccount(plan);
    }
  };

  render() {
    const {
      plan,
      upgradePlan,
      selected,
      selectedPlanCost,
      poppedOver,
      closeCard = () => {},
      intl,
      hoveringCardButtonMessage,
      isJA,
      disableUpgrade,
      currentPlan,
      settingsStore: {
        subscription: {
          billableAccounts,
          billingAccountIndex,
          billingPayoneerAccountId,
          billablePayoneerAccounts,
        },
      },
    } = this.props;
    const { showCheckout, payPalConnectionLoading, paymentMethod, payoneerAccount } = this.state;
    const monthlyPayment = plan.period === 'MONTH';
    const needsMoreInfo = false;
    const cost = plan.price;
    let buttonMessage;
    let shouldShowDowngradeInfo = false;
    if (!poppedOver) {
      if ((selectedPlanCost && plan.price > selectedPlanCost) || !selectedPlanCost) {
        buttonMessage = intl.formatMessage({ id: 'subscription.upgradePlan' });
      } else {
        buttonMessage = intl.formatMessage({ id: 'subscription.downgradePlan' });
      }
    } else {
      if (selectedPlanCost && plan.price < selectedPlanCost) {
        shouldShowDowngradeInfo = true;
      }
      buttonMessage = hoveringCardButtonMessage;
    }

    return (
      <div onClick={e => poppedOver && e.stopPropagation()}>
        <CustomCard selected={selected} popped={poppedOver ? 1 : 0} zindex="1">
          {poppedOver && (
            <CloseButtonContainer>
              <IconSvg type="x" onClick={closeCard} size={10} />
            </CloseButtonContainer>
          )}
          <Meta title={plan.title} style={{ display: 'flex', justifyContent: 'flex-start' }} />
          <Divider style={{ margin: '1em 0' }} />
          <div style={{ fontWeight: '500' }}>
            <span style={{ fontSize: '22px' }}>$ {cost}</span>
            <span style={{ fontSize: '13px' }}>/ {monthlyPayment ? 'month' : 'year'}</span>
          </div>
          <FlexibleInfoWrapper>
            <div
              className="scroll-1"
              css={css`
                padding-left: 1.5em;
                height: 180px;
                overflow-y: auto;
                flex-grow: 1;
                min-height: 0;
              `}
              dangerouslySetInnerHTML={{ __html: plan.cardFormatDescription }}
            />
            {/* {poppedOver && showCheckout && paymentMethod === 'Paypal' && (
              <Checkout
                plan={plan}
                noPadding
                payPalConnectionLoading={payPalConnectionLoading}
                beforeConnect={() => {
                  this.setState({
                    payPalConnectionLoading: true,
                  });
                }}
                afterConnect={acc => {
                  this.setState(
                    {
                      payPalConnectionLoading: false,
                      showCheckout: false,
                    },
                    () => closeCard(),
                  );
                }}
              />
            )} */}
            {poppedOver && (
              <>
                <h3>Select a payment method</h3>
                <PaymentToggle css={css`display:flex; align-items:center;justify-content:center;`}>
                  {payments.map(item => (
                    <Button
                      key={item.id}
                      type={paymentMethod === item.value && 'primary'}
                      onClick={() => this.setState({ paymentMethod: item.value })}
                    >
                      {item.value}
                    </Button>
                  ))}
                </PaymentToggle>
                {/* {paymentMethod === 'Paypal' && (
                  <FormItem
                    key="paypal-account"
                    {...formItemLayout}
                    label={intl.formatMessage({ id: 'subscription.paypalAccount' })}
                  >
                    <Select
                      onChange={this.updateAccount}
                      value={billingAccountIndex || 'Not found'}
                      style={{ maxWidth: 'calc(100% - 18px)' }}
                    >
                      {billableAccounts?.length &&
                        billableAccounts?.map((name, idx) => (
                          <Option key={name} value={idx}>
                            {name}
                          </Option>
                        ))}
                      <Option key="other" value={-1}>
                        <span style={{ fontFamily: 'Heebo-Thin' }}>
                          {intl.formatMessage({ id: 'listing.connectAnother' })}
                        </span>
                      </Option>
                    </Select>
                  </FormItem>
                )} */}
                {paymentMethod === 'Payoneer' && (
                  <FormItem
                    key="payoneer-account"
                    {...formItemLayout}
                    label={intl.formatMessage({ id: 'subscription.payoneerAccount' })}
                  >
                    <Select
                      onChange={this.updatePayoneerAccount}
                      value={payoneerAccount}
                      style={{ maxWidth: 'calc(100% - 18px)' }}
                    >
                      {billablePayoneerAccounts.map(element => (
                        <Option key={element} value={element}>
                          {element}
                        </Option>
                      ))}
                      <Option key="other" value="">
                        <span style={{ fontFamily: 'Heebo-Thin' }}>
                          {intl.formatMessage({ id: 'listing.connectAnother' })}
                        </span>
                      </Option>
                    </Select>
                  </FormItem>
                )}
              </>
            )}
            {poppedOver && !selected && needsMoreInfo && (
              <AdditionalInfoWrapper>
                <span style={{ color: themeColors.pink }}>*</span>
                <FormattedMessage id="subscription.needMoreInfo" />
              </AdditionalInfoWrapper>
            )}
            {poppedOver && !selectedPlanCost && !needsMoreInfo && (
              <AdditionalInfoWrapper>
                <span style={{ color: themeColors.pink }}>*</span>
                <FormattedMessage
                  id="subscription.firstPayment"
                  values={{ monthlyCost: `$ ${plan.price}` }}
                />
              </AdditionalInfoWrapper>
            )}
            {!selected ? (
              <Row>
                {(!poppedOver ||
                  (poppedOver && !(showCheckout || billableAccounts.length === 0))) && (
                  <Button
                    disabled={disableUpgrade}
                    style={{
                      background: themeColors.backgroundLightBlue,
                      fontSize: isJA ? '10px' : null,
                    }}
                    onClick={() => {
                      console.log('updating subscription');
                      return shouldShowDowngradeInfo
                        ? this.showDowngradeInfo(plan)
                        : upgradePlan(plan, payoneerAccount);
                    }}
                  >
                    {buttonMessage}
                  </Button>
                )}
              </Row>
            ) : moment().isAfter(currentPlan?.plan_expiry) ? (
              <Row>
                {(!poppedOver ||
                  (poppedOver && !(showCheckout || billableAccounts.length === 0))) && (
                  <Button
                    disabled={disableUpgrade}
                    style={{
                      background: themeColors.backgroundLightBlue,
                      fontSize: isJA ? '10px' : null,
                    }}
                    onClick={() => {
                      console.log('updating subscription');
                      return shouldShowDowngradeInfo
                        ? this.showDowngradeInfo(plan)
                        : upgradePlan(plan, payoneerAccount);
                    }}
                  >
                    {buttonMessage}
                  </Button>
                )}
              </Row>
            ) : (
              <Row>
                <Button
                  disabled
                  style={{
                    background: themeColors.backgroundLightBlue,
                    fontSize: isJA ? '10px' : null,
                  }}
                >
                  Active Plan
                </Button>
              </Row>
            )}
          </FlexibleInfoWrapper>
        </CustomCard>
      </div>
    );
  }
}

SubscriptionPlanCard.propTypes = {
  plan: PropTypes.object,
  intl: PropTypes.object,
  selected: PropTypes.bool,
  upgradePlan: PropTypes.func,
  closeCard: PropTypes.func,
  // monthlyPayment: PropTypes.bool,
  isJA: PropTypes.bool,
  poppedOver: PropTypes.bool,
  selectedPlanCost: PropTypes.number,
  // requiredData: PropTypes.array,
  settingsStore: PropTypes.object,
  hoveringCardButtonMessage: PropTypes.string,
};

export default SubscriptionPlanCard;
