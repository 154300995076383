import { types } from 'mobx-state-tree';

const DepositsPagination = types
  .model('DepositsPagination', {
    total: 0,
    start: 0,
    limit: 500,
  })
  .actions(self => ({
    setStart: st => {
      self.start = st;
    },
    setLimit: lmt => {
      self.limit = lmt;
    },
    setTotal: ttl => {
      self.total = ttl;
    },
  }));

export default DepositsPagination;
