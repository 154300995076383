import React from 'react';
import { Button } from 'antd';
import { css } from '@emotion/core';
import IconSvg from '../IconSvg';

const ButtonWithIcon = props => {
  return (
    <Button
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onClick={props.onClick}
      disabled={props.disabled}
      css={css`
        padding: 0;
        border: 0;
        background-color: transparent !important;
      `}
    >
      <IconSvg type={props.iconType} size={props.iconSize} />
    </Button>
  );
};

export default ButtonWithIcon;
