import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import UserProfileComponent from './UserProfile';
import { InputTitle, Input, ErrorText } from './styled';

const ERROR_MESSAGE = 'Field is required';

@injectIntl
@inject('sessionStore')
@inject('settingsStore')
@observer
class UserProfileContainer extends Component {
  constructor() {
    super();

    this.state = {
      isFormValid: false,
      formLoginControls: {
        email: {
          value: '',
          label: 'User Email',
          valid: true,
        },
        password: {
          value: '',
          label: 'Password',
          valid: true,
        },
      },
      formBusinessControls: {
        businessName: {
          value: '',
          label: 'Business Name',
          valid: false,
          touched: false,
        },
        firstname: {
          value: '',
          label: 'First Name',
          valid: false,
          touched: false,
        },
        lastname: {
          value: '',
          label: 'Last Name',
          valid: false,
          touched: false,
        },
        streetAddress: {
          value: '',
          label: 'Street Address',
          valid: false,
          touched: false,
        },
        city: {
          value: '',
          label: 'City',
          valid: false,
          touched: false,
        },
        postalCode: {
          value: '',
          label: 'Postal Code',
          valid: false,
          touched: false,
        },
        state: {
          value: '',
          label: 'State',
          valid: false,
          touched: false,
        },
      },
    };
  }

  componentDidMount() {
    const {
      updateBreadcrumbs,
      updateApplyBtn,
      intl,
      settingsStore: {
        userProfile: {
          email,
          business_details: {
            name = '',
            first_name = '',
            last_name = '',
            address_line_1 = '',
            city = '',
            zip = '',
            state = '',
          },
        },
      },
      saveUserProfileClone,
    } = this.props;

    this.updateApplyBtn = updateApplyBtn;
    this.saveUserProfileClone = saveUserProfileClone;

    const loginControls = {
      email: {
        ...this.state.formLoginControls.email,
        value: email,
      },
      password: {
        ...this.state.formLoginControls.password,
        value: '123456',
      },
    };
    const businessControls = {
      businessName: {
        ...this.state.formBusinessControls.businessName,
        value: name,
        touched: !!name,
        valid: this.validateControl(name),
      },
      firstname: {
        ...this.state.formBusinessControls.firstname,
        value: first_name,
        touched: !!first_name,
        valid: this.validateControl(first_name),
      },
      lastname: {
        ...this.state.formBusinessControls.lastname,
        value: last_name,
        touched: !!last_name,
        valid: this.validateControl(last_name),
      },
      streetAddress: {
        ...this.state.formBusinessControls.streetAddress,
        value: address_line_1,
        touched: !!address_line_1,
        valid: this.validateControl(address_line_1),
      },
      city: {
        ...this.state.formBusinessControls.city,
        value: city,
        touched: !!city,
        valid: this.validateControl(city),
      },
      postalCode: {
        ...this.state.formBusinessControls.postalCode,
        value: zip,
        touched: !!zip,
        valid: this.validateControl(zip),
      },
      state: {
        ...this.state.formBusinessControls.state,
        value: state,
        touched: !!state,
        valid: this.validateControl(state),
      },
    };

    const isFormValid = this.validateFields(loginControls) && this.validateFields(businessControls);

    this.setState(
      {
        isFormValid,
        formLoginControls: loginControls,
        formBusinessControls: businessControls,
      },
      () => {
        this.updateApplyBtn(!this.state.isFormValid);
        if (this.state.isFormValid) {
          this.saveDataToStore();
        }
      },
    );

    updateBreadcrumbs(
      {
        name: intl.formatMessage({ id: 'settingsModal.userProfile' }),
      },
      true,
    );
  }

  validateControl(value) {
    return value.trim() !== '';
  }

  validateFields = fields => {
    let isValid = true;
    Object.keys(fields).forEach(name => {
      isValid = fields[name].valid && isValid;
    });

    return isValid;
  };

  changeControl = (control, value) => {
    control.value = value;
    control.touched = true;
    control.valid = this.validateControl(control.value);

    return control;
  };

  onChangeInput = (e, controlName) => {
    const loginControls = { ...this.state.formLoginControls };
    const businessControls = { ...this.state.formBusinessControls };

    switch (true) {
      case !!loginControls[controlName]:
        loginControls[controlName] = this.changeControl(
          { ...loginControls[controlName] },
          e.target.value,
        );
        break;
      case !!businessControls[controlName]:
        businessControls[controlName] = this.changeControl(
          { ...businessControls[controlName] },
          e.target.value,
        );
        break;
      default:
        break;
    }

    const isFormValid = this.validateFields(loginControls) && this.validateFields(businessControls);

    this.setState(
      {
        isFormValid,
        formLoginControls: loginControls,
        formBusinessControls: businessControls,
      },
      () => {
        this.updateApplyBtn(!this.state.isFormValid);
        if (this.state.isFormValid) {
          this.saveDataToStore();
        }
      },
    );
  };

  saveDataToStore = () => {
    const {
      businessName,
      firstname,
      lastname,
      streetAddress,
      city,
      postalCode,
      state,
    } = this.state.formBusinessControls;

    this.saveUserProfileClone({
      name: businessName.value,
      first_name: firstname.value,
      last_name: lastname.value,
      address_line_1: streetAddress.value,
      city: city.value,
      zip: postalCode.value,
      state: state.value,
    });
  };

  renderInput = (control, controlName) => {
    let type;
    let disabled;

    switch (controlName) {
      case 'email':
        type = 'email';
        disabled = true;
        break;
      case 'password':
        type = 'password';
        disabled = true;
        break;
      default:
        type = 'text';
        disabled = false;
    }

    const errorMessageBlock = (
      <Col span={6}>
        <ErrorText>{ERROR_MESSAGE}</ErrorText>
      </Col>
    );

    return (
      <Row gutter={[0, 15]} key={controlName}>
        <Col span={6}>
          <InputTitle>{control.label}</InputTitle>
        </Col>
        <Col span={5}>
          <Input
            className={`${!control.valid && control.touched ? 'error' : ''}`}
            type={type}
            value={control.value}
            valid={control.valid}
            touched={control.touched}
            errorMessage={ERROR_MESSAGE}
            shouldValidate
            onChange={e => this.onChangeInput(e, controlName)}
            disabled={disabled}
          />
        </Col>
        {!control.valid && control.touched && errorMessageBlock}
      </Row>
    );
  };

  renderFormInputs = () => {
    const loginInputs = Object.keys(this.state.formLoginControls).map(controlName => {
      const control = this.state.formLoginControls[controlName];

      return this.renderInput(control, controlName);
    });

    const businessInputs = Object.keys(this.state.formBusinessControls).map(controlName => {
      const control = this.state.formBusinessControls[controlName];

      return this.renderInput(control, controlName);
    });

    return { loginInputs, businessInputs };
  };

  render() {
    return (
      <>
        <UserProfileComponent renderFormInputs={this.renderFormInputs()} />
      </>
    );
  }
}

UserProfileContainer.propTypes = {
  intl: PropTypes.object,
  settingsStore: PropTypes.object,
  updateBreadcrumbs: PropTypes.func,
  saveUserProfileClone: PropTypes.func,
};

export default UserProfileContainer;
