import { types } from 'mobx-state-tree';

const Location = types
  .model('Location', {
    id: types.number,
    name: types.string,
    datacenter_id: types.number,
    country: types.string,
    continent: types.string,
    state: types.string,
    region_code: types.string,
  })
  .views(self => ({
    get selectOptionFormat() {
      const { name, country, state, continent } = self;
      const maybeState = state ? `${state}, ` : '';
      return `${name} (${maybeState}${country}, ${continent})`;
    },
  }));

export default Location;
