import { types } from 'mobx-state-tree';

export const Payment = types.model('Payment', {
  additionalCatalogItemCost: types.maybe(types.number),
  additionalCatalogItems: types.maybe(types.number),
  additionalListedItemCost: types.maybe(types.number),
  additionalListedItems: types.maybe(types.number),
  additionalOrderCost: types.maybe(types.number),
  additionalOrders: types.maybe(types.number),
  amount: types.maybe(types.number),
  refundAmount: types.maybe(types.number),
  totalAmount: types.maybe(types.number),
  addOnsAmount: types.maybe(types.number),
  businessName: types.maybe(types.string),
  city: types.maybe(types.string),
  currencyId: types.maybe(types.string),
  email: types.maybe(types.string),
  endPeriodDatetime: types.maybe(types.string),
  id: types.maybe(types.number),
  invoiceId: types.maybe(types.number),
  paymentDatetime: types.maybe(types.string),
  phone: types.maybe(types.string),
  planEndDatetime: types.maybe(types.string),
  planName: types.maybe(types.string),
  planPrice: types.maybe(types.number),
  planStartDatetime: types.maybe(types.string),
  startPeriodDatetime: types.maybe(types.string),
  street: types.maybe(types.string),
  transactionId: types.maybe(types.string),
  userName: types.maybe(types.string),
  zipCode: types.maybe(types.string),
});
