import styled from '@emotion/styled';
import { Form, Radio } from 'antd';
import { themeColors } from '../../../constants/theme';

export const ProgressContainer = styled.div`
  border-radius: 1px;
  border: solid 1px #cdd3e0;
  padding: 0.5em 1em;
  & > *:not(:first-of-type) {
    margin-left: 2.5em;
  }
`;

export const RadioGroup = styled(Radio.Group)`
  & label {
    font-weight: normal;
  }
  margin: 2em 0 1em 0;
`;

export const PlansContainer = styled.div`
  padding: 1em 1em 2em 1em;
  background: ${themeColors.backgroundLightBlue};
  border-radius: 4px;
`;

export const MySubContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
`;
