import { types } from 'mobx-state-tree';
import { AddOnPlan } from './AddOnPlan';

export const Plan = types
  .model('Plan', {
    created: types.maybeNull(types.string), // "2020-01-27 13:18:43.164000"
    currency: types.maybeNull(types.string), // "USD"
    description: types.maybeNull(types.string),
    features: types.maybeNull(types.frozen()), // {BLUECARE: {count: 0, limit: 0}, FEE: 0.6, ORDERS: {count: 0, limit: -1}}
    id: types.string,
    is_active: types.maybeNull(types.boolean), // true
    period: types.maybeNull(types.string), // "MONTH"
    price: types.maybeNull(types.number), // 20
    title: types.maybeNull(types.string), // "Bronze"
    updated: types.maybeNull(types.string), // "2020-01-27 13:18:43.164000"
  })
  .views(self => ({
    get isFree() {
      return self.title === 'Free';
    },
    get cardFormatDescription() {
      return `<ul>
      ${self.description.split('*').reduce((acc, curr) => `${acc} <li>${curr} </li>\n`, '')}
    </ul>`;
    },
  }));
