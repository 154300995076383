import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { Col, Collapse } from 'antd';
import { clone } from 'mobx-state-tree';
import { FormattedMessage } from 'react-intl';
import { startCase } from 'lodash';
import CommonButton from '../common/Button';
import Subscription from '../SettingTabs/Subscription';

import IconSvg from '../common/IconSvg';
import { themeColors } from '../../constants';
import {
  CustomModal,
  TopRow,
  BotRow,
  ModalHeader,
  ModalConentHeader,
  CustomCard,
  CustomPanel,
  CustomBreadcrumbs,
  ModalCornerButton,
  MainCol,
} from './styled.js';

import ENUMS from '../../constants/enums';
import UserProfileContainer from '../SettingTabs/UserProfile';
import ItemLocation from '../SettingTabs/ItemLocation/ItemLocation';

const menuBackgroundColor = themeColors.backgroundLightBlue;
const accordionHeaderColor = themeColors.backgroundDarkBlue;

@inject('settingsStore')
@inject('localeStore')
@inject('toastsStore')
@observer
class SettingsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.activeTab || ENUMS.SETTINGS_TABS.SUBSCRIPTION,
      isFullScreen: false,
      subscriptionClone: clone(props.settingsStore.subscription),
      userProfileFields: {},
      breadcrumbs: [],
      applyBtnDisabled: true,
    };
    this.rootSubmenuKeys = ['sub1', 'sub2', 'sub3', 'sub4'];
    this.selectTab = this.selectTab.bind(this);
  }

  selectTab = key => {
    const { breadcrumbs } = this.state;
    if (breadcrumbs.length > 1) {
      breadcrumbs[0].clickHandler();
    }
    this.setState(
      {
        applyBtnDisabled: true,
        activeTab: key,
      },
      // updateHash(key),
    );
  };

  saveChanges = () => {
    const { activeTab, userProfileFields } = this.state;
    const {
      settingsStore: {
        userProfile: { saveUserDataToServer },
      },
    } = this.props;
    this.updateApplyBtn(true);
    switch (activeTab) {
      case ENUMS.SETTINGS_TABS.USER_PROFILE:
        saveUserDataToServer(userProfileFields);
        break;
      default:
        break;
    }
  };

  updateBreadcrumbs = (breadcrumbObj, isReset) => {
    this.setState({
      breadcrumbs: isReset ? [breadcrumbObj] : [...this.state.breadcrumbs, breadcrumbObj],
    });
  };

  updateApplyBtn = disable => {
    this.setState({ applyBtnDisabled: disable });
  };

  saveUserProfileClone = data => {
    this.setState({
      userProfileFields: {
        ...data,
      },
    });
  };

  refreshSubscriptionClone = () => {
    const {
      settingsStore: { subscription },
    } = this.props;
    this.setState({
      subscriptionClone: clone(subscription),
      applyBtnDisabled: true,
    });
  };

  renderTab = () => {
    const {
      subscription: { billingPaypalAccountId },
    } = this.props.settingsStore;
    const { activeTab, subscriptionClone } = this.state;
    const { USER_PROFILE, SUBSCRIPTION, ITEM_LOCATION } = ENUMS.SETTINGS_TABS;
    switch (activeTab) {
      case SUBSCRIPTION:
        return (
          <Subscription
            updateBreadcrumbs={this.updateBreadcrumbs}
            refreshSubscriptionClone={this.refreshSubscriptionClone}
            selectTab={this.selectTab}
            subscriptionClone={subscriptionClone}
            updateApplyBtn={this.updateApplyBtn}
          />
        );
      case USER_PROFILE:
        return (
          <UserProfileContainer
            updateBreadcrumbs={this.updateBreadcrumbs}
            updateApplyBtn={this.updateApplyBtn}
            saveUserProfileClone={this.saveUserProfileClone}
          />
        );
      case ITEM_LOCATION:
        return <ItemLocation updateBreadcrumbs={this.updateBreadcrumbs} />;
      default:
        return 'NO COMP';
    }
  };

  renderBreadcrumbs = () => {
    const { breadcrumbs } = this.state;
    return breadcrumbs.map((crumbObj, i) => (
      <span
        key={crumbObj.name}
        onClick={crumbObj.clickHandler}
        style={{
          cursor: 'pointer',
          color: i === 0 && breadcrumbs.length > 1 ? themeColors.blue : null,
        }}
      >
        {startCase(crumbObj.name)}
        {i !== breadcrumbs.length - 1 && ' > '}
      </span>
    ));
  };

  render() {
    const buttonCss = { width: '128px' };
    const { visible, closeModal } = this.props;
    const { USER_PROFILE, SUBSCRIPTION, ITEM_LOCATION } = ENUMS.SETTINGS_TABS;
    const { applyBtnDisabled } = this.state;
    return (
      <CustomModal
        bodyStyle={{ height: '80vh', overflowY: 'hidden', overflowX: 'hidden' }}
        width={this.state.isFullScreen ? '100%' : '60%'}
        visible={visible}
        onCancel={closeModal}
        closable={false}
        footer={[
          <CommonButton key="back" cssProps={buttonCss} clickHandler={closeModal} type="normal">
            <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
          </CommonButton>,
          <CommonButton
            key="submit"
            cssProps={buttonCss}
            type="primary"
            clickHandler={this.saveChanges}
            disabled={applyBtnDisabled}
          >
            <FormattedMessage id="settingsModal.apply" defaultMessage="Apply" />
          </CommonButton>,
        ]}
      >
        <TopRow>
          <Col
            span={6}
            style={{ background: menuBackgroundColor, display: 'flex', alignItems: 'center' }}
          >
            <ModalHeader className="mainTitle">
              <FormattedMessage id="settingsModal.settings" defaultMessage="Settings" />
            </ModalHeader>
          </Col>
          <ModalConentHeader>
            <CustomBreadcrumbs>{this.renderBreadcrumbs()}</CustomBreadcrumbs>
            <div>
              <ModalCornerButton
                onClick={() => this.setState({ isFullScreen: !this.state.isFullScreen })}
              >
                <IconSvg
                  hoverColor={themeColors.blue}
                  size={10}
                  type={this.state.isFullScreen ? 'shrink' : 'expand'}
                />
              </ModalCornerButton>
              <ModalCornerButton onClick={closeModal}>
                <IconSvg size={10} type="exit" hoverColor={themeColors.blue} />
              </ModalCornerButton>
            </div>
          </ModalConentHeader>
        </TopRow>
        <BotRow>
          <Col
            span={6}
            style={{
              padding: '0',
              height: '100%',
              background: menuBackgroundColor,
              overflow: 'auto',
            }}
          >
            <Collapse
              accordion
              bordered={false}
              style={{ backgroundColor: accordionHeaderColor, borderRadius: '0' }}
            >
              <CustomPanel key="sub1" header="Profile Settings">
                <CustomCard
                  key="1"
                  onClick={() => this.selectTab(USER_PROFILE)}
                  active={this.state.activeTab === USER_PROFILE && 'true'}
                >
                  <FormattedMessage id="settingsModal.userProfile" defaultMessage="User profile" />
                </CustomCard>
                <CustomCard
                  key="2"
                  onClick={() => this.selectTab(SUBSCRIPTION)}
                  active={this.state.activeTab === SUBSCRIPTION && 'true'}
                >
                  <FormattedMessage id="settingsModal.subscription" defaultMessage="Subscription" />
                </CustomCard>
                <CustomCard
                  key="5"
                  onClick={() => this.selectTab(ITEM_LOCATION)}
                  active={this.state.activeTab === ITEM_LOCATION && 'true'}
                >
                  <FormattedMessage
                    id="settingsModal.itemLocation"
                    defaultMessage="Item Location"
                  />
                </CustomCard>
              </CustomPanel>
            </Collapse>
          </Col>
          <MainCol
            id="tm-settings-modal-content"
            className="scroll-1"
            style={{ overflowY: 'scroll', height: '87%' }}
            span={18}
          >
            {this.renderTab()}
          </MainCol>
        </BotRow>
      </CustomModal>
    );
  }
}

SettingsModal.propTypes = {
  settingsStore: PropTypes.object,
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  activeTab: PropTypes.number,
};

export default SettingsModal;
