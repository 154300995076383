import React, { Component } from 'react';
import PropTypes from 'prop-types';
import braintree from 'braintree-web-drop-in';
import { observer, inject } from 'mobx-react';
import { Button } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { css } from '@emotion/core';
import { MyBraintreeDropin, Container } from './styled';

@injectIntl
@inject('settingsStore')
@observer
export default class Checkout extends Component {
  handlePaymentMethod = paymentMethod => {
    const {
      beforeConnect = () => {},
      afterConnect = () => {},
      settingsStore: {
        connectionManagement: { connectAndPay },
      },
      plan,
    } = this.props;
    beforeConnect();
    connectAndPay(plan.price, plan.currency, plan.id, paymentMethod.nonce).then(afterConnect);
  };

  renderSubmitButton = ({ onClick, isDisabled }) => (
    <Button
      onClick={onClick}
      type="primary"
      disabled={isDisabled || this.props.payPalConnectionLoading}
      css={css`
        ${this.props.buttonCss ? this.props.buttonCss : ''}
      `}
    >
      <FormattedMessage id="settingsModal.connectAndPay" />
    </Button>
  );

  render() {
    const {
      settingsStore: {
        connectionManagement: { paypalBillingToken },
      },
    } = this.props;

    return (
      <Container noPadding>
        {paypalBillingToken && (
          <MyBraintreeDropin
            braintree={braintree}
            authorizationToken={paypalBillingToken}
            handlePaymentMethod={this.handlePaymentMethod}
            paypal={{ flow: 'vault' }}
            options={{ card: false }}
            renderSubmitButton={this.renderSubmitButton}
          />
        )}
      </Container>
    );
  }
}

Checkout.propTypes = {
  settingsStore: PropTypes.object,
  afterConnect: PropTypes.func,
  beforeConnect: PropTypes.func,
};
