import { types, getParent, getRoot, flow } from 'mobx-state-tree';
import _ from 'lodash';
import { Plan, Payment, SubscriptionData, AddOn } from '../../models/SettingsStore/Subscription';
import downloadFile from '../../utils/downloadFile';
import { DepositsPagination } from '../../models/SettingsStore/Balance';

const BASE_URL_2 = '/subscription/v1';
const BASE_URL = '/bifm-service';
// const PAYPAL_SERVICE_URL = '/paypal-api-service/v1'; // /customer

const Subscription = types
  .model('subscription', {
    invoices: types.optional(types.array(types.frozen()), []),
    invoicesPagination: types.optional(DepositsPagination, {}),
    transactions: types.optional(types.array(types.frozen()), []),
    transactionsPagination: types.optional(DepositsPagination, {}),
    plans: types.optional(types.array(Plan), []),
    // subscriptionData: types.optional(SubscriptionData, {}),
    loading: false,
    sdInitialized: false,
    pInitialized: false,
    billingAccountIndex: 0,
  })
  .views(self => ({
    get axios() {
      return getParent(self).axios;
    },
    get accountCode() {
      return getRoot(self).sessionStore.accountCode;
    },
    get currentPlan() {
      return getParent(self).userProfile.currentPlan;
    },
    get userEmail() {
      return getParent(self).userProfile.email;
    },
    get currentPlanId() {
      return self?.currentPlan?._id;
    },
    get currentPlanActive() {
      return getParent(self).userProfile.currentPlanActive;
    },
    get currentPlanFree() {
      return self?.currentPlan?.price === 0;
    },
    get usagePercents() {
      return {
        autoPurchaseUsage: 0,
        listedItemsUsage: 0,
      };
    },
    get showToast() {
      return getParent(self, 2).toastsStore.showToast;
    },
    get enums() {
      return getParent(self, 2).enumsStore.getEnumsList;
    },
    // the select will have id as key and name as value
    get billableAccounts() {
      return getParent(self).connectionManagement.billableAccounts;
    },
    get billablePayoneerAccounts() {
      return getParent(self).connectionManagement.billablePayoneerAccounts;
    },
    // the select will have id as key and name as value
    get billingPaypalAccountId() {
      return getParent(self).connectionManagement.billableAccounts[self.billingAccountIndex];
    },
    get trialExpiredNoSubscription() {
      return !self.subscriptionData.subscriptionPlanId && !self.payments.length;
    },
    // get subscriptionInitialized() {
    //   return self.sdInitialized && self.pInitialized;
    // },
    get activePlan() {
      return self.planById(self.subscriptionData.subscriptionPlanId);
    },
  }))
  .actions(self => ({
    planById(id) {
      return self.plans.find(el => el.id === id);
    },
    fetchPlans: flow(function* fetchPlans() {
      try {
        const URL = `${BASE_URL}/subscription/plans`;
        const { data } = yield self.axios.fetch(URL, {}, {});
        self.plans = data.data;
      } catch (err) {
        console.log(err);
      }
    }),
    fetchInvoice: flow(function* fetchInvoice(id) {
      try {
        const {
          data,
          headers: { 'content-disposition': contentDisposition },
        } = yield self.axios.fetch(`${BASE_URL}/Payments/invoice/${id}`, {}, {}, 'blob');
        const fileTitle = /filename=([\w.]+);/.exec(contentDisposition)[1];
        downloadFile(data, fileTitle, 'application/pdf');
      } catch (err) {
        console.error(err);
      }
    }),
    cancelSubscription: flow(function* cancelSubscription() {
      try {
        if (self.currentPlanId) {
          const {
            data: { status, message },
          } = yield self.axios.post(`${BASE_URL}/subscription/cancel`, {}, {});
          if (status === 'ok') {
            self.showToast({
              type: self.enums.TOAST_TYPES.INFO,
              message: 'Your subscription has been cancelled.',
            });
          } else {
            self.showToast({
              type: self.enums.TOAST_TYPES.WARNING,
              message,
            });
          }
        }
      } catch (err) {
        console.log('upgrading plan failed: ', err);
        self.showToast({
          type: self.enums.TOAST_TYPES.WARNING,
          message: 'An error has occurred. Please try again later.',
        });
      }
    }),
    setLoading(val) {
      self.loading = val;
    },
    addHolidayFree: flow(function* addHolidayFree() {
      try {
        const data = yield self.axios.post(`${BASE_URL}/Subscriptions/holiday`);
        return data;
      } catch (err) {
        console.log(err);
        return null;
      }
    }),
    setSubscriptionPlanId: id => {
      self.subscriptionData.subscriptionPlanId = id;
    },
    initSubscription: flow(function* initSubscription() {
      yield Promise.allSettled([
        self.fetchPlans(),
        self.fetchFirstInvoicesPage(),
        self.fetchFirstTransactionsPage(),
      ]);
      // getParent(self).userProfile.updateIntercom({
      //   subscription_plan: self.subscriptionData.subscriptionPlanName,
      //   plan_expiration: self.subscriptionData.expirationDate,
      //   listings_usage_percent: self.usagePercents.listedItemsUsage,
      // });
    }),
    downloadTransactionInvoice: flow(function* downloadTransactionInvoice(id, created) {
      try {
        const URL = `${BASE_URL}/invoices/${id}/download_link`;
        const { data } = yield self.axios.fetch(URL, {}, {});
        const a = document.createElement('a');
        a.style.display = 'none';
        document.body.appendChild(a);
        a.href = data.data.download_link;
        a.download = created;
        a.click();
      } catch (e) {
        console.error(e);
      }
    }),
    fetchInvoices: flow(function* fetchInvoices(forReports = false, addResults = false) {
      try {
        const { start, limit } = self.invoicesPagination;
        const URL = `${BASE_URL}/invoices?type=SUBSCRIPTION&start=${start}&length=${limit}&app=BIFM`;
        const { data } = yield self.axios.fetch(URL, {}, {});
        self.invoices = addResults ? [...self.invoices, ...data.data] : data.data;
        self.invoicesPagination.setTotal(data.recordsFiltered ?? 0);
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    }),
    fetchTransactions: flow(function* fetchTransactions(addResults = false) {
      try {
        const { start, limit } = self.transactionsPagination;
        const URL = `${BASE_URL}/subscription/transactions?start=${start}&length=${limit}`;
        const { data } = yield self.axios.fetch(URL, {}, {});
        self.transactions = addResults ? [...self.transactions, ...data.data] : data.data;
        self.transactionsPagination.setTotal(data.recordsFiltered ?? 0);
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    }),
    fetchFirstInvoicesPage: flow(function* fetchFirstInvoicesPage() {
      self.invoicesPagination.setStart(0);
      self.invoicesPagination.setLimit(3);
      yield self.fetchInvoices();
    }),
    fetchNextInvoicesPage: flow(function* fetchNextInvoicesPage() {
      self.invoicesPagination.setStart(self.invoices.length);
      self.invoicesPagination.setLimit(10);
      yield self.fetchInvoices(false, true);
    }),
    fetchFirstTransactionsPage: flow(function* fetchFirstTransactionsPage() {
      self.transactionsPagination.setStart(0);
      self.transactionsPagination.setLimit(3);
      yield self.fetchTransactions();
    }),
    fetchNextTransactionsPage: flow(function* fetchNextTransactionsPage() {
      self.transactionsPagination.setStart(self.transactions.length);
      self.transactionsPagination.setLimit(10);
      yield self.fetchTransactions(true);
    }),
    setBillingPaypalAccount: value => {
      if (typeof value === 'string') {
        self.billingPaypalAccountId = value;
      }
    },
    updateBillingPaypalAccount(value) {
      self.billingAccountIndex = value;
    },
  }));

export default Subscription;
