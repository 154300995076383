import { types, getParent, flow } from 'mobx-state-tree';

const DashboardStore = types
  .model('dashboardStore', {
    initialized: false,
    miniDashboard: types.maybe(types.frozen()),
  })
  .views(self => ({
    get axios() {
      return getParent(self).axios;
    },
  }))
  .actions(self => ({
    initStore: flow(function* initStore() {
      if (self.initialized === true) {
        return;
      }
      try {
        yield Promise.allSettled([self.fetchMiniDashboard()]);
      } catch (error) {
        console.error('Getting DashboardStore data failed: ', error);
      } finally {
        self.initialized = true;
      }
    }),
    fetchMiniDashboard: flow(function* fetchMiniDashboard() {
      try {
        const { data } = yield self.axios.fetch('/bifm-service/orders/stats', {});
        self.miniDashboard = data.data;
      } catch (error) {
        console.error('Getting MiniDashboard failed: ', error);
      }
    }),
  }));

export default DashboardStore;
