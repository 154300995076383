import React from 'react';
import { Menu, Badge, Button } from 'antd';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { FormattedMessage } from 'react-intl';
import IconSvg from '../common/IconSvg';
import { colors, buttonNormal, buttonHover, themeColors } from '../../constants';

const { SubMenu } = Menu;

export const StyledMenuItem = styled(Menu.Item)`
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex: 0.1;
  border-left: solid 1px #ebebeb;
  padding: 0;
  cursor: default;

  :last-of-type {
    border-right: solid 1px #ebebeb;
  }

  &.bordered-right {
    border-right: solid 1px #ebebeb;
  }
`;

export const StyledSubMenu = styled(SubMenu)`
  display: flex;
  flex: 0.1;
  justify-content: center;
  align-items: center;
  border-left: solid 1px #ebebeb;
`;

export const StyledMenuTitle = styled('div')`
  width: 16.6%;
  height: 100%;
  display: flex;
  justify-content: center;
  font-family: 'Heebo-Light';
  align-items: center;
  ${({ color }) => color && `color: ${color};`}
  ${props =>
    props.hoverable
      ? `
    &:hover {
      color: ${props.hoverColor || buttonHover};
      &>div.title-link {
        border-bottom: 1px solid ${props.color || buttonHover};
      }
    }
    &>div.title-link {
      border-bottom: 1px solid ${props.hoverColor || 'rgba(35, 59, 105, 0.5)'};
    }
  `
      : ''}
`;

export const StyledMenuTitleValue = styled('div')`
  font-size: ${({ theme }) => theme.fontSize.xlarger};
`;
export const UpgradePopoverText = styled('div')`
  font-size: ${({ theme }) => theme.fontSize.larger};
`;
export const MenuTitleDescContainer = styled('div')`
  margin-left: 5px;
`;
export const MenuTitleDescRow = styled('div')`
  font-size: 12px;
  line-height: 13px;
  white-space: pre-wrap;
`;

export const SettingsButtonsContainer = styled.div`
  width: 66px;
  height: 32px;
  border-radius: 16px;
  border: solid 1px ${({ theme }) => theme.colors.greyInputBorder};
  display: flex;
  justify-content: space-around;
`;

export const Notifications = props => {
  return (
    <div
      css={css`
        &:hover {
          // cursor: pointer;
        }
      `}
    >
      <Badge
        count={props.count}
        css={css`
          sup.ant-badge-count {
            background-color: ${colors.red};
          }
        `}
      >
        <IconSvg
          type="notifications-bell"
          color={buttonNormal}
          hoverColor={buttonHover}
          size={21}
        />
      </Badge>
    </div>
  );
};

export const Logout = props => {
  return (
    <div
      css={css`
        &:hover {
          cursor: pointer;
        }
      `}
      onClick={props.onClick}
    >
      <IconSvg
        type="logout-icon"
        color={themeColors.dirtyBlue}
        hoverColor={themeColors.blue}
        size={21}
      />
    </div>
  );
};

export const PlanUsagePopover = ({
  statusMessage,
  closeAction,
  openSubscriptionSettings,
  showWarning,
}) => {
  return (
    <React.Fragment>
      <UpgradePopoverText>
        <FormattedMessage id={statusMessage} />
      </UpgradePopoverText>
      {showWarning && (
        <p>
          <FormattedMessage id="subscription.warning" />
        </p>
      )}
      <div
        css={css`
          display: flex;
          align-items: center;
          margin: 10px 0 5px;
        `}
      >
        <Button
          onClick={closeAction}
          size="large"
          css={css`
            margin-right: 10px;
            border-radius: 20px;
          `}
        >
          <FormattedMessage id="common.dismiss" />
        </Button>
        <Button
          type="primary"
          onClick={openSubscriptionSettings}
          size="large"
          css={css`
            border-radius: 20px;
            display: flex;
            align-items: center;
          `}
        >
          <FormattedMessage id="subscription.upgradePlan" />
          <IconSvg
            css={css`
              margin-left: 7px;
            `}
            type="space-ship"
            size={25}
          />
        </Button>
      </div>
      <IconSvg
        type="x"
        size={8}
        onClick={closeAction}
        css={css`
          position: absolute;
          top: 20px;
          right: 10px;
        `}
      />
    </React.Fragment>
  );
};
