import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Button, Row, Select } from 'antd';
import { css } from '@emotion/core';
import { FormItem } from '../commonUI/commonUI';
import { SettingsFormHeader } from '../../common/SettingsFormHeader';
import { Input, InputTitle } from '../UserProfile/styled';
import Dropdown from '../../common/Dropdown';
import AxiosWrapper from '../../../axios';
import config from '../../../envUrlConfig';
import { fontSize } from '../../../constants';
import { flagsPaths, upperThreeToTwo, upperTwoToName } from './countries';

const locationAxios = new AxiosWrapper({
  baseURL: config.API_URL || config.API_URL2,
});

const { Option } = Select;

const ItemLocation = ({ updateBreadcrumbs }) => {
  const [userCity, setUserCity] = useState('');
  const [userCountry, setUserCountry] = useState('US');
  const [userZip, setUserZip] = useState('');
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    updateBreadcrumbs({ name: 'Item Location' }, true);
    locationAxios.fetch('/bifm-service/users/me', {}).then(response => {
      setUserLocation(response.data.data);
    });
  }, []);

  if (!userLocation) {
    return <div>Location Loading</div>;
  }

  const { city_state, country, zip } = userLocation?.item_location || '';

  return (
    <div>
      <Form className="login-form" layout="vertical">
        <FormItem>
          <SettingsFormHeader key="location" id="location" defaultMessage="LOCATION" />
          <Row gutter={[0, 15]}>
            <Col span={6}>
              <InputTitle>City-State</InputTitle>
            </Col>
            <Col span={5}>
              <Input
                name="city_state"
                className=""
                type="text"
                onChange={e => setUserCity(e.target.value)}
                value={userCity || city_state}
              />
            </Col>
          </Row>
          <Row gutter={[0, 15]}>
            <Col span={6}>
              <InputTitle>Country</InputTitle>
            </Col>
            <Col span={5}>
              <Dropdown
                onChange={e => setUserCountry(e.target.key)}
                defaultValue="US"
                disabled
                height="22px"
                width="162px"
                borderRadius="16px !important"
                css={css`
                  & div.ant-select-selection-selected-value {
                    overflow: visible;
                  }
                `}
              >
                {flagsPaths.map(option => {
                  const shortName = upperThreeToTwo[option.name];
                  const fullName = upperTwoToName[shortName];
                  return (
                    <Option key={option.name} value={upperThreeToTwo[option.name]}>
                      <span
                        css={css`
                          font-size: ${fontSize.medium};
                        `}
                      >
                        {fullName}
                      </span>
                    </Option>
                  );
                })}
              </Dropdown>
            </Col>
          </Row>
          <Row gutter={[0, 15]}>
            <Col span={6}>
              <InputTitle>Postal Code</InputTitle>
            </Col>
            <Col span={5}>
              <Input
                type="text"
                name="zip"
                onChange={e => setUserZip(e.target.value)}
                required
                value={userZip || zip}
              />
            </Col>
          </Row>
        </FormItem>
        <Button
          type="success"
          onClick={() => {
            locationAxios.post('/bifm-service/users/me', {
              item_location: {
                city_state: userCity || city_state || '',
                country: userCountry || country || '',
                zip: userZip || zip || '',
              },
            });
          }}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

ItemLocation.propTypes = {
  updateBreadcrumbs: PropTypes.func,
};
export default ItemLocation;
