import { types } from 'mobx-state-tree';

export const ReportsFilters = types
  .model('ReportsFilters', {
    fromDate: types.maybeNull(types.string),
    toDate: types.maybeNull(types.string),
  })
  .actions(self => ({
    setToDate(e) {
      self.toDate = e.target.value;
    },
    setFromDate(e) {
      self.fromDate = e.target.value;
    },
  }));
