import { types } from 'mobx-state-tree';
import CurrencyReload from './CurrencyReload';

const Reload = types.model('Reload', {
  EUR: types.optional(CurrencyReload, {}),
  GBP: types.optional(CurrencyReload, {}),
  USD: types.optional(CurrencyReload, {}),
});

export default Reload;
