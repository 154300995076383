const ENUMS = {
  BIFM_MARKETS: {
    AMAZON: 'amazon',
    AMAZON_UK: 'amazon_uk',
    WALMART: 'walmart',
    ALIEXPRESS: 'ali_express',
    HOMEDEPOT: 'homedepot',
    BESTBUY: 'bestbuy',
  },
  NEW_DISCOVERY_DESTINATION_NAMES: {
    EBAY: 4,
    EBAY_US: 4,
  },
  NEW_DISCOVERY_SOURCE_NAMES: {
    AZ_US: 1,
    WM: 2,
    ALI_CN: 3,
    ALI_US: 4,
    CATALOG: 5,
  },
  COMMON: {
    ID: 2,
    URL: 1,
  },
  MARKETS: {
    AMAZON: 1,
    EBAY: 2,
    ALIEXPRESS: 3,
    WALMART: 4,
  },
  STRATEGY: {
    NONE: 0,
    FIXED_PROFIT: 1,
    COMPETITION: 2,
    PENETRATION: 3,
  },
  CONDITION: {
    NONE: 0,
    NEW: 1,
    NEW_OTHER: 2,
    USED: 3,
    MANUFACTURER_REFURBISHED: 4,
    SELLER_REFURBISHED: 5,
  },
  DISCOVERY_ITEM_STATUS: {
    INPROGRESS: 1,
    READY: 2,
    FAILED: 3,
    VALIDATION_FAILED: 4,
  },
  PRICING_STRATEGIES: {
    FIXED_PRICE: 'fixedProfit',
    COMPETITION: 'competition',
    PENETRATION: 'penetration',
  },
  SUBSCRIPTION_PLANS: {
    FREE_TRAIL: 'free',
    BRONZE: 2,
    SILVER: 3,
    GOLD: 4,
  },
  Q_LIST: {
    FAILED_DISCOVERIES: 1,
    COMPLETED_DISCOVERIES: 2,
    LISTED_DISCOVERIES: 3,
    DISCOVERIES_IN_PROGRESS: 4,
  },
  CONNECTION_MANAGEMENT: {
    SOURCE: 1,
    DESTINATION: 2,
    THIRD_PARTY: 3,
  },
  SETTINGS_TABS: {
    USER_PROFILE: 1,
    SUBSCRIPTION: 2,
    BALANCE: 3,
    TRANSACTIONS: 4,
    ITEM_LOCATION: 5,
  },
  CURRENCY: {
    NONE: 0,
    DOLLAR: 1,
    EURO: 2,
  },
  CURRENCY_IDS: {
    USD: 1,
    EUR: 2,
  },
  SHIPPING_METHODS: {
    ECONOMY: 1,
    STANDARD: 2,
    EXPRESS: 3,
    ONE_DAY: 4,
    INTERNATIONAL: 5,
    DOMESTIC: 6,
  },
  SHIPPING_COSTS: {
    COST: 1,
    FREE_SHIPPING: 2,
  },
  MARKETSPLACES_TEAM_A: {
    EBAY: 2,
    'EBAY US': 4,
    'EBAY UK': 6,
  },
  TOAST_TYPES: {
    DANGER: 1,
    WARNING: 2,
    INFO: 3,
  },
  VALUE_TYPE: {
    PERCENT: 1,
    CURRENCY_UNITS: 2,
  },
  TYPE_OF_DISCOVERY_PROFIT_TYPE: {
    PERCENT: 1,
    CURRENCY_UNITS: 2,
  },
  DISCOVERY_TYPE: {
    REGULAR: 1,
    LISTINGS_IMPORT: 2,
  },
  CODES: {
    UPC: 1,
    ASIN: 2,
    URL: 3,
    MARKET_ID: 4,
  },
  DISCOVERY_METHODS: {
    URL: 1,
    IDS: 2,
    CSV: 3,
  },
  DESTINATION_MARKETPLACE: {
    NONE: 0,
    EBAY_US: 4,
    EBAY_UK: 6,
  },
  DISCOVERY_SORT_BY: {
    ID: 1,
    TITLE: 2,
    SOURCE_PRICE: 3,
    PROFIT: 4,
    PROFIT_CURRENCY_UNITS: 5,
    PROFIT_PERCENT: 6,
    DESTINATION_PRICE: 7,
    TRIPLEMARS_RANK: 8,
  },
  SOURCE_MARKETPLACE: {
    NONE: 0,
    AMAZON_US: 1,
    WALMART: 2,
    ALIEXPRESS_CN: 3,
    ALIEXPRESS_US: 4,
    AMAZON_UK: 5,
  },
  DISCOVERY_STATUS: {
    NONE: 0,
    IN_PROGRESS: 1,
    COMPLETED: 2,
    FAILED: 3,
    LISTED: 4,
    REMOVED: 9,
  },
  HTML_TEMPLATE_SYSTEM: {
    TEMPLATE1: 1,
    TEMPLATE2: 2,
  },
  ITEM_CODE: {
    UPC: 1,
    ASIN: 2,
    URL: 3,
    MARKET_ID: 4,
  },
  ITEM_STATUS: {
    NONE: 0,
    ADDED: 1,
    RECIEVED_SCRAPE_SERVICE: 2,
    SUCCEEDED_TO_DISCOVER: 3,
    FAILED_TO_DICSCOVER: 4,
    WAITING_FOR_STRATEGY_CALCULATION: 5,
    VALIDATION_CATEGORIES_NOT_FOUND: 6,
    FAILED_STRATEGY_CALCULATION: 7,
    WAITING_FOR_SCRAPE: 8,
    SUCCEDED_TO_SCRAPE: 9,
    FAILED_TO_SCRAPE: 10,
    ITEM_TIMED_OUT: 11,
  },
  LANGUAGE: {
    UNSPECIFIED: 0,
    EN: 1,
    ES: 2,
  },
  LISTING_ITEM_STATUS: {
    ACTIVE: 1,
    COMPLETED: 2,
    CUSTOM: 3,
    CUSTOM_CODE: 4,
    ENDED: 5,
    OUT_OF_STOCK: 6,
  },
  LISTING_STATUS: {
    IN_PROGRESS: 1,
    PARTIALLY_COMPLETED: 2,
    COMPLETED: 3,
    REFUSED: 4,
    STOPPED: 5,
  },
  MARKETPLACE_API_TASK: {
    UNCPECIFIED: 0,
    CREATE_ACCESS_TOKEN: 1,
    REFRESH_ACCESS_TOKEN: 2,
    CHECK_ACCESS_TOKEN: 3,
    CREATE_BUSINESS_POLICY: 4,
    UPDATE_BUSINESS_POLICY: 5,
    DELETE_BUSINESS_POLICY: 6,
  },
  ORDER_STATUS: {
    UNKNOWN: 0,
    ORDERED: 1,
    FAILED: 2,
    SHIPPED: 3,
    IN_PROCESS: 4,
    CANCELED: 5,
    COMPLETED: 6,
    STANDBY: 7,
    RETURNED: 8,
    REFUNDED: 9,
    STOPPED: 10,
  },
  SPYDER_TYPE: {
    WAL_PRODUCT: 1,
    AMZ_PRODUCT: 2,
  },
  VALIDATION: {
    NONE: 0,
    BLACK_LIST: 1,
    VERO: 2,
    BLACK_LIST_CATEGORY: 3,
  },
};

export default ENUMS;
