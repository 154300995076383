import React from 'react';
import { injectIntl } from 'react-intl';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

import IconSvg from '../components/common/IconSvg';

const FlexWrapper = styled.div`
  display: flex;
  align-items: start;
  position: fixed;
  bottom: 0;
  width: 1073.28px;
  justify-content: center;
  @media (min-height: 800px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    height: calc(100% - 140px);
  }
`;
const FirstLine = styled.h1`
  font-weight: bold;
`;
const TextWrapper = styled.div`
  margin-left: -10em;
  max-width: 400px;
  @media (min-height: 800px) {
    max-width: 1248px;
    margin-left: 0;
  }
`;
const Page404 = ({ intl }) => (
  <FlexWrapper>
    <IconSvg type="404-page" width={680} height={500} />
    <TextWrapper>
      <FirstLine>{intl.formatMessage({ id: 'common.noWater' })}</FirstLine>
      <h1>{intl.formatMessage({ id: 'common.404' })}</h1>
    </TextWrapper>
  </FlexWrapper>
);

export default injectIntl(observer(Page404));
