import { types, getParent, getRoot, flow } from 'mobx-state-tree';
import moment from 'moment';

const UserProfile = types
  .model('userProfile', {
    currentPlan: types.maybe(types.frozen()),
    momentNow: types.maybe(types.string),
    created: 0,
    email: '',
    id: '',
    intercomBooted: false,
    business_details: types.frozen({
      name: '',
      first_name: '',
      last_name: '',
      address_line_1: '',
      city: '',
      zip: '',
      state: '',
    }),
    item_location: types.frozen({
      cityState: '',
      country: 'US',
      zip: '',
    }),
  })
  .views(self => ({
    get axios() {
      return getParent(self).axios;
    },
    get axios2() {
      return getRoot(self).axios2;
    },
    get currentPlanActive() {
      return self.currentPlan?.plan_expiry
        ? moment(self.currentPlan?.plan_expiry).isAfter(moment(self.momentNow))
        : false;
    },
    get overLimitInfo() {
      const retval = { isOverLimit: false, overLimitFeatureIds: [] };
      if (!self.currentPlan) {
        return retval;
      }
      const { BLUECARE, ORDERS } = self.currentPlan.features;
      if (BLUECARE && BLUECARE.limit > 0 && BLUECARE.count > BLUECARE.limit) {
        retval.isOverLimit = true;
        retval.overLimitFeatures.push('subscription.addonTitle_bluecare');
      }
      if (ORDERS.limit > 0 && ORDERS.count > ORDERS.limit) {
        retval.isOverLimit = true;
        retval.overLimitFeatures.push('header.orders');
      }
      return retval;
    },
    // get fullName() {
    //   return self.firstName && self.lastName ? `${self.firstName} ${self.lastName}` : '';
    // },
  }))
  .actions(self => ({
    afterCreate() {
      self.momentNow = moment().format();
    },
    fetchCurrentUser: flow(function* fetchCurrentUser() {
      try {
        self.momentNow = moment().format();
        const {
          data: { data },
        } = yield self.axios.get('bifm-service/users/me', {});
        const { subscription_plan } = data;
        self.currentPlan = subscription_plan;
        self.created = data.created;
        self.email = data.email;
        self.id = data.id;
        self.business_details = { ...data.business_details };
        self.sendEmailToZoho(data.email);
      } catch (error) {
        console.log('get plan failed: ', error);
      }
    }),
    bootIntercom: () => {
      if (!self.intercomBooted) {
        window.Intercom('boot', {
          app_id: 'czzhysxl',
          user_id: self.id,
          email: self.email || '',
          created_at: self.created,
        });
        self.intercomBooted = true;
      }
    },
    saveUserDataToStore: data => {
      self.business_details = { ...data };
    },
    saveUserDataToServer: flow(function* fetchCurrentUser(data) {
      self.saveUserDataToStore(data);

      try {
        self.momentNow = moment().format();
        const result = yield self.axios.post('bifm-service/users/me', {
          business_details: data,
        });
        // console.log('result: ', result);
      } catch (error) {
        // console.log('User data saving failed: ', error);
      }
    }),
    sendEmailToZoho: email => {
      window.$zoho.salesiq.visitor.email(email);
    },
    // updateIntercom: data => {
    //   if (data) {
    //     window.Intercom('update', {
    //       app_id: 'czzhysxl',
    //       user_id: self.accountCode,
    //       email: self.email || '',
    //       name: self.fullName,
    //       ...data,
    //     });
    //   }
    // },
    // trackEventIntercom: (name, data) => {
    //   if (name) {
    //     window.Intercom('trackEvent', name, data);
    //   }
    // },
  }));

export default UserProfile;
