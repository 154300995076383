export const config = {
  slidesToShow: 3,
  slidesToScroll: 1,
  formItemLayout: {
    colon: false,
    labelCol: { span: 6 },
    wrapperCol: { span: 7 },
  },
  shippingMethodColSpan: 7,
  editPolicyItemLayout: {
    colon: false,
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  },
};
