import React from 'react';
import styled from '@emotion/styled';

const LogoContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  background: url('/assets/icons/logo.jpg') center/contain no-repeat;
  background-size: cover;
  background-color: #fff;
`;

const Logo = props => <LogoContainer {...props} />;

export default Logo;
