import { types } from 'mobx-state-tree';

const FirewallCreateParams = types
  .model('FirewallCreateParams', {
    machineId: types.optional(types.maybe(types.number), 0),
    details: types.optional(types.string, ''),
    ip_address: types.optional(types.string, ''),
  })
  .views(self => ({
    get createParamsValid() {
      return /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g.test(
        self.ip_address,
      );
    },
  }))
  .actions(self => ({
    setDetails(val) {
      self.details = val;
    },
    setIP(val) {
      self.ip_address = val;
    },
    resetData() {
      self.details = '';
      self.ip_address = '';
    },
  }));

export default FirewallCreateParams;
