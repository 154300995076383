import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { ToastWrapper, Header, Body } from './styled';
import ENUMS from '../../constants/enums';
import IconSvg from '../common/IconSvg';

const TOAST_TIMEOUT = 5000;

@injectIntl
@inject('toastsStore')
@observer
class Toast extends Component {
  componentDidUpdate() {
    const { getType, close, getIsShown, delay } = this.props.toastsStore;
    if (getType !== ENUMS.TOAST_TYPES.DANGER && getIsShown) {
      setTimeout(() => {
        close();
      }, delay || TOAST_TIMEOUT);
    }
  }

  getStyle = () => {
    const { getType } = this.props.toastsStore;
    const { WARNING, INFO, DANGER } = ENUMS.TOAST_TYPES;

    switch (getType) {
      case WARNING:
        return {
          backgroundColor: '#ffe6d9',
          mainColor: '#ff5700',
          iconType: 'warning',
        };
      case INFO:
        return {
          backgroundColor: '#d9e7ff',
          mainColor: '#0070ba',
          iconType: 'info',
        };
      case DANGER:
        return {
          backgroundColor: '#f9bac2',
          mainColor: '#ff3f71',
          iconType: 'danger',
        };
      default:
        return {
          backgroundColor: '#fff',
          mainColor: '#000',
          iconType: 'info',
        };
    }
  };

  render() {
    const { intl, toastsStore } = this.props;
    const { getIsShown, getMessage, close } = toastsStore;

    return getIsShown ? (
      <ToastWrapper
        borderAndTextColor={this.getStyle().mainColor}
        backgroundColor={this.getStyle().backgroundColor}
      >
        <Header>
          <IconSvg type="x" onClick={close} size={8} />
        </Header>
        <Body>
          <IconSvg size={22} type={this.getStyle().iconType} style={{ marginRight: '8px' }} />
          <span>{intl.formatMessage({ id: getMessage })}</span>
        </Body>
      </ToastWrapper>
    ) : null;
  }
}

Toast.propTypes = {
  toastsStore: PropTypes.object,
  intl: PropTypes.object,
};

export default Toast;
