import { flow, getParent, types } from 'mobx-state-tree';
import matchSorter from 'match-sorter';
import {
  Machine,
  Location,
  MachineCreateParams,
  MachineEditParams,
  FirewallEditParams,
} from '../../models/VM';
import FirewallCreateParams from '../../models/VM/FirewallCreateParams';

const BASE_URL = 'triplecloud';
const VMStore = types
  .model('VMStore', {
    machines: types.optional(types.array(Machine), []),
    locations: types.optional(types.array(Location), []),
    machineCreateParams: types.optional(MachineCreateParams, {}),
    machineEditParams: types.optional(MachineEditParams, {}),
    firewallEditParams: types.optional(FirewallEditParams, {}),
    firewallCreateParams: types.optional(FirewallCreateParams, {}),
    clientIP: types.optional(types.string, ''),
    loading: 0,
    searchText: '',
  })
  .views(self => ({
    get axios() {
      return getParent(self).axios;
    },
    get showToast() {
      return getParent(self).toastsStore.showToast;
    },
    get enums() {
      return getParent(self).enumsStore.getEnumsList;
    },
    get filteredMachines() {
      const notDeleted = self.machines.filter(m => m.status !== 'deleting');
      return matchSorter(notDeleted, self.searchText, {
        keys: ['name', 'details', 'location.name'],
      });
    },
    get accountCode() {
      return getParent(self).sessionStore.accountCode;
    },
    get transport5007() {
      return getParent(self).transport5007;
    },
    get machinesById() {
      return Object.fromEntries(
        self.machines.map((mach, idx) => [mach.id, { machineRef: mach, arrayIndex: idx }]),
      );
    },
    get IPOnCurrentWhitelist() {
      return self.clientIP && self.firewallEditParams.machineId
        ? self.machinesById[self.firewallEditParams.machineId]?.machineRef?.worksOnCurrentIP ??
            false
        : false;
    },
    get anyLoading() {
      return self.loading > 0;
    },
    get selectedMachinesIds() {
      return self.machines.reduce((acc, curr) => (curr.selected ? [...acc, curr.id] : acc), []);
    },
    get selectedMachinesCount() {
      return self.selectedItemsIds.length;
    },
    get allSelected() {
      return self.machines.length > 0
        ? self.machines.every(mach => mach.selected || (!mach.selected && mach.isProcessing))
        : false;
    },
  }))
  .actions(self => ({
    selectAll(val) {
      self.machines.forEach(mach => {
        mach.setSelected(val);
      });
    },
    startLoading() {
      self.loading++;
    },
    stopLoading() {
      self.loading--;
    },
    // changing value from array entirely to ensure the vm table updates
    reloadMachine(id, snapshot) {
      self.machines[self.machinesById[id].arrayIndex] = Machine.create(snapshot);
    },
    loadEditParams(id) {
      const { machineRef } = self.machinesById[id];
      self.machineEditParams = MachineEditParams.create({
        id,
        name: machineRef.name,
        details: machineRef.details ?? '',
      });
    },
    loadFWEditParams(machineId) {
      self.firewallEditParams = FirewallEditParams.create({ machineId });
    },
    loadFWCreateParams(machineId) {
      self.firewallCreateParams = FirewallCreateParams.create({ machineId });
    },
    setInitialFWEditParams(fw) {
      self.firewallEditParams.setId(fw.id);
      self.firewallEditParams.setDetails(fw.details);
    },
    setSearchText(txt) {
      self.searchText = txt;
    },
    fetchMachines: flow(function* fetchMachines() {
      try {
        self.startLoading();
        const {
          data: { machines },
        } = yield self.axios.fetch(`${BASE_URL}/machine`);
        self.machines = machines;
      } catch (err) {
        console.error(err);
      } finally {
        self.stopLoading();
      }
    }),
    fetchMachine: flow(function* fetchMachine(id) {
      try {
        const {
          data: { machine },
        } = yield self.axios.fetch(`${BASE_URL}/machine/${id}`);
        return machine;
      } catch (err) {
        console.error(err);
        return null;
      }
    }),
    fetchLocations: flow(function* fetchLocations() {
      try {
        self.startLoading();
        const {
          data: { locations },
        } = yield self.axios.fetch(`${BASE_URL}/location`);
        self.locations = locations;
      } catch (err) {
        console.error(err);
      } finally {
        self.stopLoading();
      }
    }),
    createMachine: flow(function* createMachine() {
      try {
        const params = {
          ...self.machineCreateParams,
          external_account_id: self.accountCode,
        };
        self.startLoading();
        const {
          data: { machine },
        } = yield self.axios.post('/subscription/v1/triplecloud', params);
        self.machines.push(machine);
        self.loadFWEditParams(machine.id);
        yield self.whitelistCurrentIP();
        self.firewallEditParams.resetData();
      } catch (err) {
        const { data } = err.response;
        self.showToast({
          type: self.enums.TOAST_TYPES.WARNING,
          message: data || 'There was an error creating the machine',
        });
        console.error(err);
      } finally {
        self.stopLoading();
      }
    }),
    createFirewall: flow(function* createFirewall() {
      try {
        const params = {
          ip_address: self.firewallCreateParams.ip_address,
          details: self.firewallCreateParams.details,
        };
        self.startLoading();
        yield self.axios.post(
          `${BASE_URL}/machine/${self.firewallCreateParams.machineId}/firewall`,
          params,
        );
        const machine = yield self.fetchMachine(self.firewallCreateParams.machineId);
        self.reloadMachine(self.firewallCreateParams.machineId, machine);
      } catch (err) {
        console.error(err);
      } finally {
        self.stopLoading();
      }
    }),
    whitelistCurrentIP: flow(function* whitelistCurrentIP() {
      try {
        self.startLoading();
        yield self.axios.post(`${BASE_URL}/machine/${self.firewallEditParams.machineId}/firewall`, {
          details: '',
        });
        const machine = yield self.fetchMachine(self.firewallEditParams.machineId);
        self.reloadMachine(self.firewallEditParams.machineId, machine);
      } catch (err) {
        console.error(err);
      } finally {
        self.stopLoading();
      }
    }),
    whitelistByIdOnCurrentIP: flow(function* whitelistByIdOnCurrentIP(id) {
      try {
        yield self.axios.post(`${BASE_URL}/machine/${id}/firewall`, {
          details: '',
        });
        const machine = yield self.fetchMachine(id);
        self.reloadMachine(id, machine);
      } catch (err) {
        console.error(err);
      }
    }),
    updateMachine: flow(function* updateMachine() {
      try {
        const params = {
          name: self.machineEditParams.name,
          details: self.machineEditParams.details,
          external_account_id: self.accountCode,
        };
        self.startLoading();
        const {
          data: { machine },
        } = yield self.axios.update(`${BASE_URL}/machine/${self.machineEditParams.id}`, params);
        self.reloadMachine(self.machineEditParams.id, machine);
      } catch (err) {
        console.error(err);
      } finally {
        self.stopLoading();
      }
    }),
    updateFirewall: flow(function* updateFirewall() {
      try {
        const params = {
          details: self.firewallEditParams.details,
        };
        self.startLoading();
        yield self.axios.update(
          `${BASE_URL}/machine/${self.firewallEditParams.machineId}/firewall/${self.firewallEditParams.id}`,
          params,
        );
        const machine = yield self.fetchMachine(self.firewallEditParams.machineId);
        self.reloadMachine(self.firewallEditParams.machineId, machine);
      } catch (err) {
        console.error(err);
      } finally {
        self.stopLoading();
      }
    }),
    deleteMachine: flow(function* deleteMachine(id) {
      try {
        self.startLoading();
        yield self.axios.delete(`/subscription/v1/triplecloud/${id}`);
        const machine = yield self.fetchMachine(id);
        self.reloadMachine(id, machine);
      } catch (err) {
        console.error(err);
      } finally {
        self.stopLoading();
      }
    }),
    bulkDelete: flow(function* bulkDelete() {
      try {
        self.startLoading();
        const params = [...self.selectedMachinesIds];
        const {
          data: { machines },
        } = yield self.axios.delete('/subscription/v1/triplecloud/bulk', params);
        self.selectAll(false);
        machines.forEach(mach => {
          self.reloadMachine(mach.id, mach);
        });
      } catch (err) {
        console.error(err);
      } finally {
        self.stopLoading();
      }
    }),
    deleteFirewall: flow(function* deleteFirewall(id) {
      try {
        self.startLoading();
        yield self.axios.delete(
          `${BASE_URL}/machine/${self.firewallEditParams.machineId}/firewall/${id}`,
        );
        const machine = yield self.fetchMachine(self.firewallEditParams.machineId);
        self.reloadMachine(self.firewallEditParams.machineId, machine);
      } catch (err) {
        console.error(err);
      } finally {
        self.stopLoading();
      }
    }),
    rebootMachine: flow(function* rebootMachine(id) {
      try {
        self.startLoading();
        const {
          data: { machine },
        } = yield self.axios.post(`${BASE_URL}/machine/${id}/reboot`);
        self.reloadMachine(id, machine);
      } catch (err) {
        console.error(err);
      } finally {
        self.stopLoading();
      }
    }),
    bulkReboot: flow(function* bulkReboot() {
      try {
        self.startLoading();
        const params = [...self.selectedMachinesIds];
        const {
          data: { machines },
        } = yield self.axios.post(`${BASE_URL}/machine/bulk/reboot`, params);
        self.selectAll(false);
        machines.forEach(mach => {
          self.reloadMachine(mach.id, mach);
        });
      } catch (err) {
        console.error(err);
      } finally {
        self.stopLoading();
      }
    }),
    getIP: flow(function* getIP() {
      try {
        self.startLoading();
        const { data } = yield self.axios.fetch(`${BASE_URL}/ip`);
        self.clientIP = data?.ip ?? '';
      } catch (err) {
        console.error(err);
      } finally {
        self.stopLoading();
      }
    }),
    initVMStore: flow(function* initVMStore() {
      try {
        self.startLoading();
        yield self.getIP();
        yield Promise.allSettled([yield self.fetchLocations(), yield self.fetchMachines()]);
        // eslint-disable-next-line no-restricted-syntax
        for (const mach of self.machines.filter(
          ({ worksOnCurrentIP, isProcessing }) => !worksOnCurrentIP && !isProcessing,
        )) {
          yield self.whitelistByIdOnCurrentIP(mach.id);
        }
      } catch (err) {
        console.error(err);
      } finally {
        self.stopLoading();
      }
    }),
  }));

export default VMStore;
