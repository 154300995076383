import { types } from 'mobx-state-tree';

const FirewallEditParams = types
  .model('FirewallEditParams', {
    machineId: types.optional(types.maybe(types.number), 0),
    id: types.maybe(types.number),
    details: types.maybe(types.string),
  })
  .actions(self => ({
    setDetails(val) {
      self.details = val;
    },
    setId(val) {
      self.id = val;
    },
    resetData() {
      self.id = null;
      self.details = '';
    },
  }));

export default FirewallEditParams;
