import { types } from 'mobx-state-tree';

const MachineCreateParams = types
  .model('MachineParams', {
    name: types.optional(types.string, ''),
    location_id: types.optional(types.number, 1),
    details: types.optional(types.string, ''),
  })
  .views(self => ({
    get createParamsValid() {
      return !!self.name.trim();
    },
  }))
  .actions(self => ({
    setName(val) {
      self.name = val;
    },
    setLocation(val) {
      self.location_id = val;
    },
    setDetails(val) {
      self.details = val;
    },
    resetData() {
      self.name = '';
      self.location_id = 1;
      self.details = '';
    },
  }));

export default MachineCreateParams;
