import { types, getParent, flow } from 'mobx-state-tree';
import Cookies from 'universal-cookie';

import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import bg from 'react-intl/locale-data/bg';
import it from 'react-intl/locale-data/it';
import pt from 'react-intl/locale-data/pt';
import de from 'react-intl/locale-data/de';
import el from 'react-intl/locale-data/el';
import ja from 'react-intl/locale-data/ja';
import he from 'react-intl/locale-data/he';
import ar from 'react-intl/locale-data/ar';
import fr from 'react-intl/locale-data/fr';
import hi from 'react-intl/locale-data/hi';
import fil from 'react-intl/locale-data/fil';
import ro from 'react-intl/locale-data/ro';
import th from 'react-intl/locale-data/th';
import vi from 'react-intl/locale-data/vi';

// Translated data
import localeData from '../../../i18n/build/data.json';

// fill incomplete data from translation
localeData.es = Object.assign({}, localeData.en, localeData.es);
localeData.bg = Object.assign({}, localeData.en, localeData.bg);
localeData.it = Object.assign({}, localeData.en, localeData.it);
localeData.pt = Object.assign({}, localeData.en, localeData.pt);
localeData.de = Object.assign({}, localeData.en, localeData.de);
localeData.el = Object.assign({}, localeData.en, localeData.el);
localeData.ja = Object.assign({}, localeData.en, localeData.ja);
localeData.he = Object.assign({}, localeData.en, localeData.he);
localeData.ar = Object.assign({}, localeData.en, localeData.ar);
localeData.fr = Object.assign({}, localeData.en, localeData.fr);
localeData.hi = Object.assign({}, localeData.en, localeData.hi);
localeData.fil = Object.assign({}, localeData.en, localeData.fil);
localeData.ro = Object.assign({}, localeData.en, localeData.ro);
localeData.th = Object.assign({}, localeData.en, localeData.th);
localeData.vi = Object.assign({}, localeData.en, localeData.vi);

const DEFAULT_LANGUAGE = 'en';
const COOKIE_NAME = 'language';

const LocaleStore = types
  .model('LocaleStore', {
    language: types.optional(types.string, DEFAULT_LANGUAGE),
    data: types.frozen(),
  })
  .volatile(() => ({
    cookies: new Cookies(),
  }))
  .views(self => ({
    get languageWithoutRegionCode() {
      return self.language.toLowerCase().split(/[_-]+/)[0];
    },
    get messages() {
      // Try full locale, try locale without region code, fallback to default language
      return (
        self.data ||
        localeData[self.languageWithoutRegionCode] ||
        localeData[self.language] ||
        localeData.DEFAULT_LANGUAGE
      );
    },
    get isBG() {
      return self.language === 'bg';
    },
    get isEL() {
      return self.language === 'el';
    },
    get isJA() {
      return self.language === 'ja';
    },
  }))
  .actions(self => {
    const changeLanguage = (language = '') => {
      if (self.language === language) return;
      self.language = language;

      // Save to cookie
      self.cookies.set(COOKIE_NAME, language, {
        path: '/',
        maxAge: 24 * 3600 * 30,
      });
    };

    const initLocale = () => {
      addLocaleData([
        ...en,
        ...es,
        ...bg,
        ...it,
        ...pt,
        ...de,
        ...el,
        ...ja,
        ...he,
        ...ar,
        ...fr,
        ...hi,
        ...fil,
        ...ro,
        ...th,
        ...vi,
      ]);
      const cookieLanguage = self.cookies.get(COOKIE_NAME);
      self.language = cookieLanguage;
    };

    return {
      initLocale,
      changeLanguage,
    };
  });

export default LocaleStore;
